/*
 * Quick Configurations Dashboard Messages
 *
 * This contains all the text for the Quick Configurations Dashboard Page container.
 */
import { defineMessages } from 'react-intl';

export const scope = 'app.containers.ComponentManager.Dashboard';

export default defineMessages({
  // #region Titles
  titleESSBattery: {
    id: `${scope}.titleESSBattery`,
    defaultMessage: 'Component Manager - ESS Battery',
  },
  titleSolarInverter: {
    id: `${scope}.titleSolarInverter`,
    defaultMessage: 'Component Manager - Solar Inverter',
  },
  titleSolarConverter: {
    id: `${scope}.titleSolarConverter`,
    defaultMessage: 'Component Manager - Solar Converter',
  },
  titleEV: {
    id: `${scope}.titleEV`,
    defaultMessage: 'Component Manager - EV',
  },
  titleESSConverter: {
    id: `${scope}.titleESSConverter`,
    defaultMessage: 'Component Manager - ESS Converter',
  },
  titleESSInverter: {
    id: `${scope}.titleESSInverter`,
    defaultMessage: 'Component Manager - ESS Inverter',
  },
  titleEngine: {
    id: `${scope}.titleEngine`,
    defaultMessage: 'Component Manager - Engine',
  },
  titleFleet: {
    id: `${scope}.titleFleet`,
    defaultMessage: 'Component Manager - Fleet',
  },
  titleInverter: {
    id: `${scope}.titleInverter`,
    defaultMessage: 'Component Manager - Inverter',
  },
  titleEnclosure: {
    id: `${scope}.titleEnclosure`,
    defaultMessage: 'Component Manager - Enclosure',
  },
  enclosureModalTitle: {
    id: `${scope}.enclosureModalTitle`,
    defaultMessage: 'New Enclosure',
  },
  viewEnclosureModalTitle: {
    id: `${scope}.viewEnclosureModalTitle`,
    defaultMessage: 'Enclosure',
  },
  titleEnclosureComponent: {
    id: `${scope}.titleEnclosureComponent`,
    defaultMessage: 'Component Manager - Enclosure Component',
  },
  enclosureComponentModalTitle: {
    id: `${scope}.enclosureComponentModalTitle`,
    defaultMessage: 'New Enclosure Component',
  },
  viewEnclosureComponentModalTitle: {
    id: `${scope}.viewEnclosureComponentModalTitle`,
    defaultMessage: 'Enclosure Component',
  },
  titleDCACInverterComponent: {
    id: `${scope}.titleDCACInverterComponent`,
    defaultMessage: 'Component Manager - DC/AC Bus Inverter',
  },

  nameESSBattery: {
    id: `${scope}.nameESSBattery`,
    defaultMessage: 'ESS - Battery',
  },
  nameSolarInverter: {
    id: `${scope}.nameSolarInverter`,
    defaultMessage: 'Solar - Inverter',
  },
  nameSolarConverter: {
    id: `${scope}.nameSolarConverter`,
    defaultMessage: 'Solar - Converter',
  },
  nameEV: {
    id: `${scope}.nameEV`,
    defaultMessage: 'EV',
  },
  nameESSConverter: {
    id: `${scope}.nameESSConverter`,
    defaultMessage: 'ESS - Converter',
  },
  nameESSInverter: {
    id: `${scope}.nameESSInverter`,
    defaultMessage: 'ESS - Inverter',
  },
  nameEngine: {
    id: `${scope}.nameEngine`,
    defaultMessage: 'Engine',
  },
  nameFleet: {
    id: `${scope}.nameFleet`,
    defaultMessage: 'Fleet',
  },
  nameInverter: {
    id: `${scope}.nameInverter`,
    defaultMessage: 'Inverter',
  },
  nameEnclosure: {
    id: `${scope}.nameEnclosure`,
    defaultMessage: 'Enclosure',
  },
  nameEnclosureComponent: {
    id: `${scope}.nameEnclosureComponent`,
    defaultMessage: 'Enclosure Component',
  },
  nameDCACBusInverterComponent: {
    id: `${scope}.nameDCACBusInverterComponent`,
    defaultMessage: 'DC/AC Bus Inverter',
  },
  alertDeactivateFleetTitle: {
    id: `${scope}.alertDeactivateFleetTitle`,
    defaultMessage: 'Deactivate Fleet',
  },
  alertActivateFleetTitle: {
    id: `${scope}.alertActivateFleetTitle`,
    defaultMessage: 'Activate Fleet',
  },
  alertActivateEnclosureTitle: {
    id: `${scope}.alertActivateEnclosureTitle`,
    defaultMessage: 'Activate Enclosure',
  },
  alertDeactivateEnclosureTitle: {
    id: `${scope}.alertDeactivateEnclosureTitle`,
    defaultMessage: 'Deactivate Enclosure',
  },
  alertActivateEnclosureComponentTitle: {
    id: `${scope}.alertActivateEnclosureComponentTitle`,
    defaultMessage: 'Activate Enclosure Component',
  },
  alertDeactivateEnclosureComponentTitle: {
    id: `${scope}.alertActivateEnclosureComponentTitle`,
    defaultMessage: 'Deactivate Enclosure Component',
  },
  // #endregion

  // #region common
  search: {
    id: `${scope}.title`,
    defaultMessage: 'Search',
  },
  createNew: {
    id: `${scope}.createNew`,
    defaultMessage: 'Create New',
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: 'Save',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
  },
  close: {
    id: `${scope}.close`,
    defaultMessage: 'Close',
  },
  manufactureColumnTitle: {
    id: `${scope}.manufactureColumnTitle`,
    defaultMessage: 'MANUFACTURER',
  },
  partNumberColumnTitle: {
    id: `${scope}.partNumberColumnTitle`,
    defaultMessage: 'PART NUMBER',
  },
  descriptionColumnTitle: {
    id: `${scope}.descriptionColumnTitle`,
    defaultMessage: 'DESCRIPTION',
  },
  dateColumnTitle: {
    id: `${scope}.dateColumnTitle`,
    defaultMessage: 'CREATED',
  },
  activeTitle: {
    id: `${scope}.activeTitle`,
    defaultMessage: 'ACTIVE',
  },
  inactiveTitle: {
    id: `${scope}.inactiveTitle`,
    defaultMessage: 'INACTIVE',
  },
  alertNoLabel: {
    id: `${scope}.alertNoLabel`,
    defaultMessage: 'No',
  },
  capsNoLabel: {
    id: `${scope}.capsNoLabel`,
    defaultMessage: 'NO',
  },
  capsYesLabel: {
    id: `${scope}.capsYesLabel`,
    defaultMessage: 'YES',
  },
  alertYesLabel: {
    id: `${scope}.alertYesLabel`,
    defaultMessage: 'Yes',
  },
  alertDeactiveLabel: {
    id: `${scope}.alertDeactiveLabel`,
    defaultMessage: 'Deactivate',
  },
  alertActiveLabel: {
    id: `${scope}.alertActiveLabel`,
    defaultMessage: 'Activate',
  },
  alertDeactivateMessage: {
    id: `${scope}.alertDeactivateMessage`,
    defaultMessage:
      'Deactivation makes the component unavailable for this and future configurations, but the component remains accessible for historical configurations which rely on it.',
  },
  alertDeactivateDescription: {
    id: `${scope}.alertDeactivateDescription`,
    defaultMessage: '{type}',
  },
  alertCancelChangesTitle: {
    id: `${scope}.alertCancelChangesTitle`,
    defaultMessage: 'Cancel Changes',
  },
  alertCancelChangesMessage: {
    id: `${scope}.alertCancelChangesMessage`,
    defaultMessage: 'Are you sure you want to cancel? Any changes made since the last save will be lost.',
  },
  alertUnsavedChangesTitle: {
    id: `${scope}.alertUnsavedChangesTitle`,
    defaultMessage: 'Unsaved Changes',
  },
  alertUnsavedChangesMessage: {
    id: `${scope}.alertUnsavedChangesMessage`,
    defaultMessage:
      'You have unsaved changes on this configuration and those changes will be lost. Are you sure you want to navigate away?',
  },
  alertActivateMessage: {
    id: `${scope}.alertActivateMessage`,
    defaultMessage: 'Are you sure you want to Activate the following component configuration?',
  },

  // #endregion

  // #region ESS Battery resources
  createComponentModalTitle: {
    id: `${scope}.createComponentModalTitle`,
    defaultMessage: 'New ESS - Battery Component',
  },
  ViewComponentModalTitle: {
    id: `${scope}.viewComponentModalTitle`,
    defaultMessage: 'ESS - Battery Component',
  },
  componentSearchNotMatch: {
    id: `${scope}.componentSearchNotMatch`,
    defaultMessage: 'No matching {name} Component for search text',
  },
  emptyComponentList: {
    id: `${scope}.emptyComponentList`,
    defaultMessage: 'No {name} Components',
  },
  manufactureLabel: {
    id: `${scope}.manufactureLabel`,
    defaultMessage: 'Manufacturer',
  },
  manufactureError: {
    id: `${scope}.manufactureError`,
    defaultMessage: 'Manufacturer is required',
  },
  partNumberRequiredError: {
    id: `${scope}.partNumberRequiredError`,
    defaultMessage: 'Part Number is required',
  },
  partNumberFormatError: {
    id: `${scope}.partNumberFormatError`,
    defaultMessage: 'Part Number must be between 0 and 99,999',
  },
  partNumberLabel: {
    id: `${scope}.partNumberLabel`,
    defaultMessage: 'Part Number',
  },
  connectionLabel: {
    id: `${scope}.connectionLabel`,
    defaultMessage: 'Connection',
  },
  batteryEfficiencyLabel: {
    id: `${scope}.batteryEfficiencyLabel`,
    defaultMessage: 'Battery Efficiency',
  },
  batteryEfficiencyRequiredErrorMessage: {
    id: `${scope}.batteryEfficiencyRequiredErrorMessage`,
    defaultMessage: 'Battery Efficiency is required',
  },
  batteryEfficiencyPatternErrorMessage: {
    id: `${scope}.batteryEfficiencyPatternErrorMessage`,
    defaultMessage: 'Battery Efficiency must be between 0.1 and 100.0',
  },
  alertActivateTitle: {
    id: `${scope}.alertActivateTitle`,
    defaultMessage: 'Activate ESS - Battery',
  },
  essSizeLabel: {
    id: `${scope}.essSizeLabel`,
    defaultMessage: 'Nameplate Energy Capacity',
  },
  nameplateEnergyCapacityValidRequiredErrorMessage: {
    id: `${scope}.nameplateEnergyCapacityValidRequiredErrorMessage`,
    defaultMessage: 'Nameplate Energy Capacity is required',
  },
  nameplateEnergyCapacityValidPatternErrorMessage: {
    id: `${scope}.nameplateEnergyCapacityValidPatternErrorMessage`,
    defaultMessage: 'Nameplate Energy Capacity must be between 0.0 and 99,999.0 with a decimal range to 0.1',
  },
  maxChargeLabel: {
    id: `${scope}.maxChargeLabel`,
    defaultMessage: 'Max Charge',
  },
  maxChargeRequiredErrorMessage: {
    id: `${scope}.maxChargeRequiredErrorMessage`,
    defaultMessage: 'Max Charge is required',
  },
  maxChargePatternErrorMessage: {
    id: `${scope}.maxChargePatternErrorMessage`,
    defaultMessage: 'Max Charge must be between 0 and 99,999',
  },
  maxDischargeLabel: {
    id: `${scope}.maxDischargeLabel`,
    defaultMessage: 'Max Discharge',
  },
  maxDischargeRequiredErrorMessage: {
    id: `${scope}.maxDischargeRequiredErrorMessage`,
    defaultMessage: 'Max Discharge is required',
  },
  maxDischargePatternErrorMessage: {
    id: `${scope}.maxDischargePatternErrorMessage`,
    defaultMessage: 'Max Discharge must be between 0.0 and 99,999.9',
  },
  depthOfDischargeLabel: {
    id: `${scope}.depthOfDischargeLabel`,
    defaultMessage: 'Depth of Discharge',
  },
  depthOfDischargeRequiredErrorMessage: {
    id: `${scope}.depthOfDischargeRequiredErrorMessage`,
    defaultMessage: 'Depth of Discharge is required',
  },
  depthOfDischargePatternErrorMessage: {
    id: `${scope}.depthOfDischargePatternErrorMessage`,
    defaultMessage: 'Depth of Discharge must be between 0.0 and 100.0',
  },
  unitCostLabel: {
    id: `${scope}.unitCostLabel`,
    defaultMessage: 'Unit Cost',
  },
  unitCostRequiredErrorMessage: {
    id: `${scope}.unitCostRequiredErrorMessage`,
    defaultMessage: 'Unit Cost is required',
  },
  unitCostPatternErrorMessage: {
    id: `${scope}.unitCostPatternErrorMessage`,
    defaultMessage: 'Unit Cost must be between 0 and 999,999',
  },
  installationCostLabel: {
    id: `${scope}.installationCostLabel`,
    defaultMessage: 'Installation Cost',
  },
  installationCostRequiredErrorMessage: {
    id: `${scope}.installationCostRequiredErrorMessage`,
    defaultMessage: 'Installation Cost is required',
  },
  installationCostPatternErrorMessage: {
    id: `${scope}.installationCostPatternErrorMessage`,
    defaultMessage: 'Installation Cost must be between 0 and 999,999',
  },
  alertDeactivateESSBatteryTitle: {
    id: `${scope}.alertDeactivateESSBatteryTitle`,
    defaultMessage: 'Deactivate ESS - Battery',
  },
  alertActivateESSBatteryTitle: {
    id: `${scope}.alertActivateESSBatteryTitle`,
    defaultMessage: 'Activate ESS - Battery',
  },
  batteryPowerRatingPatternErrorMessage: {
    id: `${scope}.batteryPowerRatingPatternErrorMessage`,
    defaultMessage: 'Hardware Power Rating limit must be between 0.1 and 9,999.0',
  },
  usableEnergyCapacityTitle: {
    id: `${scope}.usableEnergyCapacityTitle`,
    defaultMessage: 'Usable Energy Capacity',
  },
  // #endregion

  // #region ESS Converter Resources
  alertDeactivateESSConverterTitle: {
    id: `${scope}.alertDeactivateESSConverterTitle`,
    defaultMessage: 'Deactivate ESS - Converter',
  },
  alertActivateESSConverterTitle: {
    id: `${scope}.alertActivateESSConverterTitle`,
    defaultMessage: 'Activate ESS - Converter',
  },
  alertDeactivateEVTitle: {
    id: `${scope}.alertDeactivateEVTitle`,
    defaultMessage: 'Deactivate EV',
  },
  alertActivateEVTitle: {
    id: `${scope}.alertActivateEVTitle`,
    defaultMessage: 'Activate EV',
  },
  alertActiveteLabel: {
    id: `${scope}.alertActiveteLabel`,
    defaultMessage: 'Activate',
  },
  alertDeleteLabel: {
    id: `${scope}.alertDeleteLabel`,
    defaultMessage: 'Delete',
  },
  alertDeleteMessage: {
    id: `${scope}.alertDeleteMessage`,
    defaultMessage: 'Are you sure you want to delete a Component Configuration?',
  },
  alertDeleteTitle: {
    id: `${scope}.alertDeleteTitle`,
    defaultMessage: 'Delete Component',
  },
  powerRatingLabel: {
    id: `${scope}.powerRatingLabel`,
    defaultMessage: 'Hardware Power Rating',
  },
  essConverterEfficiencyLabel: {
    id: `${scope}.essConverterEfficiencyLabel`,
    defaultMessage: 'Converter Efficiency',
  },
  inverterEfficiencyLabel: {
    id: `${scope}.inverterEfficiencyLabel`,
    defaultMessage: 'Inverter Efficiency',
  },
  staticLossLabel: {
    id: `${scope}.staticLossLabel`,
    defaultMessage: 'Static Loss',
  },
  powerRatingRequiredErrorMessage: {
    id: `${scope}.powerRatingRequiredErrorMessage`,
    defaultMessage: 'Hardware Power Rating is required',
  },
  powerRatingPatternErrorMessage: {
    id: `${scope}.powerRatingPatternErrorMessage`,
    defaultMessage: 'Hardware Power Rating must be between 0.1 and 9,999.0',
  },
  essConverterPowerRatingRangeErrorMessage: {
    id: `${scope}.essConverterPowerRatingRangeErrorMessage`,
    defaultMessage: 'Hardware Power Rating limit must be between 0.1 and 9,999.0',
  },
  solarPowerRatingPatternErrorMessage: {
    id: `${scope}.solarPowerRatingPatternErrorMessage`,
    defaultMessage: 'Hardware Power Rating limit must be between 0.1 and 9,999.0',
  },
  inverterEfficiencyRequiredErrorMessage: {
    id: `${scope}.inverterEfficiencyRequiredErrorMessage`,
    defaultMessage: 'Inverter Efficiency is required',
  },
  inverterEfficiencyPatternErrorMessage: {
    id: `${scope}.inverterEfficiencyPatternErrorMessage`,
    defaultMessage: 'Inverter Efficiency must be between 0.1 and 100.0',
  },
  converterEfficiencyRequiredErrorMessage: {
    id: `${scope}.converterEfficiencyRequiredErrorMessage`,
    defaultMessage: 'Converter Efficiency is required',
  },
  converterEfficiencyPatternErrorMessage: {
    id: `${scope}.inverterEfficiencyPatternErrorMessage`,
    defaultMessage: 'Connverter Efficiency must be between 0.1 and 100.0',
  },
  staticLossRequiredErrorMessage: {
    id: `${scope}.staticLossRequiredErrorMessage`,
    defaultMessage: 'Static Loss is required',
  },
  staticLossPatternErrorMessage: {
    id: `${scope}.staticLossPatternErrorMessage`,
    defaultMessage: 'Static Loss must be between 0.000 and 9,999.000',
  },
  engineTypeLabel: {
    id: `${scope}.engineTypeLabel`,
    defaultMessage: 'Engine Type',
  },
  engineTypeRequiredError: {
    id: `${scope}.engineTypeRequiredError`,
    defaultMessage: 'Engine Type is required',
  },
  heatProductionLabel: {
    id: `${scope}.heatProductionLabel`,
    defaultMessage: 'Heat Production (Optional)',
  },
  heatProductionRequiredErrorMessage: {
    id: `${scope}.heatProductionRequiredErrorMessage`,
    defaultMessage: 'Heat Production is required',
  },
  heatProductionPatternErrorMessage: {
    id: `${scope}.heatProductionPatternErrorMessage`,
    defaultMessage: 'Heat Production must be between 0 and 9,999.99',
  },
  converterEfficiencyLabel: {
    id: `${scope}.converterEfficiencyLabel`,
    defaultMessage: 'Converter Efficiency',
  },
  titleNewESSConverter: {
    id: `${scope}.titleNewESSConverter`,
    defaultMessage: 'New ESS - Converter Component',
  },
  viewTitleNewESSConverter: {
    id: `${scope}.viewTitleNewESSConverter`,
    defaultMessage: 'ESS - Converter Component',
  },
  // #endregion

  // #region ESS Inverter Resources
  alertActivateESSInverterTitle: {
    id: `${scope}.alertActivateESSInverterTitle`,
    defaultMessage: 'Activate ESS - Inverter',
  },
  alertDeactivateESSInverterTitle: {
    id: `${scope}.alertDeactivateESSInverterTitle`,
    defaultMessage: 'Deactivate ESS - Inverter',
  },
  titleNewESSInverter: {
    id: `${scope}.titleNewESSInverter`,
    defaultMessage: 'New ESS - Inverter Component',
  },
  titleViewESSInverter: {
    id: `${scope}.titleViewESSInverter`,
    defaultMessage: 'ESS - Inverter Component',
  },
  essInverterPowerRatingPatternErrorMessage: {
    id: `${scope}.essInverterPowerRatingPatternErrorMessage`,
    defaultMessage: 'Hardware Power Rating limit must be between 0.1 and 9,999.0',
  },
  // #endregion

  // #region Solar Inverter Resources

  alertDeactivateSolarInverterTitle: {
    id: `${scope}.alertDeactivateSolarInverterTitle`,
    defaultMessage: 'Deactivate Solar - Inverter',
  },
  alertActivateSolarInverterTitle: {
    id: `${scope}.alertActivateSolarInverterTitle`,
    defaultMessage: 'Activate Solar - Inverter',
  },
  // #endregion

  // #region Solar Inverter Resources
  solarPowerRatingRangeErrorMessage: {
    id: `${scope}.solarPowerRatingRangeErrorMessage`,
    defaultMessage: 'Hardware Power Rating limit must be between 0.1 and 9,999.0',
  },
  solarInverterStaticLossRangeErrorMessage: {
    id: `${scope}.solarInverterStaticLossRangeErrorMessage`,
    defaultMessage: 'Static Loss must be between 0.000 and 9,999.000',
  },
  // #endregion

  // #region Solar Inverter Resources
  alertDeactivateDCACInverterTitle: {
    id: `${scope}.alertDeactivateDCACInverterTitle`,
    defaultMessage: 'Deactivate DC/AC Bus - Inverter',
  },
  alertActivateDCACInverterInverterTitle: {
    id: `${scope}.alertActivateDCACInverterInverterTitle`,
    defaultMessage: 'Activate DC/AC Bus - Inverter',
  },
  // #endregion

  // #region Solar Converter Resources
  solarConverterStaticLossRangeErrorMessage: {
    id: `${scope}.solarConverterStaticLossRangeErrorMessage`,
    defaultMessage: 'Static Loss must be between 0.000 and 9,999.000',
  },
  createSolarConverterComponentModalTitle: {
    id: `${scope}.createSolarConverterComponentModalTitle`,
    defaultMessage: 'New Solar - Converter Component',
  },
  viewSolarConverterComponentModalTitle: {
    id: `${scope}.viewSolarConverterComponentModalTitle`,
    defaultMessage: 'Solar - Converter Component',
  },
  alertActivateSolarConverterTitle: {
    id: `${scope}.alertActivateSolarConverterTitle`,
    defaultMessage: 'Activate Solar - Converter',
  },
  alertDeactivateSolarConverterTitle: {
    id: `${scope}.alertDeactivateSolarConverterTitle`,
    defaultMessage: 'Deactivate Solar - Converter',
  },
  chargeRateLabel: {
    id: `${scope}.chargeRateLabel`,
    defaultMessage: 'Charge Rate',
  },
  chargeRateRequiredErrorMessage: {
    id: `${scope}.chargeRateRequiredErrorMessage`,
    defaultMessage: 'Charge Rate is required',
  },
  chargeRatePatternErrorMessage: {
    id: `${scope}.chargeRatePatternErrorMessage`,
    defaultMessage: 'Charge Rate must be between 0.00 and 999.99',
  },
  chargeRateFleetPatternErrorMessage: {
    id: `${scope}.chargeRateFleetPatternErrorMessage`,
    defaultMessage: 'Charge Rate must be between 0.00 and 999.99',
  },
  chargeEfficiencyLabel: {
    id: `${scope}.chargeEfficiencyLabel`,
    defaultMessage: 'Charging Efficiency',
  },
  chargeEfficiencyRequiredErrorMessage: {
    id: `${scope}.chargeEfficiencyRequiredErrorMessage`,
    defaultMessage: 'Charging Efficiency is required',
  },
  chargeEfficiencyPatternErrorMessage: {
    id: `${scope}.chargeEfficiencyPatternErrorMessage`,
    defaultMessage: 'Charging Efficiency must be between 0.1 and 100.0',
  },
  createEVComponentModalTitle: {
    id: `${scope}.createEVComponentModalTitle`,
    defaultMessage: 'New EV Component',
  },
  viewEVComponentModalTitle: {
    id: `${scope}.viewEVComponentModalTitle`,
    defaultMessage: 'EV Component',
  },
  editEVComponentModalTitle: {
    id: `${scope}.editEVComponentModalTitle`,
    defaultMessage: 'Edit EV Component',
  },
  createNewTitleSolarInverter: {
    id: `${scope}.createNewTitleSolarInverter`,
    defaultMessage: 'New Solar - Inverter Component',
  },
  viewTitleSolarInverter: {
    id: `${scope}.viewTitleSolarInverter`,
    defaultMessage: 'Solar - Inverter Component',
  },
  editTitleSolarInverter: {
    id: `${scope}.editTitleSolarInverter`,
    defaultMessage: 'Edit Solar - Inverter Component',
  },
  createNewTitleDCACBusInverter: {
    id: `${scope}.createNewTitleDCACBusInverter`,
    defaultMessage: 'New DC/AC Bus - Inverter Component',
  },
  viewTitleDCACBusInverter: {
    id: `${scope}.viewTitleDCACBusInverter`,
    defaultMessage: 'DC/AC Bus - Inverter Component',
  },
  editTitleDCACBusInverter: {
    id: `${scope}.editTitleDCACBusInverter`,
    defaultMessage: 'Edit DC/AC Bus - Inverter Component',
  },
  // #endregion
  alertDeactivateEngineTitle: {
    id: `${scope}.alertDeactivateEngineTitle`,
    defaultMessage: 'Deactivate Engine',
  },
  alertActivateEngineTitle: {
    id: `${scope}.alertActivateEngineTitle`,
    defaultMessage: 'Activate Engine',
  },
  createEngineComponentModalTitle: {
    id: `${scope}.createEngineComponentModalTitle`,
    defaultMessage: 'New Engine Component',
  },
  viewEngineComponentModalTitle: {
    id: `${scope}.viewEngineComponentModalTitle`,
    defaultMessage: 'Engine Component',
  },
  editEngineComponentModalTitle: {
    id: `${scope}.editEngineComponentModalTitle`,
    defaultMessage: 'Edit Engine Component',
  },
  createFleetComponentModalTitle: {
    id: `${scope}.createFleetComponentModalTitle`,
    defaultMessage: 'New Fleet Component',
  },
  viewFleetComponentModalTitle: {
    id: `${scope}.viewFleetComponentModalTitle`,
    defaultMessage: 'Fleet Component',
  },
  editFleetComponentModalTitle: {
    id: `${scope}.editFleetComponentModalTitle`,
    defaultMessage: 'Edit Fleet Component',
  },
  energyCapacityLabel: {
    id: `${scope}.energyCapacityLabel`,
    defaultMessage: 'Energy Capacity',
  },
  energyCapacityRequiredErrorMessage: {
    id: `${scope}.energyCapacityRequiredErrorMessage`,
    defaultMessage: 'Energy Capacity is required',
  },
  energyCapacityPatternErrorMessage: {
    id: `${scope}.energyCapacityPatternErrorMessage`,
    defaultMessage: 'Energy Capacity must be between 0 and 99,999',
  },
  chargeRateFleetLabel: {
    id: `${scope}.chargeRateFleetLabel`,
    defaultMessage: 'Per EV Charge Rate',
  },
  // #region Engine Resources
  enginePowerRatingRangeErrorMessage: {
    id: `${scope}.enginePowerRatingRangeErrorMessage`,
    defaultMessage: 'Hardware Power Rating must be between 0.1 and 9,999.0',
  },
  engineStaticLossRangeErrorMessage: {
    id: `${scope}.engineStaticLossRangeErrorMessage`,
    defaultMessage: 'Static Loss must be between 0.000 and 9,999.000',
  },
  // #endregion
  hasControls: {
    id: `${scope}.hasControls`,
    defaultMessage: 'Has Controls',
  },
  view: {
    id: `${scope}.view`,
    defaultMessage: 'View',
  },
});
