/* eslint-disable no-bitwise */
/* eslint-disable no-plusplus */

/**
 * Kind of similar to a printf() function, replacing all arguments on the first format string
 * @example: stringFormat('{0} has ${1} apples', 'Jimmy', 10);
 * returns 'Jimmy has 10 apples'
 * @method stringFormat
 * @param {String} _format String with the format to represent
 * @return CallExpression
 */
export function stringFormat(_format) {
  // eslint-disable-next-line prefer-rest-params
  const args = Array.prototype.slice.call(arguments, 1);
  // eslint-disable-next-line func-names
  return _format.replace(/\{(\d+)\}/g, (match, number) => (typeof args[number] !== 'undefined' ? args[number] : match));
}

/**
 * Applies a numeric format to any given value (adding a comma between every 3 spaces)
 * @example: numberFormat(12500) Returns '12,500.00';
 * @example: numberFormat(12500, 0) Returns '12,500';
 * @example: numberFormat('this is an invalid number', 0) Returns '0';
 * @example: numberFormat('this is an invalid number', 2, '') Returns '';
 * @method numberFormat
 * @param value value to apply format
 * @param {Number} _decimals
 * @param {Number} _invalidVal
 * @param {String} _format
 * @return
 */
export function numberFormat(_value, _decimals, _invalidVal, _format) {
  const decimals = _decimals != null ? _decimals : 2;
  let value = `${_value || ''}`;
  const format = _format || '{0}';
  if (/(?!-)[^0-9.,]/g.test(value)) {
    return _invalidVal || 0;
  }
  value = value.replace(/[^\d.-]/g, '');
  value = Number(value);
  if (value || (!value && _invalidVal == null)) {
    if (decimals !== -1) {
      value = (value || 0).toFixed(decimals);
    } else {
      value = `${value || 0}`;
    }
    const parts = value.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return stringFormat(format, parts.join('.'));
  }
  return _invalidVal;
}

/*
 * round
 * Rounds a number
 * @param {number} value Value to validate
 * @param {number} decimals how many decimals should it round to
 * return number
 */
export function round(value, decimals) {
  const formatNumber = Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);
  return Number.isNaN(formatNumber) ? 0 : formatNumber;
}

// Configuration Settings Helpers

/*
 * formatThousandsNumber (v2)
 * Formats a number to display a thousands format
 * @param {string|number} value Value to validate
 * @param {Boolean} allowDecimal Flag to allow decimals
 * return object
 */
export const formatThousandsNumber = (value, allowDecimal = true) => {
  let formattedVal = '';
  if (value !== undefined && value !== null && value !== '') {
    let valueWithoutCommas = value.toString().replace(/,/g, '');
    if (!allowDecimal) {
      // eslint-disable-next-line no-bitwise
      valueWithoutCommas <<= 0;
    }
    if (valueWithoutCommas.toString().includes('.')) {
      const splitString = valueWithoutCommas.toString().split('.');
      const decimalValue =
        splitString[1] || splitString[1].substring(0, 1) === 0 || splitString[1].substring(0, 1) === '0' ? splitString[1] : '';
      formattedVal = `${parseInt(splitString[0] || '0', 10).toLocaleString('en-US')}.${decimalValue.replace(/[^\d]/g, '')}`;
    } else {
      formattedVal = parseInt(valueWithoutCommas, 10).toLocaleString('en-US');
    }
  }
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(parseFloat(formattedVal.toString().replace(/,/g, '')))) {
    formattedVal = '';
  }
  return formattedVal;
};

/**
 * trucateFileName
 * @param {string} fileName Name of the file to truncate
 * @param {number} fileNameLength Max length allowed before truncating
 * @returns {string}
 */
export function trucateFileName(fileName, fileNameLength) {
  if (fileName && fileName.length > fileNameLength) {
    const re = /(?:\.([^.]+))?$/;
    const fileNameExtension = re.exec(fileName) || '';
    const truncatedFileName = `${fileName.substring(0, fileNameLength - 4)}...${fileNameExtension[1]}`;
    return truncatedFileName;
  }
  return fileName;
}

/*
 * validateDecimalValue
 * Validates a decimal value
 * @param {string} value Value to validate
 * return boolean
 */
export function validateDecimalValue(value, decimals) {
  const decimalValue = decimals || 2;
  let decimalNumberRegExp = /^(\d*)((\.?))(\d{1,2})?$/i;
  // TODO: Refactor this function
  if (decimalValue === 1) {
    decimalNumberRegExp = /^(\d*)((\.?))(\d{1})?$/i;
  } else if (decimals === 3) {
    decimalNumberRegExp = /^(\d*)((\.?))(\d{1,3})?$/i;
  } else if (decimals === 4) {
    decimalNumberRegExp = /^(\d*)((\.?))(\d{1,4})?$/i;
  } else if (decimals === 6) {
    decimalNumberRegExp = /^(\d*)((\.?))(\d{1,6})?$/i;
  }

  return decimalNumberRegExp.test(value);
}

/**
 * toFixedNoRounding
 * @param {*} number number to fix
 * @param {*} decimalsToFix number of decimals
 * @returns fixed number
 */
export function toFixedNoRounding(number, decimalsToFix) {
  if (!(number?.length === 0 || number !== '0')) {
    return number;
  }
  if (number === '' || number?.toString()?.includes(' ') || /(?!-)[^0-9.,]/g.test(number)) {
    return number;
  }
  const regExp = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimalsToFix}})?`, 'g');
  const intPart = number?.toString()?.match(regExp)[0];
  const dot = intPart?.indexOf('.');
  if (dot === -1) {
    // integer, insert decimal dot and pad up zeros
    return `${intPart}.${'0'.repeat(decimalsToFix)}`;
  }
  const trailingZeros = decimalsToFix - (intPart.length - dot) + 1;
  return trailingZeros > 0 ? intPart + '0'.repeat(trailingZeros) : intPart;
}

/*
 * getFixedValue
 * @param {*} value number as string
 * @param {*} fractionDigits number of digits for value to be fixed
 */
export function getFixedValue(value, fractionDigits = 2) {
  let fixedValue = '0';

  if (value && value.toString().length > 0) {
    if (value.toString().length === 1 && value === '0') {
      return fixedValue;
    }

    fixedValue = parseFloat(value).toFixed(fractionDigits);
  } else {
    fixedValue = '';
  }
  return fixedValue;
}

/**
 * decimalPercentageToPercentage
 * Example: converts 0.25 to 25.0 converts 1 to 100.0 Useful for displaying percentages in more human readable form
 * @param {number} value value to conbert
 * @param {number} decimalsToFix number of trailing decimals, defaulting to 1.
 * @returns formatted number
 */
export function decimalPercentageToPercentage(value, decimalsToFix = 1) {
  return (value * 100).toFixed(decimalsToFix);
}

/**
 * validateDecimalNumericValue also the number of decimals received from the max value string is validated
 * @param {string} value Input value to validate
 * @param {string} maxValue Max value to validate against with
 * @param {boolean} hasBeenTouched Flag to determine if the input have ever been focused
 * @param {number} minValue Min valid value to validate against with
 * @param {boolean} validateDeciamls Flag to determine if we must validate the number of decimals in the maxValue and the current value
 * @returns object
 */
export function validateDecimalNumericValue(value, maxValue, hasBeenTouched, minValue = 0, validateDecimals = false) {
  const stringValue = `${value}`;
  const isNumber = !Number.isNaN(parseFloat(stringValue.replace(/,/g, ''))) || stringValue === '0';
  const REQUIRED_ERROR = 'required';
  const RANGE_ERROR = 'range';
  let errorType = '';
  let isValid = isNumber;
  if (hasBeenTouched) {
    const isInputEmpty = stringValue.length === 0;
    errorType = isInputEmpty ? REQUIRED_ERROR : '';
    isValid = !isInputEmpty && isNumber;
    if (!isInputEmpty) {
      // Is decimals valid ?
      const maxDecimals = maxValue.split('.').length > 1 ? maxValue.split('.')[1].length : 0;
      const currentDecimals = value.split('.').length > 1 ? value.split('.')[1].length : 0;
      if (validateDecimals && currentDecimals > maxDecimals) {
        isValid = false;
        errorType = RANGE_ERROR;
      } else {
        // Is the number within the range ?
        const numberValue = parseFloat(stringValue.replace(/,/g, ''));

        if (numberValue > parseFloat(maxValue) || numberValue < minValue) {
          isValid = false;
          errorType = RANGE_ERROR;
        }
      }
    }
  }
  return { errorType, isValid };
}

/**
 * stringToColor
 * Transform a string to a hex color
 * @param {String} string String to be converted into a custom hex color
 * @returns Hex color
 */
export function stringToColor(string) {
  let hash = 0;
  let colorResult = '#';
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  const colorString = (hash & 0x00ffffff).toString(16).toUpperCase();
  colorResult += '00000'.substring(0, 6 - colorString.length) + colorString;
  return colorResult;
}

/**
 * stringAvatar
 * Format a name into an initals and background color object to use in the Avatar control
 * @param {String} name Name to show in the avatar control, First Name Last Name (John Doe)
 * @returns Object with the background color and Initials for the Avatar control
 */
export function stringAvatar(name) {
  const formattedName = name.split(' ');
  return {
    style: {
      backgroundColor: stringToColor(name),
    },
    children: `${formattedName[0][0]}${formattedName[1] ? formattedName[1][0] : ''}`,
  };
}

/**
 * getValueOrEmptyString
 * Check if the value is falsy otherwise return an empty string
 * @param {Object} value Variable to test
 * @returns Variable sent or default empty string
 */
export function getValueOrEmptyString(value) {
  return value || '';
}

/**
 * getValueWithCeroOrEmptyString
 * Check if the value is not falsy, allowing ceroes, otherwise return an empty string
 * @param {Object} value Variable to test
 * @returns Variable sent or default empty string
 */
export function getValueWithCeroOrEmptyString(value) {
  if (value) {
    return value;
  }
  if (typeof value === 'number' && value === 0) {
    return 0;
  }
  return '';
}

/**
 * getNextRoundUpValue
 * Rounds up to the next limit value number
 * @param {Number} value Number to round up
 */
export function getNextRoundUpValue(value) {
  if (typeof value === 'number') {
    // Get the first number and add 1
    let numberToCheck = value;
    // Check decimal values
    const isDecimal = value.toString(10).indexOf('.') > 0;
    const firstNumber = parseInt(value.toString(10).slice(0, 1), 10);
    if (isDecimal && firstNumber === 0) {
      const decimalNumber = value.toString(10).split('.');
      numberToCheck = parseInt(decimalNumber[1].toString(10).slice(0, 1), 10);
    }

    // Check thousand values
    let result = numberToCheck > 10 ? parseInt(numberToCheck.toString(10).slice(0, 1), 10) + 1 : numberToCheck;
    const integerPart = parseInt(numberToCheck, 10);
    let additionalZero = integerPart.toString(10).length;
    additionalZero = result > 9 ? additionalZero + 1 : additionalZero; // There's a 10 as the next number, an extra 0 is needed

    // Add 0's at the end
    result = result.toString().padEnd(additionalZero, '0');
    if (isDecimal && firstNumber === 0) {
      result = `0.${result}`;
    }

    return isDecimal && firstNumber === 0 ? parseFloat(result) : parseInt(result, 10);
  }
  return 0;
}

export function dateRangeOverlaps(firstStart, firstEnd, secondStart, secondEnd) {
  if (firstStart < secondStart && secondStart < firstEnd) return true; // b starts in a
  if (firstStart < secondEnd && secondEnd < firstEnd) return true; // b ends in a
  if (secondStart < firstStart && firstEnd < secondEnd) return true; // a in b
  return false;
}

/**
 * csvJSON
 * @param {String} csv CSV to transform
 * @returns JSON object of the CSV text
 */
export function csvJSON(csv) {
  const lines = csv.split('\n');
  const headers = lines[0].replaceAll('"', '').split(',');
  const regex = /\s*(")?(.*?)\1\s*(?:,|$)/gs;
  let result = null;

  const isLinesEmpty = lines.shift() === undefined;
  if (!isLinesEmpty) {
    const match = (line) => {
      const matches = [...line.matchAll(regex)].map((m) => m[2]);
      matches.pop(); // cut off blank match at the end
      return matches;
    };

    result = lines.map((line) =>
      match(line).reduce((accumulated, current, index) => {
        const val = current.length <= 0 ? null : current;
        const key = headers[index] ?? `extra_${index}`;
        return { ...accumulated, [key]: val };
      }, {}),
    );
    result.pop();
  }
  return result;
}

/**
 * capitalizeFirstLetter
 * Use this to capitalize the first letter of a word when CSS 'text-transform: capitalize' doesn't work
 * 'text-transform: capitalize' doesn't cap only the first letter when the source string is hard coded as all caps
 * For example, CSS cannot transform 'YES' to 'Yes'.
 *
 * @param {string} wordString string (single word) to convert
 * @returns string (single word) with the first letter capitalized only
 */
export function capitalizeFirstLetter(wordString) {
  const firstLetterToCap = wordString.charAt(0).toUpperCase();
  const restOfLetters = wordString.slice(1).toLowerCase();
  return firstLetterToCap + restOfLetters;
}
