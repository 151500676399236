const useStyles = () => ({
  cancelButton: {
    order: 1,
    marginRight: '2rem',
  },
  saveButton: {
    order: 2,
  },
  descriptionContent: {
    inlineSize: '26rem',
    overflowWrap: 'break-word',
  },
});

export default useStyles;
