import { Colors } from '../../../../theme';

const useStyles = () => ({
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    maxWidth: '9.85rem',
  },
  rightText: {
    marginLeft: '0.5rem',
    minWidth: '2rem',
  },
  smallInputContainer: {
    width: '7.312rem',
  },
  unitText: {
    marginLeft: '0.5rem',
  },
  regularInputContainer: { width: '10rem' },
  currencyText: {
    marginRight: '0.732rem',
  },
  totalPower: {
    marginRight: '0.744rem',
  },
  addNew: {
    marginTop: '1rem',
  },
  operationalRowContainer: {
    marginTop: '0.5rem',
    height: '2.5rem',
    borderRadius: '2px',
    border: 'solid 1px',
    borderColor: Colors.mercury,
    backgroundColor: Colors.athensGray,
    display: 'flex',
    alignItems: 'center',
  },
  cancelButton: {
    order: 1,
    marginRight: '2rem',
  },
  saveButton: {
    order: 2,
  },
  fuelTypeSelect: {
    width: '10rem',
  },
  emissionType: {
    width: '10rem',
  },
  hideShowEmissionsTypes: {
    height: '0.1rem',
  },
  engineTypeSelect: {
    width: '10rem',
  },
});

export default useStyles;
