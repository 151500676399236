/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
/**
 *
 * SelectCustomOptions.js
 * Custom select
 */
import Avatar from '@mui/material/Avatar';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import images from '../../../assets/images';
import useStyles from './styles';

/**
 * SelectCustomOptions
 * @param {string} placeholder Text to show when nothing is selected
 * @param {Object[text, value]} selectData Data to show
 * @param {boolean} invalid Flag to show invalid style
 * @param {Object} errorText Text to show when error is shown
 * @param {boolean} disabled Flag to disable control
 * @param {function} onChange Function to execute when changing value
 */
function SelectCustomOptions({
  placeholder = '',
  selectData = [],
  invalid = false,
  disabled = false,
  errorText,
  selectedObject = {},
  error = false,
  onChange,
  onClose,
  dependendantProperty = null, // In case this select changes when other property changes
  onBlur,
  dataTestId,
}) {
  const classes = useStyles({ invalid });
  const [selectedOption, setSelectedOption] = React.useState(selectedObject.value || '');

  React.useEffect(() => {
    if (selectedObject.value && selectedObject.value !== selectedOption) {
      setSelectedOption(selectedObject.value);
    }
  }, [dependendantProperty]);

  /**
   * handleSelect
   * @param {string} text Text to show
   * @param {object} value Value to return
   */
  const handleSelect = (text = null, value = null) => {
    setSelectedOption(text);
    onChange(value);
  };

  const children = selectData.map((element) => (
    <MenuItem
      data-testid={`custom-options-select-${element.value}`}
      value={element.text}
      key={element.value}
      onClick={() => handleSelect(element.text, element.value)}
      disabled={element.disabled}
    >
      {element.icon && (
        <ListItemAvatar>
          <Avatar alt={element.text} src={element.icon} className={classes.avatar} />
        </ListItemAvatar>
      )}
      <Typography data-testid="select-element-text" className={classes.childrenText}>
        {element.text}
      </Typography>
    </MenuItem>
  ));

  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="select-native-simple" className={classes.placeholder}>
        {placeholder}
      </InputLabel>
      <Select
        fullWidth
        aria-controls="search-menu"
        aria-haspopup="true"
        disabled={disabled}
        value={selectedOption}
        placeholder={placeholder}
        className={classes.selectMock}
        variant="outlined"
        id="select-native-simple"
        onClose={onClose}
        error={error}
        IconComponent={(props) => <img alt="select-arrow" src={images.chevronIcon} className={classes.selectWrapper} {...props} />}
        onBlur={onBlur}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        data-testid={dataTestId}
      >
        {children}
      </Select>
      <Typography className={classes.errorInput}>{errorText}</Typography>
    </FormControl>
  );
}

SelectCustomOptions.propTypes = {
  placeholder: PropTypes.string,
  selectData: PropTypes.array,
  onChange: PropTypes.any,
  invalid: PropTypes.bool,
  disabled: PropTypes.bool,
  errorText: PropTypes.object,
  selectedObject: PropTypes.object,
  onClose: PropTypes.func,
  error: PropTypes.bool,
  dependendantProperty: PropTypes.any,
  onBlur: PropTypes.func,
  dataTestId: PropTypes.string,
};

export default SelectCustomOptions;
