/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
import { Box, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as Yup from 'yup';
import AlertModal from '../../../../components/AlertModal';
import Button from '../../../../components/Button';
import FormikInput from '../../../../components/FormikInput';
import FormikSwitch from '../../../../components/FormikSwitch';
import Modal from '../../../../components/Modal';
import { commonValidators } from '../../../../utils/yupValidation';
import messages from '../messages';
import useStyles from './styles';

const DOLLAR_SYMBOL = '$';
// field IDs - these are used by formik to access field properties
const MANUFACTURER = 'manufacturer';
const PART_NUMBER = 'partNumber';
const HAS_CONTROLS = 'hasControls';
const UNIT_COST = 'unitCost';
const INSTALLATION_COST = 'installationCost';

const EnclosureModal = (props) => {
  const { classes, isModalVisible, defaultData, onCancel, onAccept, intl, readonly } = props;

  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);

  const initialValues = {
    manufacturer: `${defaultData?.manufacturer ?? ''}`,
    partNumber: `${defaultData?.partNumber ?? ''}`,
    hasControls: `${defaultData?.isMaster ? 'right' : 'left'}`,
    unitCost: `${defaultData?.unitCost ?? ''}`,
    installationCost: `${defaultData?.installationCost ?? ''}`,
  };

  const validationSchema = Yup.object({
    manufacturer: commonValidators.REQUIRED_STRING,
    partNumber: commonValidators.REQUIRED_STRING,
    hasControls: commonValidators.REQUIRED_STRING,
    unitCost: commonValidators.REQUIRED_NUMBER_COMMA_VALIDATION_MIN_MAX('0', '999,999'),
    installationCost: commonValidators.REQUIRED_NUMBER_COMMA_VALIDATION_MIN_MAX('0', '999,999'),
    isActive: Yup.boolean(),
  });

  /**
   * checkIfIsValid
   * @param value given a field value, uses the validationSchema to validate the field.
   * @returns set the state of isSaveButtonEnabled true or false based on the results.
   */
  const checkIfIsValid = (value) =>
    validationSchema
      .validate(value)
      .then(() => {
        setIsSaveButtonEnabled(true);
      })
      .catch(() => {
        setIsSaveButtonEnabled(false);
      });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validate: checkIfIsValid,
  });

  /**
   * handleSave
   * Saves the form values on button press by sending them to the api.
   * Make sure to format data first (remove commas, convert booleans, etc)
   */
  const handleSave = () => {
    const formVals = formik?.values;
    const saveData = {
      manufacturer: formVals?.manufacturer,
      partNumber: formVals?.partNumber,
      isMaster: formVals?.hasControls !== 'left',
      unitCost: Number(formVals?.unitCost.toString().replace(/,/g, '')),
      installationCost: Number(formVals?.installationCost.toString().replace(/,/g, '')),
      isActive: defaultData?.isActive || true,
    };
    onAccept(saveData);
  };

  /**
   * handleCancel
   * Primary modal cancel button
   */
  const handleCancel = () => {
    setIsCancelModalVisible(false);
    onCancel();
  };

  const modalTitle = intl.formatMessage(readonly ? messages.viewEnclosureModalTitle : messages.enclosureModalTitle);

  const paperPropsStyle = { maxWidth: '30rem' };

  return (
    <>
      {isCancelModalVisible && (
        <AlertModal
          title={messages.alertCancelChangesTitle}
          message={messages.alertCancelChangesMessage}
          isVisible={isCancelModalVisible}
          acceptLabel={messages.alertYesLabel}
          cancelLabel={messages.alertNoLabel}
          onAccept={handleCancel}
          onCancel={() => setIsCancelModalVisible(false)}
          size="30rem"
        />
      )}
      <Modal open={isModalVisible} title={modalTitle} paperPropsStyle={paperPropsStyle}>
        <form>
          <>
            <FormikInput
              id={MANUFACTURER}
              dataTestId={`input-${MANUFACTURER}`}
              dataTestWrapperId={`input-wrapper-${MANUFACTURER}`}
              title={messages.manufactureLabel}
              readonly={readonly}
              formik={formik}
              maxLength={200}
            />
            <FormikInput
              id={PART_NUMBER}
              dataTestId={`input-${PART_NUMBER}`}
              dataTestWrapperId={`input-wrapper-${PART_NUMBER}`}
              title={messages.partNumberLabel}
              readonly={readonly}
              formik={formik}
              maxLength={100}
            />

            <Grid container>
              <Grid item xs={6}>
                <Box marginTop={readonly ? '' : 1}>
                  <FormattedMessage {...messages.hasControls} />
                </Box>
              </Grid>
              <Grid item xs={6} container justifyContent="flex-end">
                <Box paddingBottom={4}>
                  <Grid container justify="flex-end">
                    <FormikSwitch
                      id={HAS_CONTROLS}
                      leftText={intl.formatMessage(messages.capsNoLabel)}
                      rightText={intl.formatMessage(messages.capsYesLabel)}
                      formik={formik}
                      readonly={readonly}
                    />
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <FormikInput
              id={UNIT_COST}
              dataTestId={`input-${UNIT_COST}`}
              dataTestWrapperId={`input-wrapper-${UNIT_COST}`}
              title={messages.unitCostLabel}
              formatByThousands
              integersOnly
              maxLength={200}
              leftUnit={DOLLAR_SYMBOL}
              readonly={readonly}
              formik={formik}
              alignRight
            />
            <FormikInput
              id={INSTALLATION_COST}
              dataTestId={`input-${INSTALLATION_COST}`}
              dataTestWrapperId={`input-wrapper-${INSTALLATION_COST}`}
              title={messages.installationCostLabel}
              formatByThousands
              integersOnly
              maxLength={200}
              leftUnit={DOLLAR_SYMBOL}
              readonly={readonly}
              formik={formik}
              alignRight
            />
          </>
        </form>
        {!readonly && (
          <>
            <div className={classes.saveButton}>
              <Button
                handleRoute={handleSave}
                disabled={!isSaveButtonEnabled}
                type="submit"
                disableRipple={false}
                data-testid="save-button"
              >
                {intl.formatMessage(messages.save)}
              </Button>
            </div>
            <div className={classes.cancelButton}>
              <Button onClick={() => setIsCancelModalVisible(true)}>{intl.formatMessage(messages.cancel)}</Button>
            </div>
          </>
        )}
        {readonly && (
          <Button onClick={() => onCancel()}>
            <FormattedMessage {...messages.close} />
          </Button>
        )}
      </Modal>
    </>
  );
};

EnclosureModal.propTypes = {
  id: PropTypes.string,
  classes: PropTypes.object,
  readonly: PropTypes.bool,
  onCancel: PropTypes.func,
  onAccept: PropTypes.func,
  isModalVisible: PropTypes.bool,
  defaultData: PropTypes.shape({
    manufacturer: PropTypes.string,
    partNumber: PropTypes.string,
    isMaster: PropTypes.bool,
    unitCost: PropTypes.string,
    installationCost: PropTypes.string,
    isActive: PropTypes.bool,
  }),
  intl: PropTypes.object,
};

export default withStyles(useStyles)(injectIntl(EnclosureModal));
