const configurationStatus = {
  new: 'New',
  inProgress: 'In Progress',
  inReview: 'In Review',
  underContract: 'Under Contract',
  sentToIcian: 'Sent to ICIAN',
  archive: 'Archive',
  archiveValue: 'Archived',
  lost: 'Lost',
  lostOption: 'Mark as Lost',
  clone: 'Clone',
};

export default configurationStatus;
