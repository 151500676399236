/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/function-component-definition */
/**
 *
 * Header
 * Header, if you pass it a prop "route" it'll render a link to a react-router route
 * otherwise it'll render a link with an onclick
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import HeaderDashboard from './HeaderDashboard';
import HeaderDetails from './HeaderDetails';
import HeaderConfiguration from './HeaderConfiguration';
import HeaderQuickConfiguration from './HeaderQuickConfiguration';
import HeaderComponentManager from './HeaderComponentManager';
import useStyles from './styles';
import HeaderTariffDetails from './HeaderTariffDetails';

/**
 * HeaderConfiguration
 * @param {string} title Title to show
 * @param {string} subtitle1 Subtitle to show
 * @param {string} subtitle2 Subtitle to show in green format
 * @param {boolean} dashboard Flag to show dashboard header
 * @param {boolean} details Flag to show details header
 * @param {boolean} configuration Flag to show configuration header
 * @param {boolean} quickConfiguration Flag to show quick configuration header
 * @param {object} breadcrumbs Breadcrumbs to show
 * @param {Object[]{text:'',value:'',icon:''}} users Users to show in list
 * @param {Object[]{text:'',value:''}} status Status to show in list
 * @param {function} handleTabChange Event to execute when clicking the tab buttons
 * @param {function} newConfiguration Event to execute when clicking the new button
 * @param {string} masterTariffId Master Tariff Id
 * @param {string} tariffId  Tariff Id
 * @param {function} onSave Method to trigger then the save button is pressed
 * @param {function} onGoBack Method to trigger then the back icon is pressed
 */
const Header = ({
  title = '',
  subtitle1 = '',
  subtitle2 = '',
  dashboard = false,
  details = false,
  configuration = false,
  quickConfiguration = false,
  tariffDetails = false,
  breadcrumbs = [],
  users = [],
  status = [],
  tabSelected = 0,
  handleTabChange,
  newConfiguration,
  handleSearch,
  handleSelectChange,
  handleEditSubtitle,
  showActive,
  isQuickConfiguration,
  componentManager,
  handleCreateNewComponent,
  isAdmin,
  masterTariffId = '',
  tariffId = '',
  onSave,
  onGoBack,
  disableSaveButton = true,
  showMine,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.wrapper}>
      {dashboard && (
        <HeaderDashboard
          tabSelected={tabSelected}
          title={title}
          handleTabChange={handleTabChange}
          users={users}
          handleSearch={handleSearch}
          handleSelectChange={handleSelectChange}
          showActive={showActive}
          showMine={showMine}
        />
      )}
      {details && <HeaderDetails title={title} subtitle1={subtitle1} breadcrumbs={breadcrumbs} selectedStatus={status} />}
      {configuration && (
        <HeaderConfiguration
          title={title}
          subtitle1={subtitle1}
          subtitle2={subtitle2}
          breadcrumbs={breadcrumbs}
          status={status[0]}
          handleEditSubtitle={handleEditSubtitle}
          isQuickConfiguration={isQuickConfiguration}
        />
      )}
      {quickConfiguration && <HeaderQuickConfiguration title={title} newConfiguration={newConfiguration} />}
      {componentManager && (
        <HeaderComponentManager
          tabSelected={tabSelected}
          title={title}
          handleTabChange={handleTabChange}
          handleSearch={handleSearch}
          showActive={showActive}
          onCreateNew={handleCreateNewComponent}
          isAdmin={isAdmin}
        />
      )}
      {tariffDetails && (
        <HeaderTariffDetails
          title={title}
          masterTariffId={masterTariffId}
          tariffId={tariffId}
          subtitle2={subtitle2}
          breadcrumbs={breadcrumbs}
          onSave={onSave}
          onGoBack={onGoBack}
          disableSaveButton={disableSaveButton}
        />
      )}
    </Box>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  subtitle1: PropTypes.string,
  subtitle2: PropTypes.string,
  dashboard: PropTypes.bool,
  details: PropTypes.bool,
  configuration: PropTypes.bool,
  quickConfiguration: PropTypes.bool,
  breadcrumbs: PropTypes.array,
  users: PropTypes.array,
  tabSelected: PropTypes.number,
  handleSearch: PropTypes.func,
  handleSelectChange: PropTypes.func,
  handleTabChange: PropTypes.func,
  newConfiguration: PropTypes.func,
  handleEditSubtitle: PropTypes.func,
  status: PropTypes.array,
  showActive: PropTypes.bool,
  isQuickConfiguration: PropTypes.bool,
  componentManager: PropTypes.bool,
  handleCreateNewComponent: PropTypes.func,
  isAdmin: PropTypes.bool,
  tariffDetails: PropTypes.bool,
  masterTariffId: PropTypes.number,
  tariffId: PropTypes.number,
  onSave: PropTypes.func,
  onGoBack: PropTypes.func,
  disableSaveButton: PropTypes.bool,
  showMine: PropTypes.bool,
};

export default Header;
