/*
 * AppBar Messages
 * This contains all the text for the AppBar component.
 */
import { defineMessages } from 'react-intl';

export const scope = 'app.containers.App';

export default defineMessages({
  yes: {
    id: `${scope}.yes`,
    defaultMessage: 'Yes',
  },
  no: {
    id: `${scope}.no`,
    defaultMessage: 'No',
  },
  ok: {
    id: `${scope}.ok`,
    defaultMessage: 'Ok',
  },
  errorModalTitle: {
    id: `${scope}.errorModalTitle`,
    defaultMessage: 'Notifications',
  },
  dismiss: {
    id: `${scope}.modalOklButtonNotifications`,
    defaultMessage: 'Ok',
  },
  signOutTitle: {
    id: `${scope}.signOutTitle`,
    defaultMessage: 'Logout?',
  },
  signOutBody: {
    id: `${scope}.signOutBody`,
    defaultMessage: 'Are you sure you want to logout?',
  },
  signOutUnsavedChangesBody: {
    id: `${scope}.signOutBody`,
    defaultMessage: 'You have unsaved changes that might not be saved. Are you sure you want to logout?',
  },
  OpportunityDashboardToolTip: {
    id: `${scope}.OpportunityDashboardToolTip`,
    defaultMessage: 'Opportunity Dashboard',
  },
  QuickConfigurationToolTip: {
    id: `${scope}.QuickConfigurationToolTip`,
    defaultMessage: 'Quick Configuration',
  },
  ComponentManagerToolTip: {
    id: `${scope}.ComponentManagerToolTip`,
    defaultMessage: 'Component Manager',
  },
});
