import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  lastUpdatedColumn: {
    minWidth: '10rem',
  },
  buttonsColumn: {
    minWidth: '12.812rem',
  },
  headerTitle: {
    fontWeight: 'bold',
  },
  manufacture: {
    minWidth: '12rem',
    textAlign: 'left',
  },
  partNumber: {
    minWidth: '10rem',
    textAlign: 'left',
  },
  created: {
    minWidth: '7rem',
    textAlign: 'left',
  },
  status: {
    minWidth: '2rem',
    textAlign: 'left',
  },
  buttonsCell: {
    spacing: '3rem',
    minWidth: '3rem',
  },
  iconButton: {
    marginRight: '10.5rem',
  },
}));
