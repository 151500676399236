export const GET_QUICK_CONFIGURATIONS_START = 'GET_QUICK_CONFIGURATIONS_START';
export const GET_QUICK_CONFIGURATIONS_SUCCESS = 'GET_QUICK_CONFIGURATIONS_SUCCESS';
export const GET_QUICK_CONFIGURATIONS_FAIL = 'GET_QUICK_CONFIGURATIONS_FAIL';
export const POST_QUICK_CONFIGURATIONS_START = 'POST_QUICK_CONFIGURATIONS_START';
export const POST_QUICK_CONFIGURATIONS_SUCCESS = 'POST_QUICK_CONFIGURATIONS_SUCCESS';
export const POST_QUICK_CONFIGURATIONS_FAIL = 'POST_QUICK_CONFIGURATIONS_FAIL';
export const DELETE_QUICK_CONFIGURATIONS_START = 'DELETE_QUICK_CONFIGURATIONS_START';
export const DELETE_QUICK_CONFIGURATIONS_SUCCESS = 'DELETE_QUICK_CONFIGURATIONS_SUCCESS';
export const DELETE_QUICK_CONFIGURATIONS_FAIL = 'DELETE_QUICK_CONFIGURATIONS_FAIL';
export const PATCH_QUICK_CONFIGURATIONS_START = 'PATCH_QUICK_CONFIGURATIONS_START';
export const PATCH_QUICK_CONFIGURATIONS_SUCCESS = 'PATCH_QUICK_CONFIGURATIONS_SUCCESS';
export const PATCH_QUICK_CONFIGURATIONS_FAIL = 'PATCH_QUICK_CONFIGURATIONS_FAIL';

// No Configurations Opportunities
export const GET_ACTIVE_OPPORTUNITIES_START = 'GET_ACTIVE_OPPORTUNITIES_START';
export const GET_ACTIVE_OPPORTUNITIES_SUCCESS = 'GET_ACTIVE_OPPORTUNITIES_SUCCESS';
export const GET_ACTIVE_OPPORTUNITIES_FAIL = 'GET_ACTIVE_OPPORTUNITIES_FAIL';
