import envVars from '../utils/envVars';

/**
 * MSAL configurations
 */
export const msalConfig = {
  auth: {
    clientId: envVars.authClientId,
    authority: envVars.authAuthority,
    knownAuthorities: [envVars.authAuthority],
    redirectUri: envVars.authRedirectURI,
    postLogoutRedirectUri: envVars.authPostLogoutRedirectUri,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

/**
 * Api request scopes
 */
export const apiRequest = {
  scopes: ['openid', `api://${envVars.authClientId}/access_as_user`],
};

/**
 * Login request scopes
 * available scopes are https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const loginRequest = {
  scopes: ['openid', 'profile', 'offline_access'],
};
