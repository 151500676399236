import { Colors } from '../../../theme';

export default () => ({
  headerContainer: {
    backgroundColor: Colors.white,
    height: '3.5rem',
    width: '100%',
    top: 0,
  },
  tabContainer: {
    flex: 1,
    display: 'contents',
  },
  leftSeparator: {
    marginLeft: '2rem',
    marginTop: '0.6rem',
  },
  bodyContainer: {
    marginLeft: '1rem',
    marginRight: '1rem',
    marginTop: '2.5rem',
    padding: '1rem 1.5rem',
    backgroundColor: Colors.white,
    height: '550px',
  },
  gridItem: {
    '&:focus': {
      outline: `2px solid ${Colors.primaryLight}`,
      borderRadius: '2px',
    },
  },
});
