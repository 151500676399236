/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withStyles } from '@mui/styles';
import Input from '../Input';
import TwoColumnRow from '../TwoColumnRow';
import useStyles from './styles';
import { formatThousandsNumber, toFixedNoRounding } from '../../utils/stringFormatter';
import { setValueTextClass } from '../../containers/ComponentManager/utilities';

/**
 * FormikInput
 * @param {object} param0 Formik Input parameters
 * @param {object} param0.intl Internationalizatino object
 * @param {string} param0.formik Formik object
 * @param {string} param0.classes Style classes object
 * @param {string} param0.id Input unique identifier
 * @param {string} param0.title Input title
 * @param {string} param0.rightUnit Text to show up to the right of the field
 * @param {string} param0.type The type of the input
 * @param {bool} param0.formatByThousands Flag to turn on Thousands Formatting
 * @param {bool} param0.integersOnly Flag to disallow decimals
 * @param {bool} param0.disallowStrings Flag to prevent strings from being entered at all
 * @param {number} param0.decimalPlaces limits the number of decimal places
 * @param {string} param0.leftUnit Text to show up to the left of the field
 * @param {string} param0.small changes the size of the input
 * @param {bool} param0.autoFocus enables autofocus
 * @param {bool} param0.indented indents the TwoColumnRow
 * @param {bool} param0.alignRight whether to align text field to the right
 * @param {function} param0.onChange override for Formik onChange
 * @param {function} param0.onFocus optional input for Focus event
 * @param {function} param0.onBlur optional function for blur
 * @param {function} param0.maxLength optional number for the maximum input length
 * @param {Boolean} param0.hideTitle optional to hide title from two columns
 * @param {string} param0.dataTestId optional Data Test unique identifier for the input control
 * @param {string} param0.fullWidth optional FullWidth field
 * @returns Input with validations component
 */
const FormikInput = ({
  intl,
  formik,
  classes,
  id,
  title,
  rightUnit,
  type,
  formatByThousands = false, // add comma formatting into a number.
  integersOnly = false, // removes decimals.
  disallowStrings,
  decimalPlaces, // number of desired decimal places. Not compatible with integersOnly at the same time. Do not set to 0; instead use integersOnly flag.
  leftUnit,
  small,
  autoFocus = false,
  indented,
  alignRight = false,
  onChange,
  onFocus,
  onBlur,
  maxLength,
  hideTitle,
  disabled = false,
  dataTestId,
  dataTestWrapperId,
  fullWidth = false,
  inputProps,
  placeHolder,
  textFieldStyle,
  readonly,
}) => {
  const doesErrorExist = Boolean(formik.touched[id.toString()] && formik.errors[id.toString()]);
  const getErrorMsg = () => `${title && intl.formatMessage(title)} ${formik.errors[id.toString()]}`;

  /**
   * renderErrorMessage
   * based on whether the field has been touched and if an error message exists, render the error message below the field.
   * @returns error message text
   */
  const renderErrorMessage = () => {
    if ((doesErrorExist && getErrorMsg()) || (!doesErrorExist && formik.errors[id.toString()] && !getErrorMsg().includes('required'))) {
      return (
        <Typography variant="caption" color="error">
          {getErrorMsg()}
        </Typography>
      );
    }
    return null;
  };

  const errorMessage = renderErrorMessage();

  /**
   * handleBlur format the input to remove its decimals if this is an integer-only input.
   * Or, set the decimal places if those are required.
   * Finally, perform formik's onBlur event.
   * @param event formik event; contains info for formik to know which field to blur.
   * @returns blur function
   */
  const handleBlur = (event) => {
    // this doesn't fire in the case the user saves without moving from the input
    // perhaps we shouldn't allow the user to input decimals at all when integersOnly is true
    if (formik.values[id.toString()] && integersOnly) {
      formik.setFieldValue(id.toString(), Math.floor(formik.values[id.toString()].toString().replace(/,/g, '')).toString());
    } else if (formik.values[id.toString()] && decimalPlaces) {
      if (parseFloat(formik.values[id.toString()]) === 0) {
        formik.setFieldValue(id.toString(), parseFloat(formik.values[id.toString()].toString().replace(/,/g, '')).toFixed(decimalPlaces));
      } else {
        formik.setFieldValue(
          id.toString(),
          toFixedNoRounding(parseFloat(formik.values[id.toString()].toString().replace(/,/g, '')), decimalPlaces),
        );
      }
    }
    if (onBlur) {
      onBlur(event);
    }
    formik.handleBlur(event);
  };

  const handleOnChange = (event) => {
    if (onChange) {
      onChange(event);
    }
    formik.handleChange(event);
  };

  const handleValue = (fieldValue) => {
    let value;
    if (formatByThousands) {
      value = formatThousandsNumber(fieldValue);
    } else if (disallowStrings) {
      value = /(?!-)[^0-9.,]/g.test(fieldValue) ? '' : fieldValue;
    } else {
      value = fieldValue;
    }
    return value;
  };

  return (
    <div data-testid="formik-div-component">
      <TwoColumnRow
        testId={dataTestWrapperId || 'formik-wrapper-component'}
        title={!hideTitle && <FormattedMessage {...title} />}
        error={errorMessage}
        key={id}
        indented={indented}
      >
        <div className={classes.inputContainer}>
          {leftUnit && (
            <Typography data-testid="formik-typography-component" color="primary" variant="caption" className={classes.currencyText}>
              {leftUnit}
            </Typography>
          )}
          <div className={setValueTextClass(rightUnit, leftUnit, small, classes, readonly)}>
            {readonly && handleValue(formik.values[id.toString()])}
            {!readonly && (
              <Input
                dataTestId={dataTestId || 'formik-input-component'}
                id={id}
                name={id}
                invalid={doesErrorExist}
                value={handleValue(formik.values[id.toString()])}
                type={type || 'text'}
                onChange={(event) => handleOnChange(event)}
                alignRight={alignRight}
                onFocus={onFocus}
                onBlur={(event) => handleBlur(event)}
                autoFocus={autoFocus}
                error={doesErrorExist}
                disabled={disabled}
                inputProps={{ maxLength, ...inputProps }}
                fullWidth={fullWidth}
                placeholder={placeHolder}
                textFieldStyle={textFieldStyle}
              />
            )}
          </div>
          {rightUnit && (
            <Typography color="primary" variant="caption" className={`${classes.unitText} ${!readonly ? classes.notReadOnlyUnitText : ''}`}>
              {rightUnit}
            </Typography>
          )}
        </div>
      </TwoColumnRow>
    </div>
  );
};

FormikInput.propTypes = {
  intl: PropTypes.object,
  formik: PropTypes.object,
  classes: PropTypes.object,
  id: PropTypes.string,
  title: PropTypes.object,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  rightUnit: PropTypes.string,
  value: PropTypes.string || PropTypes.number,
  type: PropTypes.string,
  formatByThousands: PropTypes.bool,
  alignRight: PropTypes.bool,
  fullWidth: PropTypes.bool,
  integersOnly: PropTypes.bool,
  disallowStrings: PropTypes.bool,
  decimalPlaces: PropTypes.number,
  leftUnit: PropTypes.string,
  small: PropTypes.bool,
  autoFocus: PropTypes.bool,
  indented: PropTypes.bool,
  hideTitle: PropTypes.bool,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  dataTestId: PropTypes.string,
  dataTestWrapperId: PropTypes.string,
  inputProps: PropTypes.object,
  placeHolder: PropTypes.string,
  textFieldStyle: PropTypes.object,
  readonly: PropTypes.bool,
};

export default withStyles(useStyles)(injectIntl(FormikInput));
