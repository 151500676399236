/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from './utils/history';
import languageProviderReducer from './containers/LanguageProvider/reducer';
import opportunityReducer from './containers/Opportunities/reducers';
import quickConfigurationsReducer from './containers/QuickConfigurations/reducers';
import componentManagerReducer from './containers/ComponentManager/Home/reducers';
import authReducer from './containers/Root/reducers';
import calculationReducer from './containers/Opportunities/Configuration/Review/reducers';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  return combineReducers({
    language: languageProviderReducer,
    opportunity: opportunityReducer,
    quickConfiguration: quickConfigurationsReducer,
    componentManager: componentManagerReducer,
    auth: authReducer,
    router: connectRouter(history),
    calculation: calculationReducer,
    ...injectedReducers,
  });
}
