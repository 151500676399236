import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    height: 56,
  },
  spacing: {
    flexGrow: 1,
  },
  toolBar: {
    padding: '1rem 2.5rem 1rem 2rem',
  },
  iconButton: {
    padding: 3,
  },
  selectedIconBackground: {
    backgroundColor: '#FFFFFF32',
  },
  countIndicator: {
    minWidth: '1.125rem',
    minHeight: '1.125rem',
    borderRadius: '2px',
    marginLeft: '1rem',
    textAlign: 'center',
    color: '#20c161',
    backgroundColor: '#FFFFFF',
  },
  dismissAllWrapper: {
    display: 'flex',
    height: '2rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noNotificationsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '27.938rem',
    height: '13.125rem',
  },
  noNewNotificationsText: {
    marginTop: '1rem',
    fontSize: '0.75rem',
  },
  dismissAllButton: {
    cursor: 'pointer',
  },
}));
