/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Box, Card, CardContent } from '@mui/material';
import useStyles from './styles';

/**
 * CardComponent
 * @param {object} data Data object to show in card
 * @param {function} onClick Event to execute when clicking
 * @param {function} onDragStart Event to execute when dragging
 */
const CardComponent = ({ title, icon, description, onClick, typeOfComponents, totalComponents }) => {
  const classes = useStyles();
  return (
    <>
      <Card className={classes.wrapper} variant="outlined" onClick={() => onClick(typeOfComponents)} key={typeOfComponents}>
        <CardContent className={classes.contentWrapper}>
          <Box className={classes.iconWrapper} key="icon">
            <img src={icon} alt="" />
          </Box>
          <FormattedMessage {...title}>
            {(text) => (
              <Box className={classes.title} key="title">
                {text}
              </Box>
            )}
          </FormattedMessage>
          <FormattedMessage {...description}>
            {(text) => <Box className={classes.description} key="description">{`${totalComponents} ${text}`}</Box>}
          </FormattedMessage>
        </CardContent>
      </Card>
    </>
  );
};

CardComponent.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.object,
  description: PropTypes.object,
  onClick: PropTypes.func,
  typeOfComponents: PropTypes.string,
  totalComponents: PropTypes.number,
};

export default CardComponent;
