import { withStyles } from '@mui/styles';
import TableRow from '@mui/material/TableRow';
import { Colors } from '../../theme';

/**
 * Styled Table Row
 */
const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: Colors.athensGray,
    },
    minHeight: '1.5rem',
  },
}))(TableRow);

export default StyledTableRow;
