export const GET_FACILITY_DATA_START = 'GET_FACILITY_DATA_START';
export const GET_FACILITY_DATA_SUCCESS = 'GET_FACILITY_DATA_SUCCESS';
export const GET_FACILITY_DATA_FAIL = 'GET_FACILITY_DATA_FAIL';

export const GET_TRENDS_DATA_START = 'GET_TRENDS_DATA_START';
export const GET_TRENDS_DATA_SUCCESS = 'GET_TRENDS_DATA_SUCCESS';
export const GET_TRENDS_DATA_FAIL = 'GET_TRENDS_DATA_FAIL';

export const GET_SOLAR_DATA_START = 'GET_SOLAR_DATA_START';
export const GET_SOLAR_DATA_SUCCESS = 'GET_SOLAR_DATA_SUCCESS';
export const GET_SOLAR_DATA_FAIL = 'GET_SOLAR_DATA_FAIL';

export const GET_ENGINE_DATA_START = 'GET_ENGINE_DATA_START';
export const GET_ENGINE_DATA_SUCCESS = 'GET_ENGINE_DATA_SUCCESS';
export const GET_ENGINE_DATA_FAIL = 'GET_ENGINE_DATA_FAIL';

export const GET_ESS_DATA_START = 'GET_ESS_DATA_START';
export const GET_ESS_DATA_SUCCESS = 'GET_ESS_DATA_SUCCESS';
export const GET_ESS_DATA_FAIL = 'GET_ESS_DATA_FAIL';

export const GET_EV_DATA_START = 'GET_EV_DATA_START';
export const GET_EV_DATA_SUCCESS = 'GET_EV_DATA_SUCCESS';
export const GET_EV_DATA_FAIL = 'GET_EV_DATA_FAIL';

export const GET_FLEET_DATA_START = 'GET_FLEET_DATA_START';
export const GET_FLEET_DATA_SUCCESS = 'GET_FLEET_DATA_SUCCESS';
export const GET_FLEET_DATA_FAIL = 'GET_FLEET_DATA_FAIL';

export const GET_WIND_DATA_START = 'GET_WIND_DATA_START';
export const GET_WIND_DATA_SUCCESS = 'GET_WIND_DATA_SUCCESS';
export const GET_WIND_DATA_FAIL = 'GET_WIND_DATA_FAIL';

export const TRIGGER_CALCULATION_DATA_START = 'TRIGGER_CALCULATION_DATA_START';
export const TRIGGER_CALCULATION_DATA_SUCCESS = 'TRIGGER_CALCULATION_DATA_SUCCESS';
export const TRIGGER_CALCULATION_DATA_FAIL = 'TRIGGER_CALCULATION_DATA_FAIL';

export const DOWNLOAD_CALCULATION_DATA_START = 'DOWNLOAD_CALCULATION_DATA_START';
export const DOWNLOAD_CALCULATION_DATA_SUCCESS = 'DOWNLOAD_CALCULATION_DATA_SUCCESS';
export const DOWNLOAD_CALCULATION_DATA_FAIL = 'DOWNLOAD_CALCULATION_DATA_FAIL';

export const GET_FACILITY_SCALE_START = 'GET_FACILITY_SCALE_START';
export const GET_FACILITY_SCALE_SUCCESS = 'GET_FACILITY_SCALE_SUCCESS';
export const GET_FACILITY_SCALE_FAIL = 'GET_FACILITY_SCALE_FAIL';

export const GET_OTHER_DATA_START = 'GET_OTHER_DATA_START';
export const GET_OTHER_DATA_SUCCESS = 'GET_OTHER_DATA_SUCCESS';
export const GET_OTHER_DATA_FAIL = 'GET_OTHER_DATA_FAIL';

export const DOWNLOAD_BOM_DATA_START = 'DOWNLOAD_BOM_DATA_START';
export const DOWNLOAD_BOM_DATA_SUCCESS = 'DOWNLOAD_BOM_DATA_SUCCESS';
export const DOWNLOAD_BOM_DATA_FAIL = 'DOWNLOAD_BOM_DATA_FAIL';

export const REQUEST_PROCESS_LOGS_START = 'REQUEST_PROCESS_LOGS_START';
export const REQUEST_PROCESS_LOGS_SUCCESS = 'REQUEST_PROCESS_LOGS_SUCCESS';
export const REQUEST_PROCESS_LOGS_FAIL = 'REQUEST_PROCESS_LOGS_FAIL';
