/*
 * Header for Component Manager
 *
 */
import { defineMessages } from 'react-intl';

export const scope = 'app.containers.ComponentManager.Dashboard';

export default defineMessages({
  activeTab: {
    id: `${scope}.activeTab`,
    defaultMessage: 'Active',
  },
  deactiveTab: {
    id: `${scope}.deactiveTab`,
    defaultMessage: 'Inactive',
  },
  search: {
    id: `${scope}.search`,
    defaultMessage: 'Search',
  },
  searchQuick: {
    id: `${scope}.searchQuick`,
    defaultMessage: 'Search by Configuration Name',
  },
  createNew: {
    id: `${scope}.createNew`,
    defaultMessage: 'CREATE NEW',
  },
  assignedUserPlaceholder: {
    id: `${scope}.assignedUserPlaceholder`,
    defaultMessage: 'Assigned User',
  },
});
