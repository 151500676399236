import { makeStyles } from '@mui/styles';

export default makeStyles({
  breadcrumbsWrapper: {
    marginLeft: 'auto',
    marginRight: '1.5rem',
  },
  clickableLink: {
    cursor: 'pointer',
  },
});
