/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import images from '../../assets/images';
import DrawerComponent from '../../components/Drawer';
import messages from './messages';

class NavigationDrawer extends Component {
  formatUserName = (userName) => {
    const splitName = userName.split(',');
    return splitName[1] ? `${splitName[1].trim()} ${splitName[0].trim()}` : userName;
  };

  render() {
    const { version, userGroups, userGroupList, user } = this.props;
    const currentPath = this.props.location.pathname;
    const options = [
      {
        id: 1,
        selected: currentPath === '/' || currentPath.startsWith('/opportunity'),
        icon: images.iconDashboard,
        url: '/',
        toolTipMessage: messages.OpportunityDashboardToolTip,
      },
    ];

    if (userGroups === userGroupList.Engineers || userGroups === userGroupList.Admins) {
      options.push({
        id: 2,
        selected: currentPath.startsWith('/quickconfigurations'),
        icon: images.iconService,
        url: '/quickconfigurations',
        toolTipMessage: messages.QuickConfigurationToolTip,
      });
    }

    options.push({
      id: 3,
      selected: currentPath.startsWith('/componentmanager'),
      icon: images.componentManager,
      url: '/componentmanager',
      toolTipMessage: messages.ComponentManagerToolTip,
    });

    return <DrawerComponent options={options} version={version} currentUser={this.formatUserName(user.name)} />;
  }
}

NavigationDrawer.propTypes = {
  location: PropTypes.object,
  version: PropTypes.string.isRequired,
  userGroups: PropTypes.string,
  userGroupList: PropTypes.object,
  user: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { userGroups, userGroupList, user } = state.auth;
  const { location } = state.router;
  return { location, userGroups, userGroupList, user };
};

export default connect(mapStateToProps, {})(NavigationDrawer);
