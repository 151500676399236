import { makeStyles } from '@mui/styles';
import { Colors } from '../../../theme';

export default makeStyles({
  avatar: {
    height: '1.5rem',
    width: '1.5rem',
    borderRadius: '50%',
  },
  selectWrapper: {
    marginRight: 0,
    marginLeft: 'auto',
  },
  selectMock: {
    borderRadius: '2px',
    border: (props) => `solid 1px ${props.invalid ? Colors.alizarinCrimson : Colors.mercury}`,
    backgroundColor: 'transparent',
    fontSize: '0.875rem',
    letterSpacing: '-0.08px',
    textTransform: 'inherit',
    '& .MuiButton-label': {
      fontSize: '0.875rem',
      letterSpacing: '-0.08px',
      padding: '0',
    },
  },
  errorInput: {
    color: Colors.alizarinCrimson,
    fontSize: '0.875rem',
    lineHeight: 1.14,
    visibility: (props) => `${props.invalid ? 'visible' : 'hidden'}`,
    backgroundColor: Colors.white,
  },
  placeholder: {
    fontSize: '0.875rem',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.1px',
    color: Colors.dustyGray,
    transform: 'translate(1rem, 0.65rem) scale(1)',
  },
  formControl: {
    backgroundColor: Colors.athensGray,
    '& .MuiOutlinedInput-input': {
      display: 'flex',
    },
    '& .MuiInputLabel-shrink': {
      visibility: 'hidden',
    },
  },
  childrenText: {
    minHeight: '1.5rem',
    fontSize: '0.875rem',
    letterSpacing: '-0.08px',
    margin: '0 0.25rem',
    padding: '0',
  },
});
