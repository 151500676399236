/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unstable-nested-components */
/**
 *
 * SelectCustomOptions.js
 * Custom select
 */

import { Checkbox, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import images from '../../../assets/images';
import useStyles from './styles';

/**
 * SelectCustomOptions
 * @param {string} placeholder Text to show when nothing is selected
 * @param {Object[text, value]} selectData Data to show
 * @param {boolean} invalid Flag to show invalid style
 * @param {Object} errorText Text to show when error is shown
 * @param {boolean} disabled Flag to disable control
 * @param {boolean} error Flag to show error message
 * @param {Object[text, value]]} selectedObject Object selected by default
 * @param {function} onChange Function to execute when changing value
 * @param {function} onClose Function to execute when closing the select, useful to handle when the user clicks outside
 * @param {function} renderSelectedValue Function to execute when showing the select result in multiple option
 */
function SelectCustomOptions({
  placeholder = '',
  selectData = [],
  invalid = false,
  disabled = false,
  errorText,
  selectedObject = [],
  error = false,
  onChange,
  onClose,
  renderSelectedValue,
  onBlur,
}) {
  const classes = useStyles({ invalid });
  const [selectedOption, setSelectedOption] = React.useState(selectedObject);

  React.useEffect(() => {
    onChange(selectedOption);
  }, [selectedOption]);

  /**
   * handleChangeMultiple
   * @param {object} event Event object of the click event
   */
  const handleChangeMultiple = (event) => {
    setSelectedOption(event.target.value);
  };

  /**
   * handleRenderValue
   * @param {object} render Data selected to render
   */
  const handleRenderValue = (render) => {
    if (renderSelectedValue) {
      return renderSelectedValue(render);
    }
    const returnText = render.map((item) => item.text);
    return returnText.join(', ');
  };

  const children = selectData.map((element) => {
    const checked = selectedOption.findIndex((option) => option.text === element.text) > -1;
    return (
      <MenuItem value={element} key={element.value} data-testid={`multiple-options-select-${element.value}`}>
        <Checkbox
          checked={checked}
          color="default"
          icon={<img src={images.btnUnchecked} alt="unchecked" />}
          checkedIcon={<img src={images.btnChecked} alt="checked" />}
        />
        <Typography className={classes.childrenText}>{element.text}</Typography>
      </MenuItem>
    );
  });

  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="select-native-simple" className={classes.placeholder}>
        {placeholder}
      </InputLabel>
      <Select
        fullWidth
        multiple
        aria-controls="search-menu"
        aria-haspopup="true"
        disabled={disabled}
        value={selectedOption}
        placeholder={placeholder}
        className={classes.selectMock}
        variant="outlined"
        id="select-native-simple"
        onClose={onClose}
        error={error}
        onChange={handleChangeMultiple}
        renderValue={handleRenderValue}
        onBlur={onBlur}
        IconComponent={(props) => <img alt="select-arrow" src={images.chevronIcon} className={classes.selectWrapper} {...props} />}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        {children}
      </Select>
      <Typography className={classes.errorInput}>{errorText}</Typography>
    </FormControl>
  );
}

SelectCustomOptions.propTypes = {
  placeholder: PropTypes.string,
  selectData: PropTypes.array,
  onChange: PropTypes.any,
  invalid: PropTypes.bool,
  disabled: PropTypes.bool,
  errorText: PropTypes.object,
  selectedObject: PropTypes.array,
  onClose: PropTypes.func,
  error: PropTypes.bool,
  renderSelectedValue: PropTypes.func,
  onBlur: PropTypes.func,
};

export default SelectCustomOptions;
