import { makeStyles } from '@mui/styles';

export default makeStyles({
  iconChevron: {
    height: '1rem',
    width: '1rem',
    marginRight: 0,
    marginLeft: 'auto',
  },
  iconCheck: {
    height: '1.5rem',
    width: '1.5rem',
    marginRight: 0,
    marginLeft: 'auto',
  },
  selectMock: {
    marginLeft: '0.5rem',
    fontSize: '0.75rem',
    fontWeight: '600',
    padding: '0.125rem 0.25rem',
    borderRadius: '2px',
    border: (props) => `solid 2px ${props.selectedColor}`,
    letterSpacing: '-0.08px',
    color: (props) => props.selectedColor,
    '& .MuiButton-label': {
      fontSize: '0.75rem',
      fontWeight: '600',
      margin: '0 0.25rem',
      padding: '0',
    },
  },
  childrenText: {
    fontSize: '0.75rem',
    fontWeight: '600',
    margin: '0 0.25rem',
  },
});
