/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/function-component-definition */
import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import styles from './styles';

const TwoColumnRow = ({ title, children, titleStyle = {}, error, indented = false, testId = null }) => {
  let stylesLabel = titleStyle;
  if (indented) {
    stylesLabel = {
      ...stylesLabel,
      ...styles.indentLabel,
    };
  }

  return (
    <div style={styles.container} data-testid={testId}>
      <div style={styles.titleWrapper}>
        <div style={styles.title}>
          <Typography variant="caption" style={stylesLabel}>
            {title}
          </Typography>
        </div>
        <div>{children}</div>
      </div>

      <div style={styles.errorWrapper}>{error && error}</div>
    </div>
  );
};

TwoColumnRow.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  children: PropTypes.node,
  titleStyle: PropTypes.object,
  error: PropTypes.object,
  indented: PropTypes.bool,
  testId: PropTypes.string,
};

export default TwoColumnRow;
