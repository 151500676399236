import { Colors } from '../../../theme';

export default (props) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  tabContainer: {
    flex: 1,
    display: 'contents',
  },
  TableWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: Colors.silver,
    flex: 1,
  },
  table: {
    minWidth: 700,
    borderRadius: 0,
    '& .MuiPaper-rounded': {
      borderRadius: 0,
    },
    overflowX: 'hidden',
    height: '100%',
  },
  paginationWrapper: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cancelModal: {
    color: Colors.mountainMeadow,
    cursor: 'pointer',
    textDecoration: 'underline',
    paddingRight: '1.25rem',
  },
  backdrop: {
    zIndex: props.zIndex.drawer + 1,
    color: '#fff',
  },
  bodyWrapper: {
    display: 'flex',
    flex: 1,
    padding: '1rem',
    flexDirection: 'column',
  },
  emptyContainer: {
    textAlign: 'center',
    paddingTop: '10rem',
  },
});
