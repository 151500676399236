/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import StyledTableCell from '../../../../components/StyledTableCell';
import messages from '../messages';
import useStyles from './styles';

/**
 * Component Manager Table Header
 */
const ComponentManagerTableHeader = ({ statusTitle = messages.inactiveTitle, isAdmin }) => {
  const classes = useStyles();
  return (
    <TableRow>
      <StyledTableCell className={classes.manufacture}>
        <Typography variant="caption" className={classes.headerTitle}>
          <FormattedMessage {...messages.manufactureColumnTitle} />
        </Typography>
      </StyledTableCell>
      <StyledTableCell className={classes.partNumber}>
        <Typography variant="caption" className={classes.headerTitle}>
          <FormattedMessage {...messages.partNumberColumnTitle} />
        </Typography>
      </StyledTableCell>
      <StyledTableCell className={classes.manufacture}>
        <Typography variant="caption" className={classes.headerTitle}>
          <FormattedMessage {...messages.descriptionColumnTitle} />
        </Typography>
      </StyledTableCell>
      <StyledTableCell className={classes.created}>
        <Typography variant="caption" className={classes.headerTitle}>
          <FormattedMessage {...messages.dateColumnTitle} />
        </Typography>
      </StyledTableCell>
      <StyledTableCell className={classes.buttonsCell} />
      {isAdmin && (
        <StyledTableCell className={classes.status}>
          <Typography variant="caption" className={classes.headerTitle}>
            <FormattedMessage {...statusTitle} />
          </Typography>
        </StyledTableCell>
      )}
    </TableRow>
  );
};

ComponentManagerTableHeader.propTypes = {
  statusTitle: PropTypes.object,
  isAdmin: PropTypes.bool,
};

export default ComponentManagerTableHeader;
