import { makeStyles } from '@mui/styles';

export default makeStyles({
  wrapper: {
    height: '6.625rem',
  },
  leftSeparator: {
    marginLeft: '2rem',
    marginTop: '0.6rem',
  },
  searchWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: '1.75rem',
    marginLeft: 'auto',
  },
  searchDropdown: {
    width: '17rem',
    height: '2rem',
    marginRight: '1rem',
  },
  searchInput: {
    width: '11.5rem',
    height: '2rem',
  },
  searchIcon: {
    width: '1.5rem',
    height: '1.5rem',
  },
  upperWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    alignItems: 'center',
    marginTop: '1rem',
  },
  lowerWrapper: {
    height: '2.5rem',
    display: 'flex',
    flexDirection: 'row',
    objectFit: 'contain',
    justifyContent: 'space-between',
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  tab: {
    textTransform: 'none',
  },
  buttonWrapper: {
    height: '2.5rem',
    width: '7.875rem',
    marginLeft: '1rem',
    marginRight: '1rem',
  },
});
