/*
 * Quick Configurations Dashboard Messages
 *
 * This contains all the text for the Quick Configurations Dashboard Page container.
 */
import { defineMessages } from 'react-intl';

export const scope = 'app.containers.ComponentManager.Dashboard';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Component Manager',
  },
  essBattery: {
    id: `${scope}.essBattery`,
    defaultMessage: 'ESS - Battery',
  },
  essInverter: {
    id: `${scope}.essInverter`,
    defaultMessage: 'ESS - Inverter',
  },
  essConverter: {
    id: `${scope}.essConverter`,
    defaultMessage: 'ESS - Converter',
  },
  solarInverter: {
    id: `${scope}.solarInverter`,
    defaultMessage: 'Solar - Inverter',
  },
  solarConverter: {
    id: `${scope}.solarConverter`,
    defaultMessage: 'Solar - Converter',
  },
  engine: {
    id: `${scope}.engine`,
    defaultMessage: 'Engine',
  },
  ev: {
    id: `${scope}.ev`,
    defaultMessage: 'EV',
  },
  fleet: {
    id: `${scope}.fleet`,
    defaultMessage: 'Fleet',
  },
  inverter: {
    id: `${scope}.invertert`,
    defaultMessage: 'Inverter',
  },
  enclosure: {
    id: `${scope}.enclosure`,
    defaultMessage: 'Enclosure',
  },
  enclosureComponent: {
    id: `${scope}.enclosureComponent`,
    defaultMessage: 'Enclosure Component',
  },
  dcacInverterComponent: {
    id: `${scope}.dcacInverterComponent`,
    defaultMessage: 'DC/AC Bus - Inverter',
  },
  components: {
    id: `${scope}.components`,
    defaultMessage: 'Components',
  },
  description: {
    width: '14.25rem',
    height: '1.063rem',
    fontFamily: 'Inter',
    fontSize: '0.875rem',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.08px',
  },
});
