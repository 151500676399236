/* eslint-disable import/prefer-default-export */
import * as Yup from 'yup';
import { formatThousandsNumber } from './stringFormatter';

/**
 * requiredStringValidator
 * Yup validator to force a required string with at least one character
 */
const requiredStringValidator = Yup.string().required('is required').min(1);

/**
 * commaNumberValidator
 * Yup validator that evaluates only numbers (supports commas)
 */
const commaNumberValidator = Yup.number()
  .typeError('is required')
  .required('is required')
  .transform((_value, originalValue) => Number(originalValue && originalValue.replace(/,/g, '')));

/**
 * requiredNumberCommaValidationMinMax
 * Yup validator which requires a value, must be a number between the lowerNumber and higherNumber.
 * Will allow 0 as an input if it is the lowerNumber. Does not allow empty string.
 * @param {*} lowerNumber number
 * @param {*} higherNumber number
 * @returns yup validator for a number in a range between lowerNumber and higherNumber, requires a number.
 */
const requiredNumberCommaValidationMinMax = (lowerNumber, higherNumber) =>
  Yup.string()
    .nullable(false)
    .typeError(`must be between ${formatThousandsNumber(lowerNumber)} and ${formatThousandsNumber(higherNumber)}`)
    .required('is required')
    .transform((_value, originalValue) => !!Number(originalValue) && Number(originalValue && originalValue.toString().replace(/,/g, '')))
    .test(
      'in-range',
      `must be between ${formatThousandsNumber(lowerNumber)} and ${formatThousandsNumber(higherNumber)}`,
      (value) =>
        (value || value === 0 || value === '0') &&
        (!!Number(value && value.toString().replace(/,/g, '')) || parseFloat(value) === 0) &&
        parseFloat(value && value.toString().replace(/,/g, '')) >= parseFloat(lowerNumber.replace(/,/g, '')) &&
        parseFloat(value && value.toString().replace(/,/g, '')) <= parseFloat(higherNumber.replace(/,/g, '')),
    )
    .transform((_value, originalValue) => originalValue && originalValue.toString());

/**
 * optionalNumberCommaValidationMinMax
 * Yup validator which requires an integer value or null input, must be a number between the lowerNumber and higherNumber.
 * Will allow 0 as an input if it is the lowerNumber. Allows empty string.
 * @param {*} lowerNumber number
 * @param {*} higherNumber number
 * @returns yup validator for a number in a range between lowerNumber and higherNumber, accepts nullable values.
 */
const optionalNumberCommaValidationMinMax = (lowerNumber, higherNumber) =>
  Yup.number()
    .nullable(true)
    .typeError(`must be between ${formatThousandsNumber(lowerNumber)} and ${formatThousandsNumber(higherNumber)}`)
    .transform((_value, originalValue) => Number(originalValue && originalValue.replace(/,/g, '')))
    .min(lowerNumber, `must be between ${formatThousandsNumber(lowerNumber)} and ${formatThousandsNumber(higherNumber)}`)
    .max(higherNumber, `must be between ${formatThousandsNumber(lowerNumber)} and ${formatThousandsNumber(higherNumber)}`);

export const commonValidators = {
  REQUIRED_STRING: requiredStringValidator,
  COMMA_NUMBER_VALIDATION: commaNumberValidator,
  REQUIRED_NUMBER_COMMA_VALIDATION_MIN_MAX: requiredNumberCommaValidationMinMax,
  OPTIONAL_NUMBER_COMMA_VALIDATION_MIN_MAX: optionalNumberCommaValidationMinMax,
};
