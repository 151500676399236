const envVars = {
  authClientId: process.env.REACT_APP_WEB_APP_AD_CLIENT_ID,
  authAuthority: process.env.REACT_APP_AD_AUTHORITY_URL,
  authRedirectURI: process.env.REACT_APP_REDIRECT_URI,
  domainName: process.env.REACT_APP_DOMAIN_NAME,
  authPostLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URL,
  doLog: process.env.REACT_APP_DO_LOG,
  googleMapsKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
};

export default envVars;
