/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-unused-state */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Typography, Select, MenuItem } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import Input from '../../../../components/Input';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';
import TwoColumnRow from '../../../../components/TwoColumnRow';
import AlertModal from '../../../../components/AlertModal';
import useStyles from './styles';
import { formatThousandsNumber, validateDecimalValue, numberFormat } from '../../../../utils/stringFormatter';
import images from '../../../../assets/images';
import { setValueTextClass } from '../../utilities';

const REQUIRED_ERROR = 'required';
const RANGE_ERROR = 'range';
const KW = 'kW';
const DOLLAR_SYMBOL = '$';
const MMBTU_PER_HOUR = 'MMBTU/hr';

const MANUFACTURER = 'MANUFACTURER';
const PART_NUMBER = 'PART_NUMBER';
const POWER_RATING = 'POWER_RATING';
const HEAT_PRODUCTION = 'HEAT_PRODUCTION';
const STATIC_LOSS = 'STATIC_LOSS';
const UNIT_COST = 'UNIT_COST';
const INSTALLATION_COST = 'INSTALLATION_COST';
const ENGINE_TYPE = 'ENGINE_TYPE';

const ENGINE_TYPE_RECIPROCATING_TEXT = 'R. Engine';
const ENGINE_TYPE_RECIPROCATING_VALUE = 'R.Engine';
const ENGINE_TYPE_MICROTURBINE_VALUE = 'Microturbine';
const ENGINE_TYPE_OTHER_VALUE = 'Other';

const ENGINE_TYPE_LIST = [
  { value: ENGINE_TYPE_RECIPROCATING_VALUE, name: ENGINE_TYPE_RECIPROCATING_TEXT },
  { value: ENGINE_TYPE_MICROTURBINE_VALUE, name: ENGINE_TYPE_MICROTURBINE_VALUE },
  { value: ENGINE_TYPE_OTHER_VALUE, name: ENGINE_TYPE_OTHER_VALUE },
];

const INITIAL_VALUES = {
  manufacturer: '',
  isManufacturerValid: true,
  manufacturerErrorMessage: '',
  hasManufacturerBeenTouched: false,
  partNumber: '',
  isPartNumberValid: true,
  partNumberErrorMessage: '',
  hasPartNumberBeenTouched: false,
  powerRating: '',
  isPowerRatingValid: true,
  powerRatingErrorMessage: '',
  hasPowerRatingBeenTouched: false,
  engineType: '',
  isEngineTypeValid: true,
  engineTypeErrorMessage: '',
  hasEngineTypeBeenTouched: false,
  staticLoss: '',
  isStaticLossValid: true,
  staticLossErrorMessage: '',
  hasStaticLossBeenTouched: false,
  heatProduction: '',
  isHeatProductionValid: true,
  heatProductionErrorMessage: '',
  hasHeatProductionBeenTouched: false,
  unitCost: '',
  isUnitCostValid: true,
  unitCostErrorMessage: '',
  hasUnitCostBeenTouched: false,
  installationCost: '',
  isInstallationCostValid: true,
  installationCostErrorMessage: '',
  hasInstallationCostBeenTouched: false,
};

class EngineModal extends Component {
  constructor() {
    super();
    this.state = {
      id: '',
      isCancelModalVisible: false,
      ...INITIAL_VALUES,
    };
  }

  componentDidMount() {
    const { defaultData } = this.props;
    this.setState({
      id: `${defaultData?.componentId ?? ''}`,
      manufacturer: `${defaultData?.manufacturer ?? ''}`,
      partNumber: `${defaultData?.partNumber ?? ''}`,
      powerRating: `${defaultData?.powerRating ?? ''}`,
      engineType: `${defaultData?.engineType ?? ''}`,
      staticLoss: `${defaultData?.staticLoss ?? '0'}`,
      heatProduction: `${defaultData?.heatProduction ?? ''}`,
      unitCost: `${defaultData?.unitCost ?? ''}`,
      installationCost: `${defaultData?.installationCost ?? ''}`,
    });
  }

  componentDidUpdate(prevProps) {
    this.validateChanges(prevProps);
  }

  /**
   * validateChanges - validate if the component receive new props and parse them to this state
   * @param {*} prevProps previous props from componentDidUpdate
   */
  validateChanges = (prevProps) => {
    const currentDefaultData = this.props.defaultData;
    const prevDefaultData = prevProps.defaultData;
    if (currentDefaultData && JSON.stringify({ ...currentDefaultData }) !== JSON.stringify({ ...prevDefaultData })) {
      this.setState({
        id: `${currentDefaultData.id || ''}`,
        manufacturer: `${currentDefaultData.manufacturer || ''}`,
        partNumber: `${currentDefaultData.partNumber || ''}`,
        powerRating: `${currentDefaultData.powerRating || ''}`,
        engineType: `${currentDefaultData.engineType || ''}`,
        staticLoss: `${currentDefaultData.staticLoss || ''}`,
        heatProduction: `${currentDefaultData.heatProduction || ''}`,
        unitCost: `${currentDefaultData.unitCost || ''}`,
        installationCost: `${currentDefaultData.installationCost || ''}`,
        isActive: currentDefaultData.isActive,
      });
    }
  };

  /**
   *  resetForm
   *  resets form
   */
  resetForm = () => {
    this.setState({ ...INITIAL_VALUES });
  };

  /*
   * validateField
   * Validates a field
   * @param {string} key Field type
   * @param {string} value Value to validate
   */
  validateField = (key, value) => {
    let isValidValue;
    let numberValue;
    let errorMessage = '';
    let validation;
    const cleanValue = value.toString().trim();
    switch (key) {
      case MANUFACTURER:
        isValidValue = cleanValue !== '' && value.length > 0;
        if (!isValidValue) {
          errorMessage = REQUIRED_ERROR;
        }
        this.setState({ isManufacturerValid: isValidValue, manufacturerErrorMessage: errorMessage });
        break;

      case PART_NUMBER:
        isValidValue = cleanValue !== '' && value.length > 0;
        if (!isValidValue) {
          errorMessage = REQUIRED_ERROR;
        }
        this.setState({ isPartNumberValid: isValidValue, partNumberErrorMessage: errorMessage });
        break;

      case POWER_RATING:
        numberValue = parseFloat(cleanValue.replace(/,/g, ''));
        isValidValue = cleanValue.length > 0 && numberValue >= 0.1 && numberValue <= 9999.0;
        this.setState({
          isPowerRatingValid: isValidValue,
          powerRatingErrorMessage: cleanValue.length === 0 ? REQUIRED_ERROR : RANGE_ERROR,
        });
        break;

      case HEAT_PRODUCTION:
        numberValue = parseFloat(cleanValue.replace(/,/g, ''));
        isValidValue = (cleanValue.length > 0 && numberValue >= 0 && numberValue <= 9999.99) || !cleanValue;
        this.setState({
          isHeatProductionValid: isValidValue,
          heatProductionErrorMessage: !isValidValue && RANGE_ERROR,
        });
        break;

      case STATIC_LOSS:
        numberValue = parseFloat(cleanValue.replace(/,/g, ''));
        isValidValue = cleanValue.length > 0 && numberValue >= 0 && numberValue <= 9999.0;
        this.setState({
          isStaticLossValid: isValidValue,
          staticLossErrorMessage: cleanValue.length === 0 ? REQUIRED_ERROR : RANGE_ERROR,
        });
        break;

      case UNIT_COST:
        validation = this.validateNumericValue(cleanValue, 999999, true);
        this.setState({ isUnitCostValid: validation.isValid, unitCostErrorMessage: validation.errorType });
        break;

      case INSTALLATION_COST:
        validation = this.validateNumericValue(cleanValue, 999999, true);
        this.setState({ isInstallationCostValid: validation.isValid, installationCostErrorMessage: validation.errorType });
        break;

      case ENGINE_TYPE:
        isValidValue = cleanValue.length > 0;
        errorMessage = !cleanValue || cleanValue.length === 0 ? REQUIRED_ERROR : errorMessage;
        this.setState({
          isEngineTypeValid: isValidValue,
        });
        break;

      default:
        break;
    }
  };

  /*
   * validateNumericValue
   * Validates a numeric value
   * @param {string} value Value to validate
   * @param {Number} maxValue Maximum available value
   * @param {boolean} hasBeenTouched Flag to determine if the input have ever been focused
   * return object
   */
  validateNumericValue = (value, maxValue, hasBeenTouched) => {
    const stringValue = `${value.replace(/[^\d]/g, '')}`;
    let errorType = '';
    let isValid = true;
    if (hasBeenTouched) {
      const isInputEmpty = stringValue.length === 0;
      errorType = isInputEmpty ? REQUIRED_ERROR : '';
      isValid = !isInputEmpty;
      if (!isInputEmpty) {
        const numberValue = parseInt(stringValue.replace(/,/g, ''), 10);
        if (numberValue > maxValue) {
          isValid = false;
          errorType = RANGE_ERROR;
        }
      }
    }
    return { errorType, isValid };
  };

  /*
   * hasChanges
   * Flag to determine if there are any changes in the form
   * return boolean
   */
  hasChanges = () => {
    const { defaultData } = this.props;
    const { manufacturer, partNumber, powerRating, engineType, staticLoss, unitCost, installationCost, id } = this.state;
    const currentData = {
      manufacturer,
      partNumber,
      powerRating,
      engineType,
      staticLoss,
      unitCost,
      installationCost,
    };
    const propsData = {
      manufacturer: `${defaultData?.manufacturer}`,
      partNumber: `${defaultData?.partNumber}`,
      powerRating: `${defaultData?.powerRating}`,
      engineType: `${defaultData?.engineType}`,
      staticLoss: `${defaultData?.staticLoss}`,
      unitCost: `${defaultData?.unitCost}`,
      installationCost: `${defaultData?.installationCost}`,
    };
    let hasChanges = false;
    if (id) {
      hasChanges = JSON.stringify({ ...currentData }) !== JSON.stringify({ ...propsData });
    } else {
      hasChanges =
        manufacturer.length > 0 ||
        partNumber.length > 0 ||
        powerRating.toString().length > 0 ||
        engineType.length > 0 ||
        staticLoss.toString().length > 0 ||
        unitCost.length > 0 ||
        installationCost.length > 0;
    }
    return hasChanges;
  };

  /*
   * enableSaveButton
   * Enables or disables the save button if the form has changes or the values are valid
   * return boolean
   */
  enableSaveButton = () => {
    const {
      isManufacturerValid,
      isPartNumberValid,
      isPowerRatingValid,
      isEngineTypeValid,
      isStaticLossValid,
      isUnitCostValid,
      isInstallationCostValid,
      isHeatProductionValid,
      manufacturer = '',
      partNumber = '',
      powerRating,
      staticLoss,
      unitCost,
      installationCost,
    } = this.state;

    const isSaveButtonEnabled =
      this.hasChanges() &&
      isManufacturerValid &&
      isPartNumberValid &&
      isPowerRatingValid &&
      isEngineTypeValid &&
      isStaticLossValid &&
      isUnitCostValid &&
      isInstallationCostValid &&
      isHeatProductionValid &&
      manufacturer.length > 0 &&
      partNumber.length > 0 &&
      powerRating.toString().length > 0 &&
      staticLoss.toString().length > 0 &&
      unitCost.length > 0 &&
      installationCost.length > 0;

    return isSaveButtonEnabled;
  };

  /**
   * handleSaveClick
   * handle the click event when the user clicks on save button inside createNewModal component
   */
  handleSaveClick = () => {
    const { id, manufacturer, partNumber, powerRating, engineType, heatProduction, staticLoss, unitCost, installationCost, isActive } =
      this.state;

    const newComponentData = {
      componentId: id,
      manufacturer,
      partNumber,
      engineType,
      heatProduction: Number(heatProduction.replace(/,/g, '')),
      powerRating: Number(powerRating.toString().replace(/,/g, '')),
      staticLoss: Number(staticLoss.toString().replace(/,/g, '')),
      unitCost: Number(unitCost.replace(/,/g, '')),
      installationCost: Number(installationCost.replace(/,/g, '')),
      isActive: isActive || true,
    };

    this.resetForm();
    this.props.onAccept(newComponentData);
  };

  /*
   * handleInputChange
   * Handles the inputs change event
   * @param {string} eventt Event object
   */
  handleInputChange = (event) => {
    const { value, name } = event.target;
    const { heatProduction, powerRating, staticLoss } = this.state;
    let formattedNumber;
    switch (name) {
      case MANUFACTURER:
        this.validateField(MANUFACTURER, value);
        this.setState({ manufacturer: value, hasManufacturerBeenTouched: true });
        break;

      case PART_NUMBER:
        this.validateField(PART_NUMBER, value);
        this.setState({ partNumber: value, hasPartNumberBeenTouched: true });
        break;

      case POWER_RATING:
        formattedNumber = validateDecimalValue(value.replace(/,/g, ''), 1) ? value : powerRating;
        this.validateField(POWER_RATING, formattedNumber);
        this.setState({ powerRating: formattedNumber, hasPowerRatingBeenTouched: true });
        break;

      case HEAT_PRODUCTION:
        formattedNumber = validateDecimalValue(value) ? value : heatProduction;
        this.validateField(HEAT_PRODUCTION, formattedNumber);
        this.setState({ heatProduction: formattedNumber, hasHeatProductionBeenTouched: true });
        break;

      case STATIC_LOSS:
        formattedNumber = validateDecimalValue(value.replace(/,/g, ''), 3) ? value : staticLoss;
        this.validateField(STATIC_LOSS, formattedNumber);
        this.setState({ staticLoss: formattedNumber, hasStaticLossBeenTouched: true });
        break;

      case UNIT_COST:
        formattedNumber = `${value.replace(/[^\d]/g, '')}`;
        this.validateField(UNIT_COST, formattedNumber);
        this.setState({ unitCost: formattedNumber, hasUnitCostBeenTouched: true });
        break;

      case INSTALLATION_COST:
        formattedNumber = `${value.replace(/[^\d]/g, '')}`;
        this.validateField(INSTALLATION_COST, formattedNumber);
        this.setState({ installationCost: formattedNumber, hasInstallationCostBeenTouched: true });
        break;

      default:
        break;
    }
  };

  /*
   * handleInputBlur
   * Handles the input blur event
   * @param {string} fieldName Name of the field
   */
  handleInputBlur = (fieldName) => {
    const { heatProduction, unitCost, installationCost, powerRating, staticLoss, manufacturer, partNumber } = this.state;
    let formattedValue;
    switch (fieldName) {
      case UNIT_COST:
        this.setState({ unitCost: unitCost.length > 0 ? parseFloat(unitCost.toString().replace(/,/g, '')).toFixed(0) : '' });
        this.validateField(UNIT_COST, unitCost);
        break;

      case POWER_RATING:
        formattedValue = powerRating.toString().length > 0 ? parseFloat(powerRating.toString().replace(/,/g, '')) : '';
        this.setState({ powerRating: formattedValue });
        this.validateField(POWER_RATING, formattedValue);
        break;

      case INSTALLATION_COST:
        this.setState({
          installationCost: installationCost.length > 0 ? parseFloat(installationCost.toString().replace(/,/g, '')).toFixed(0) : '',
        });
        this.validateField(INSTALLATION_COST, installationCost);
        break;

      case HEAT_PRODUCTION:
        this.setState({
          heatProduction: heatProduction.length > 0 ? formatThousandsNumber(heatProduction.toString().replace(/,/g, '')) : '',
        });
        this.validateField(HEAT_PRODUCTION, heatProduction);
        break;

      case STATIC_LOSS:
        formattedValue = staticLoss.toString().length > 0 ? parseFloat(staticLoss.toString().replace(/,/g, '')) : '';
        this.setState({ staticLoss: formattedValue });
        this.validateField(STATIC_LOSS, formattedValue);
        break;

      case MANUFACTURER:
        this.setState({ manufacturer: manufacturer.length > 0 ? manufacturer : '' });
        this.validateField(MANUFACTURER, manufacturer);
        break;

      case PART_NUMBER:
        this.setState({ partNumber: partNumber.length > 0 ? partNumber : '' });
        this.validateField(PART_NUMBER, partNumber);
        break;

      default:
        break;
    }
  };

  /**
   * handleCancelChanges
   * handles when the user cancel the creation or edition of a component
   */
  handleCancelChanges = () => {
    this.resetForm();
    this.setState({ isCancelModalVisible: false, defaultData: null });
    this.props.onCancel();
  };

  /**
   * handleContinueUpdating
   * handles when the user cancel the creation or edition of a component and the user cancel that action
   */
  handleContinueUpdating = () => {
    this.setState({ isCancelModalVisible: false });
  };

  /**
   * handleCreateNewDialogClose
   * handle the close event for form modal
   */
  handleCreateNewDialogClose = () => {
    if (this.hasChanges()) {
      this.setState({ isCancelModalVisible: true });
    } else {
      this.props.onCancel();
      this.resetForm();
    }
  };

  /*
   * handleEngineTypeChange
   * Handles the select change event
   * @param {Object} event Event object
   */
  handleEngineTypeChange = (event) => {
    this.setState({
      engineType: event.target.value,
    });
    this.validateField(ENGINE_TYPE, event.target.value);
  };

  /*
   * handleEngineTypeClose
   * Handles close event for the engine type field
   */
  handleEngineTypeClose = () => {
    setTimeout(() => {
      const { engineType } = this.state;
      this.validateField(ENGINE_TYPE, engineType);
    }, 100);
  };

  /*
   * renderInputField
   * Renders an input value
   * @param {string} key Field name
   * @param {string} title Name to display to the left of the field
   * @param {string} rightUnit Unit to display to the right of the input
   * @param {string} value Input value
   * @param {string} type Type of the field
   * @param {string} errorText Text to display under the input
   * @param {boolean} isValid Flag t determine if the field is valid and display the error message
   * @param {boolean} autoFocus Auto focus field flag
   * @param {boolean} isEnabled flag to determine if the control is enabled or not
   * @param {string} titleComplement string that complets the title if is the case
   * @param {string} leftUnit Unit to display to the left of the input
   * @param {boolean} small Flag to determine the sice of the input
   * @param {object} inputProps Extra props to add to the input
   */
  renderInputField = ({
    key,
    title,
    rightUnit,
    value,
    type,
    alignRight = true,
    errorText,
    isValid,
    autoFocus = false,
    isEnabled,
    titleComplement,
    leftUnit,
    small,
    inputProps,
    required,
  }) => {
    const { classes, readonly } = this.props;
    const errorMessage = this.renderErrorMessage(!isValid, errorText);
    const titleText = title ? <FormattedMessage {...title} values={{ type: titleComplement || '' }} /> : '';
    return (
      <TwoColumnRow title={titleText} error={errorMessage} key={key} testId={`two-column-${key}`}>
        <div className={classes.inputContainer}>
          {leftUnit && (
            <Typography color="primary" className={classes.currencyText}>
              {leftUnit}
            </Typography>
          )}
          <div className={setValueTextClass(rightUnit, leftUnit, small, classes, readonly)}>
            {readonly ? (
              value
            ) : (
              <Input
                autoFocus={autoFocus}
                invalid={!isValid}
                name={key}
                value={value}
                type={type}
                required={required}
                onChange={this.handleInputChange}
                alignRight={alignRight}
                onFocus={this.handleInputFocus}
                onBlur={() => this.handleInputBlur(key)}
                inputProps={inputProps}
                disabled={isEnabled}
              />
            )}
          </div>
          {rightUnit && (
            <Typography color="primary" variant="caption" className={`${classes.unitText} ${!readonly ? classes.notReadOnlyUnitText : ''}`}>
              {rightUnit}
            </Typography>
          )}
        </div>
      </TwoColumnRow>
    );
  };

  /*
   * renderErrorMessage
   * Renders the error message to display bellow each of the fields
   * @param {boolean} error Flag to display the error message
   * @param {string} message Message to display
   */
  renderErrorMessage(error, message) {
    if (error && message) {
      return (
        <Typography variant="caption" color="error">
          <FormattedMessage {...message} />
        </Typography>
      );
    }
    return null;
  }

  render() {
    const { classes, isModalVisible, readonly } = this.props;
    const {
      isCancelModalVisible,

      manufacturer,
      manufacturerErrorMessage,
      isManufacturerValid,

      partNumber,
      isPartNumberValid,
      partNumberErrorMessage,

      powerRating,
      isPowerRatingValid,
      powerRatingErrorMessage,

      engineType,
      isEngineTypeValid,

      heatProduction,
      isHeatProductionValid,
      heatProductionErrorMessage,

      staticLoss,
      isStaticLossValid,
      staticLossErrorMessage,

      unitCost,
      isUnitCostValid,
      unitCostErrorMessage,

      installationCost,
      isInstallationCostValid,
      installationCostErrorMessage,
    } = this.state;
    const modalTitle = readonly ? messages.viewEngineComponentModalTitle : messages.createEngineComponentModalTitle;
    const paperPropsStyle = { maxWidth: '30rem' };
    return (
      <>
        {isCancelModalVisible && (
          <AlertModal
            title={messages.alertCancelChangesTitle}
            message={messages.alertCancelChangesMessage}
            isVisible={isCancelModalVisible}
            acceptLabel={messages.alertYesLabel}
            cancelLabel={messages.alertNoLabel}
            onAccept={this.handleCancelChanges}
            onCancel={this.handleContinueUpdating}
            size="30rem"
          />
        )}
        <Modal
          open={isModalVisible}
          onClose={this.handleCreateNewModalClose}
          title={<FormattedMessage {...modalTitle} />}
          paperPropsStyle={paperPropsStyle}
        >
          <>
            {this.renderInputField({
              key: MANUFACTURER,
              title: messages.manufactureLabel,
              value: manufacturer,
              type: 'text',
              alignRight: false,
              errorText: manufacturerErrorMessage === REQUIRED_ERROR && messages.manufactureError,
              isValid: isManufacturerValid,
              inputProps: { maxLength: 200 },
            })}

            {this.renderInputField({
              key: PART_NUMBER,
              title: messages.partNumberLabel,
              value: partNumber,
              type: 'text',
              alignRight: false,
              errorText: partNumberErrorMessage === REQUIRED_ERROR ? messages.partNumberRequiredError : messages.partNumberFormatError,
              isValid: isPartNumberValid,
              inputProps: { maxLength: 100 },
            })}

            <TwoColumnRow
              title={<FormattedMessage {...messages.engineTypeLabel} />}
              error={this.renderErrorMessage(!isEngineTypeValid, messages.engineTypeRequiredError)}
              testId={`two-column-${ENGINE_TYPE}`}
            >
              {!readonly && (
                <Select
                  className={classes.engineTypeSelect}
                  data-testid="select-engine-dropdown"
                  id="engineType"
                  name="engineType"
                  value={engineType}
                  onChange={this.handleEngineTypeChange}
                  onClose={this.handleEngineTypeClose}
                  variant="outlined"
                  onBlur={this.handleEngineTypeClose}
                  error={!isEngineTypeValid}
                  IconComponent={(props) => <img src={images.chevronIcon} {...props} alt="chevronIcon" />}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {ENGINE_TYPE_LIST.map((row) => (
                    <MenuItem value={row.value} key={row.value} data-testid={`select-engine-dropdown-${row.value}`}>
                      {row.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
              {readonly && engineType}
            </TwoColumnRow>

            {this.renderInputField({
              key: POWER_RATING,
              title: messages.powerRatingLabel,
              value: formatThousandsNumber(powerRating),
              rightUnit: KW,
              type: 'text',
              errorText:
                powerRatingErrorMessage === REQUIRED_ERROR
                  ? messages.powerRatingRequiredErrorMessage
                  : messages.enginePowerRatingRangeErrorMessage,
              isValid: isPowerRatingValid,
            })}

            {this.renderInputField({
              key: STATIC_LOSS,
              title: messages.staticLossLabel,
              value: formatThousandsNumber(staticLoss),
              rightUnit: KW,
              type: 'text',
              errorText:
                staticLossErrorMessage === REQUIRED_ERROR
                  ? messages.staticLossRequiredErrorMessage
                  : messages.engineStaticLossRangeErrorMessage,
              isValid: isStaticLossValid,
            })}
            {((readonly && +heatProduction > 0) || !readonly) &&
              this.renderInputField({
                key: HEAT_PRODUCTION,
                title: messages.heatProductionLabel,
                value: readonly ? numberFormat(heatProduction, 2) : heatProduction,
                rightUnit: MMBTU_PER_HOUR,
                type: 'text',
                errorText:
                  heatProductionErrorMessage === REQUIRED_ERROR
                    ? messages.heatProductionRequiredErrorMessage
                    : messages.heatProductionPatternErrorMessage,
                isValid: isHeatProductionValid,
              })}

            {this.renderInputField({
              key: UNIT_COST,
              title: messages.unitCostLabel,
              value: formatThousandsNumber(unitCost),
              type: 'text',
              errorText:
                unitCostErrorMessage === REQUIRED_ERROR ? messages.unitCostRequiredErrorMessage : messages.unitCostPatternErrorMessage,
              isValid: isUnitCostValid,
              leftUnit: DOLLAR_SYMBOL,
            })}

            {this.renderInputField({
              key: INSTALLATION_COST,
              title: messages.installationCostLabel,
              type: 'text',
              errorText:
                installationCostErrorMessage === REQUIRED_ERROR
                  ? messages.installationCostRequiredErrorMessage
                  : messages.installationCostPatternErrorMessage,
              isValid: isInstallationCostValid,
              leftUnit: DOLLAR_SYMBOL,
              value: formatThousandsNumber(installationCost),
            })}
          </>
          {!readonly && (
            <>
              <div className={classes.saveButton}>
                <Button
                  handleRoute={this.handleSaveClick}
                  disabled={!this.enableSaveButton()}
                  disableRipple={false}
                  data-testid="save-button"
                >
                  <FormattedMessage {...messages.save} />
                </Button>
              </div>
              <div className={classes.cancelButton}>
                <Button onClick={this.handleCreateNewDialogClose}>
                  <FormattedMessage {...messages.cancel} />
                </Button>
              </div>
            </>
          )}
          {readonly && (
            <Button onClick={() => this.props.onCancel()}>
              <FormattedMessage {...messages.close} />
            </Button>
          )}
        </Modal>
      </>
    );
  }
}

EngineModal.propTypes = {
  classes: PropTypes.object,
  readonly: PropTypes.bool,
  onCancel: PropTypes.func,
  onAccept: PropTypes.func,
  isModalVisible: PropTypes.bool,
  defaultData: PropTypes.shape({
    id: PropTypes.string,
    manufacturer: PropTypes.string,
    partNumber: PropTypes.string,
    engineType: PropTypes.string,
    powerRating: PropTypes.number,
    staticLoss: PropTypes.string,
    heatProduction: PropTypes.string,
    unitCost: PropTypes.number,
    installationCost: PropTypes.string,
  }),
};

export default withStyles(useStyles)(EngineModal);
