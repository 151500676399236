/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/function-component-definition */
/**
 *
 * HeaderConfiguration.js
 * Header content for configuration
 */

import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { Colors } from '../../../theme';
import Breadcrumbs from '../../Breadcrumbs';
import images from '../../../assets/images';
import configurationStatus from '../../../utils/configurationStatus';
import useStyles from './styles';

/**
 * HeaderConfiguration
 * @param {string} title Title to show
 * @param {string} subtitle1 Subtitle to show
 * @param {string} subtitle2 Subtitle to show in green format
 * @param {object} breadcrumbs Breadcrumbs to show
 * @param {function} handleEditSubtitle Method to trigger then the edit icon is pressed
 */
const HeaderConfiguration = ({
  title = '',
  subtitle1 = '',
  subtitle2 = '',
  breadcrumbs = [],
  status = '',
  handleEditSubtitle,
  isQuickConfiguration,
}) => {
  const classes = useStyles({ isQuickConfiguration });

  let statusColor = '';

  switch (status) {
    case configurationStatus.new:
      statusColor = Colors.mountainMeadow;
      break;
    case configurationStatus.inProgress:
      statusColor = Colors.hokeyPok;
      break;
    case configurationStatus.inReview:
      statusColor = Colors.alizarinCrimson;
      break;
    case configurationStatus.underContract:
      statusColor = Colors.tarawera;
      break;
    case configurationStatus.sentToIcian:
      statusColor = Colors.havelockBlue;
      break;
    case configurationStatus.archive:
      statusColor = Colors.alizarinCrimson;
      break;
    case configurationStatus.lost:
      statusColor = Colors.lunarGreen;
      break;
    default:
      statusColor = Colors.black;
  }

  return (
    <div className={classes.wrapper}>
      <Box className={classes.upperWrapper}>
        <Box className={classes.titleWrapper}>
          <Box className={classes.statusWrapper}>
            <Tooltip title={title}>
              <Typography variant="h2" className={classes.leftSeparator}>
                {title}
              </Typography>
            </Tooltip>
            {isQuickConfiguration && (
              <button type="button" className={classes.button} onClick={handleEditSubtitle} data-testid="header-edit-subtitle">
                <img alt="edit" src={images.editIcon} />
              </button>
            )}
            {!isQuickConfiguration && (
              <Typography className={classes.statusView} style={{ backgroundColor: statusColor }}>
                {status.toUpperCase()}
              </Typography>
            )}
          </Box>
          <Typography variant="subtitle2" className={clsx([classes.leftSeparator, classes.overflowName])}>
            {subtitle2}
          </Typography>
          <Box className={classes.statusWrapper}>
            <Typography variant="subtitle1" className={clsx([classes.leftSeparator, classes.overflowName])}>
              {subtitle1}
            </Typography>
            {!isQuickConfiguration && (
              <button type="button" className={classes.button} onClick={handleEditSubtitle}>
                <img alt="edit" src={images.editIcon} />
              </button>
            )}
          </Box>
        </Box>
      </Box>
      <div className={classes.lowerWrapper}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </div>
    </div>
  );
};

HeaderConfiguration.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle1: PropTypes.string.isRequired,
  subtitle2: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
  handleEditSubtitle: PropTypes.func,
  isQuickConfiguration: PropTypes.bool,
};

export default HeaderConfiguration;
