/*
 * propertyExist
 * Validate value resolved exist or not
 * @param {function} function to resolve a value
 * @return {boolean} true if value resolved exists otherwise false
 */
export function propertyExist(fn) {
  // Disabling block because try is considered as unnecessary block.
  /* eslint-disable */
  try {
    return fn() !== null && typeof fn() !== 'undefined';
  } catch (error) {
    return false;
  }
  /* eslint-enable */
}

/*
 * stringIsNullOrEmpty
 * Validate value resolved exist or not
 * @param {function} function to resolve a string value
 * @return {boolean} true if string  is null/undefined or empty
 */
export function stringIsNullOrEmpty(stringValue) {
  // Disabling block because try is considered as unnecessary block.
  /* eslint-disable */
  try {
    return !propertyExist(() => stringValue) || stringValue.trim() === '';
  } catch (error) {
    return true;
  }
  /* eslint-enable */
}

/*
 * stringContains
 * Validate if an string contains other string
 * @param {string} value string data to validate
 * @param {string} searchText string to find in string value
 * @param {boolean} caseSensitive indicates if use case sensitive mode
 * @return {boolean} true if searchText is contained on value string otherwise false
 */
export function stringContains(value, searchText, caseSensitive = true) {
  let result = false;

  if (propertyExist(() => value) && propertyExist(() => searchText)) {
    const stringValue = caseSensitive ? value : value.toLowerCase();
    const searchValue = caseSensitive ? searchText : searchText.toLowerCase();

    result = stringValue.includes(searchValue);
  }

  return result;
}

/**
 * firstElement returns the first not undefined element on the array
 * @param {Array} array array to get the fisrt available element.
 */
export const firstElement = (array) => {
  if (Array.isArray(array)) {
    return array.find((element) => !!element);
  }
  let dataUndefined;
  return dataUndefined;
};

/**
 * getValueIfExists returns the elements if exist otherwise returns undefined
 * @param {Function} fnValue a function to evaluate and if it exists return the value
 * @param {Object} defaultValue default value to return if function value does not exist
 * @returns fnValue or defaultValue
 */
export const getValueIfExists = (fnValue, defaultValue) => (propertyExist(fnValue) ? fnValue() : defaultValue);

/**
 * isNumeric
 * It returns if value is numeric, validate null, boolean type, empty, isNaN
 * @param {object} value value to be evaluated.
 * @returns {boolean}
 */
export const isNumeric = (value) =>
  // eslint-disable-next-line
  !stringIsNullOrEmpty(value) && typeof value !== 'boolean' && !isNaN(value);
