/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
/* eslint-disable camelcase */
/** Component Type container
 *
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { Backdrop, CircularProgress, TableContainer, Table, TableBody, TableHead, Paper, Typography, Box } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import Pagination from '@mui/lab/Pagination';
import useStyles from './styles';
import { getComponents, patchComponent, postComponent } from '../Home/actions';
import messages from './messages';
import ComponentManagerTableHeader from './TableHeader';
import ComponentManagerRow from './TableRow';
import CreateNewComponentModal from './CreateNewModal';
import AlertModal from '../../../components/AlertModal';
import Header from '../../../components/Header';
import TabPanel from '../../../components/TabPanel';
import images from '../../../assets/images';
import { componentTypes } from '../utilities';

const ACTIVE_TAB_INDEX = 0;
const INACTIVE_TAB_INDEX = 1;

class ComponentTypeContainer extends Component {
  constructor() {
    super();

    this.state = {
      isLoading: false,
      searchText: '',
      currentPage: 1,
      rowsPerPage: 25,
      activeComponents: [],
      inactiveComponents: [],
      isCreateNewModalVisible: false,
      isDeactivateModalVisible: false,
      isCreateNewModalReadonly: false,
      tabSelected: 0,
      componentIdToDeactivate: '',
      currentComponentData: null,
      deactivateModalTitle: {},
      activateModalTitle: {},
    };
  }

  async componentDidMount() {
    const {
      components,
      match: {
        params: { componentsType },
      },
    } = this.props;
    let currentComponents = components[componentsType];
    if (!currentComponents || currentComponents.length === 0) {
      currentComponents = await this.props.getComponents(componentsType);
    }
    let activeComponents = [];
    let inactiveComponents = [];
    let activateModalTitle = {};
    let deactivateModalTitle = {};
    switch (componentsType) {
      case componentTypes.SOLAR_INVERTER:
        activateModalTitle = messages.alertActivateSolarInverterTitle;
        deactivateModalTitle = messages.alertDeactivateSolarInverterTitle;
        break;
      case componentTypes.SOLAR_CONVERTER:
        activateModalTitle = messages.alertActivateSolarConverterTitle;
        deactivateModalTitle = messages.alertDeactivateSolarConverterTitle;
        break;
      case componentTypes.ESS_CONVERTER:
        activateModalTitle = messages.alertActivateESSConverterTitle;
        deactivateModalTitle = messages.alertDeactivateESSConverterTitle;
        break;
      case componentTypes.ESS_INVERTER:
        activateModalTitle = messages.alertActivateESSInverterTitle;
        deactivateModalTitle = messages.alertDeactivateESSInverterTitle;
        break;
      case componentTypes.ESS_BATTERY:
        activateModalTitle = messages.alertActivateESSBatteryTitle;
        deactivateModalTitle = messages.alertDeactivateESSBatteryTitle;
        break;
      case componentTypes.EV:
        activateModalTitle = messages.alertActivateEVTitle;
        deactivateModalTitle = messages.alertDeactivateEVTitle;
        break;
      case componentTypes.ENGINE:
        activateModalTitle = messages.alertActivateEngineTitle;
        deactivateModalTitle = messages.alertDeactivateEngineTitle;
        break;
      case componentTypes.FLEET:
        activateModalTitle = messages.alertActivateFleetTitle;
        deactivateModalTitle = messages.alertDeactivateFleetTitle;
        break;
      case componentTypes.ENCLOSURE:
        activateModalTitle = messages.alertActivateEnclosureTitle;
        deactivateModalTitle = messages.alertDeactivateEnclosureTitle;
        break;
      case componentTypes.ENCLOSURE_COMPONENT:
        activateModalTitle = messages.alertActivateEnclosureTitle;
        deactivateModalTitle = messages.alertDeactivateEnclosureTitle;
        break;
      case componentTypes.DCAC_INVERTER:
        activateModalTitle = messages.alertActivateDCACInverterInverterTitle;
        deactivateModalTitle = messages.alertDeactivateDCACInverterTitle;
        break;
      default:
        break;
    }
    activeComponents = currentComponents.activeComponents || [];
    inactiveComponents = currentComponents.inactiveComponents || [];
    this.setState({
      activeComponents,
      inactiveComponents,
      isLoading: false,
      activateModalTitle,
      deactivateModalTitle,
    });
  }

  /**
   * Returns the title of the component type selected
   * @param {String} componentsType Selected Component Type
   * @returns title of the component type selected
   */
  getTitleByComponentType = (componentType) => {
    switch (componentType) {
      case componentTypes.SOLAR_INVERTER:
        return messages.titleSolarInverter;
      case componentTypes.SOLAR_CONVERTER:
        return messages.titleSolarConverter;
      case componentTypes.ESS_CONVERTER:
        return messages.titleESSConverter;
      case componentTypes.ESS_INVERTER:
        return messages.titleESSInverter;
      case componentTypes.ESS_BATTERY:
        return messages.titleESSBattery;
      case componentTypes.EV:
        return messages.titleEV;
      case componentTypes.ENGINE:
        return messages.titleEngine;
      case componentTypes.FLEET:
        return messages.titleFleet;
      case componentTypes.INVERTER:
        return messages.titleInverter;
      case componentTypes.ENCLOSURE:
        return messages.titleEnclosure;
      case componentTypes.ENCLOSURE_COMPONENT:
        return messages.titleEnclosureComponent;
      case componentTypes.DCAC_INVERTER:
        return messages.titleDCACInverterComponent;
      default:
        return messages.titleESSBattery;
    }
  };

  /**
   * Returns the name of the component type selected
   * @param {String} componentsType Selected Component Type
   * @returns name of the component type selected
   */
  getNameByComponentType = (componentType) => {
    switch (componentType) {
      case componentTypes.SOLAR_INVERTER:
        return messages.nameSolarInverter;
      case componentTypes.SOLAR_CONVERTER:
        return messages.nameSolarConverter;
      case componentTypes.ESS_CONVERTER:
        return messages.nameESSConverter;
      case componentTypes.ESS_INVERTER:
        return messages.nameESSInverter;
      case componentTypes.ESS_BATTERY:
        return messages.nameESSBattery;
      case componentTypes.EV:
        return messages.nameEV;
      case componentTypes.ENGINE:
        return messages.nameEngine;
      case componentTypes.FLEET:
        return messages.nameFleet;
      case componentTypes.INVERTER:
        return messages.nameInverter;
      case componentTypes.ENCLOSURE:
        return messages.nameEnclosure;
      case componentTypes.ENCLOSURE_COMPONENT:
        return messages.nameEnclosureComponent;
      case componentTypes.DCAC_INVERTER:
        return messages.nameDCACBusInverterComponent;
      default:
        return messages.nameESSBattery;
    }
  };

  /**
   * handleCreateNew
   * handles when the form modal is displayed or not
   */
  handleCreateNew = () => {
    this.setState({ isCreateNewModalVisible: true, currentComponentData: null });
  };

  /**
   * handleSearchTextChanges
   * searchs for certain value into all current components displayed
   * @param {*} searchText string
   */
  handleSearchTextChanges = async (searchText = '') => {
    const {
      components,
      match: {
        params: { componentsType },
      },
    } = this.props;
    let currentComponents = components[componentsType];
    if (!currentComponents || currentComponents.length === 0) {
      currentComponents = await this.props.getComponents(componentsType);
    }
    if (searchText !== '') {
      const newActiveComponents = currentComponents.activeComponents.filter(
        (component) =>
          (component.manufacturer && component.manufacturer.toLowerCase().indexOf(searchText.toLowerCase()) > -1) ||
          (component.partNumber && component.partNumber.toLowerCase().indexOf(searchText.toLowerCase()) > -1),
      );
      const newInactiveComponents = currentComponents.inactiveComponents.filter(
        (component) =>
          (component.manufacturer && component.manufacturer.toLowerCase().indexOf(searchText.toLowerCase()) > -1) ||
          (component.partNumber && component.partNumber.toLowerCase().indexOf(searchText.toLowerCase()) > -1),
      );
      this.setState({ searchText, activeComponents: newActiveComponents, inactiveComponents: newInactiveComponents, currentPage: 1 });
    } else {
      this.setState({
        searchText,
        activeComponents: currentComponents.activeComponents,
        inactiveComponents: currentComponents.inactiveComponents,
        currentPage: 1,
      });
    }
  };

  /**
   * getCurrentTabComponents
   * retrieve an array of components that will be displayed in the current tab (active/inactive)
   * @returns array[obj]
   */
  getCurrentTabComponents = () => {
    const { activeComponents, inactiveComponents, tabSelected } = this.state;
    return tabSelected === ACTIVE_TAB_INDEX ? activeComponents : inactiveComponents;
  };

  /**
   * handleDeleteComponent
   * hadles when the deactivate alert modal is displayed or not
   */
  handleDeactivateComponent = (id) => this.setState({ isDeactivateModalVisible: true, componentIdToDeactivate: id });

  /**
   * rendercomponents
   *
   * Renders a ESS Components List to be managed
   */
  renderComponents = (tabSelected) => {
    const { currentPage, rowsPerPage, searchText, activeComponents, inactiveComponents } = this.state;
    const {
      classes,
      isAdmin,
      match: {
        params: { componentsType },
      },
    } = this.props;

    const components = tabSelected === ACTIVE_TAB_INDEX ? activeComponents : inactiveComponents;

    let defaultMessage = messages.emptyComponentList;
    if (components?.length > 0) {
      return components.slice((currentPage - 1) * rowsPerPage, (currentPage - 1) * rowsPerPage + rowsPerPage).map((item) => {
        const { componentId, manufacturer, partNumber, description, createdTime, connection, isActive } = item;
        const creationDate = new Date(createdTime);
        const creationFormattedDate = `${creationDate.getMonth() + 1}/${creationDate.getDate()}/${creationDate.getFullYear()}`;
        const rowData = {
          id: componentId,
          manufacturer,
          partNumber,
          description,
          creationDate: creationFormattedDate,
          connection,
          isActive,
        };
        return (
          <ComponentManagerRow
            key={item.componentId}
            componentData={rowData}
            onDeactivate={this.handleDeactivateComponent}
            showActiveTabIcons={tabSelected === ACTIVE_TAB_INDEX}
            isAdmin={isAdmin}
            handleViewClick={this.handleViewClick}
          />
        );
      });
    }

    if (components?.length === 0 && searchText !== '') {
      defaultMessage = messages.componentSearchNotMatch;
    }

    const componentName = this.getNameByComponentType(componentsType);
    const componentIcon = this.getComponentIcon(componentsType);
    return (
      <FormattedMessage {...defaultMessage} values={{ name: componentName.defaultMessage }}>
        {(message) => (
          <tr className={classes.emptyContainer}>
            <td colSpan={5} height="500">
              <img width="75" height="75" alt={message} src={componentIcon} />
              <Typography>{message}</Typography>
            </td>
          </tr>
        )}
      </FormattedMessage>
    );
  };

  /**
   * handleViewClick
   * Handles opening a read-only modal when clicking on a table row 'eye' icon
   * @param {Object} componentData Component data object
   */
  handleViewClick = (componentData) => {
    const {
      match: {
        params: { componentsType },
      },
    } = this.props;

    const components = this.props.components[componentsType];
    const allComponents = [...components.activeComponents, ...components.inactiveComponents];
    const componentToView = allComponents.find((comp) => comp.componentId === componentData.id);
    this.setState({
      currentComponentData: componentToView,
      isCreateNewModalVisible: true,
      isCreateNewModalReadonly: true,
    });
    this.renderComponentModal(componentsType);
  };

  /**
   * getComponentIcon
   * @param {string} componentType
   * @returns the icon for the current component types
   */
  getComponentIcon = (componentType) => {
    switch (componentType) {
      case componentTypes.SOLAR_INVERTER:
      case componentTypes.SOLAR_CONVERTER:
        return images.iconSolar;
      case componentTypes.ESS_CONVERTER:
      case componentTypes.ESS_INVERTER:
      case componentTypes.ESS_BATTERY:
        return images.essBatteryIcon;
      case componentTypes.EV:
        return images.iconEv;
      case componentTypes.ENGINE:
        return images.iconEngine;
      case componentTypes.FLEET:
        return images.iconFleet;
      case componentTypes.ENCLOSURE:
        return images.enclosureIcon;
      case componentTypes.ENCLOSURE_COMPONENT:
        return images.enclosureIcon;
      case componentTypes.DCAC_INVERTER:
        return images.iconInverter;
      default:
        return images.noComponents;
    }
  };

  /**
   * deactivateComponent
   * deativate a component
   */
  deactivateComponent = () => {
    const { componentIdToDeactivate } = this.state;
    const components = this.getCurrentTabComponents();
    const componentToDeactivate = components.find((component) => component.componentId === componentIdToDeactivate);
    const newComponentData = { ...componentToDeactivate, isActive: !componentToDeactivate.isActive };
    this.saveComponentData(newComponentData);
    this.setState({ isDeactivateModalVisible: false, isLoading: true, componentIdToDeactivate: '' });
  };

  /**
   * saveComponentData
   * handles when the user clicks on save button inside form modal to create or edit a component configuration
   * @param {*} componentData obj
   */
  saveComponentData = (componentData) => {
    const {
      match: {
        params: { componentsType },
      },
    } = this.props;

    window.onbeforeunload = undefined;
    const currentComponentType = componentsType;
    this.setState({ isCreateNewModalVisible: false });
    if (componentData.componentId) {
      this.props.patchComponent({ ...componentData }, currentComponentType).then(() => {
        this.props.getComponents(currentComponentType).then((response) => {
          const activeComponents = response.filter((component) => component.isActive);
          const inactiveComponents = response.filter((component) => component.isActive === false);
          this.setState({
            activeComponents,
            inactiveComponents,
            isLoading: false,
            currentComponentData: null,
          });
        });
      });
    } else {
      this.props.postComponent({ ...componentData }, currentComponentType).then(() => {
        this.props.getComponents(currentComponentType).then((response) => {
          const activeComponents = response.filter((component) => component.isActive);
          const inactiveComponents = response.filter((component) => component.isActive === false);
          this.setState({
            activeComponents,
            inactiveComponents,
            isLoading: false,
            currentComponentData: null,
          });
        });
      });
    }
  };

  /**
   * handleTabChange
   * handles the change in the tab component
   * @param {int} newTabSelected
   */
  handleTabChange = (newTabSelected) => {
    this.setState({ tabSelected: newTabSelected, searchText: '', currentPage: 1 });
  };

  /**
   * handleChangePage
   * Handles change page number event
   * @param {object} event Change Page event
   * @param {number} newPage New Page Number
   */
  handleChangePage = (event, newPage) => {
    this.setState({ currentPage: newPage });
  };

  /**
   * renderModals
   * renders the alert modals and the form modal
   */
  renderModals = () => {
    const { isDeactivateModalVisible, componentIdToDeactivate, tabSelected, deactivateModalTitle, activateModalTitle } = this.state;
    const isActiveTab = tabSelected === ACTIVE_TAB_INDEX;
    const components = this.getCurrentTabComponents();
    let deactivateComplement;
    if (componentIdToDeactivate !== '') {
      const componentToDeactivate = components.find((component) => component.componentId === componentIdToDeactivate);
      const { manufacturer, partNumber } = componentToDeactivate;
      deactivateComplement = `${manufacturer} - ${partNumber}`;
      return (
        <>
          <AlertModal
            title={isActiveTab ? deactivateModalTitle : activateModalTitle}
            description={messages.alertDeactivateDescription}
            descriptionComplement={deactivateComplement}
            message={isActiveTab ? messages.alertDeactivateMessage : messages.alertActivateMessage}
            isVisible={isDeactivateModalVisible}
            acceptLabel={isActiveTab ? messages.alertDeactiveLabel : messages.alertActiveLabel}
            cancelLabel={messages.alertNoLabel}
            onAccept={this.deactivateComponent}
            onCancel={() => this.setState({ isDeactivateModalVisible: false, componentIdToDeactivate: '' })}
            size="30rem"
          />
        </>
      );
    }
    return <></>;
  };

  /**
   * renderComponentModal
   * Render the Component Modal for the current component type
   * @returns Modal with the fields for the current component type
   */
  renderComponentModal = (currentComponentType) => {
    const { isCreateNewModalVisible, isCreateNewModalReadonly, currentComponentData } = this.state;
    window.onbeforeunload = () => true;

    return (
      <CreateNewComponentModal
        isModalVisible={isCreateNewModalVisible}
        defaultData={currentComponentData !== null ? currentComponentData : null}
        onCancel={() => {
          window.onbeforeunload = undefined;
          this.setState({ isCreateNewModalVisible: false, currentComponentData: null, isCreateNewModalReadonly: false });
        }}
        onAccept={this.saveComponentData}
        readonly={isCreateNewModalReadonly}
        componentsType={currentComponentType}
      />
    );
  };

  render() {
    const {
      match: {
        params: { componentsType },
      },
    } = this.props;
    const { isLoading, activeComponents, inactiveComponents, rowsPerPage, currentPage, tabSelected, isCreateNewModalVisible } = this.state;
    const currentComponentType = componentsType;
    const { classes, isAdmin } = this.props;
    const isActiveTabSelected = tabSelected === ACTIVE_TAB_INDEX;
    const components = isActiveTabSelected ? activeComponents : inactiveComponents;
    const totalPages = components ? Math.ceil(components.length / rowsPerPage) : 0;
    const headerTitle = this.getTitleByComponentType(currentComponentType);
    const isPaginControlDisplayed = totalPages > 1;
    const statusTitle = isActiveTabSelected ? messages.activeTitle : messages.inactiveTitle;
    return (
      <>
        {this.renderModals()}
        {isCreateNewModalVisible && this.renderComponentModal(currentComponentType)}
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box className={classes.wrapper}>
          <FormattedMessage {...headerTitle}>
            {(title) => (
              <Header
                componentManager
                title={title}
                handleTabChange={this.handleTabChange}
                tabSelected={tabSelected}
                handleSearch={this.handleSearchTextChanges}
                handleCreateNewComponent={this.handleCreateNew}
                isAdmin={isAdmin}
              />
            )}
          </FormattedMessage>
          <TabPanel value={tabSelected} index={[ACTIVE_TAB_INDEX]} className={classes.tabContainer}>
            <div className={classes.bodyWrapper}>
              <TableContainer className={classes.table} component={Paper}>
                <Table padding="none" aria-label="customized table">
                  <TableHead>
                    <ComponentManagerTableHeader statusTitle={statusTitle} isAdmin={isAdmin} />
                  </TableHead>
                  <TableBody>{this.renderComponents(ACTIVE_TAB_INDEX)}</TableBody>
                </Table>
              </TableContainer>
              <div className={classes.paginationWrapper}>
                {isPaginControlDisplayed && (
                  <Pagination count={totalPages} variant="outlined" shape="rounded" page={currentPage} onChange={this.handleChangePage} />
                )}
              </div>
            </div>
          </TabPanel>
          <TabPanel value={tabSelected} index={[INACTIVE_TAB_INDEX]} className={classes.tabContainer}>
            <div className={classes.bodyWrapper}>
              <TableContainer className={classes.table} component={Paper}>
                <Table padding="none" aria-label="customized table">
                  <TableHead>
                    <ComponentManagerTableHeader isAdmin={isAdmin} />
                  </TableHead>
                  <TableBody>{this.renderComponents(INACTIVE_TAB_INDEX)}</TableBody>
                </Table>
              </TableContainer>
              <div className={classes.paginationWrapper}>
                {isPaginControlDisplayed && (
                  <Pagination count={totalPages} variant="outlined" shape="rounded" page={currentPage} onChange={this.handleChangePage} />
                )}
              </div>
            </div>
          </TabPanel>
        </Box>
      </>
    );
  }
}

ComponentTypeContainer.propTypes = {
  title: PropTypes.string,
  classes: PropTypes.object,
  getComponents: PropTypes.func,
  patchComponent: PropTypes.func,
  postComponent: PropTypes.func,
  history: PropTypes.object,
  essBatteryComponents: PropTypes.array,
  isAdmin: PropTypes.bool,
  match: PropTypes.object,
  components: PropTypes.object,
  componentsType: PropTypes.string,
};

const mapStateToProps = (state) => {
  const {
    auth: { userGroups, userGroupList },
    componentManager,
  } = state;
  return { isAdmin: userGroups === userGroupList.Admins, ...componentManager };
};

export default connect(mapStateToProps, {
  getComponents,
  patchComponent,
  postComponent,
})(withStyles(useStyles)(ComponentTypeContainer));
