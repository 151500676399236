export const SET_USER = 'SET_USER';
export const CLEAR_USER = 'CLEAR_USER';

export const GET_NOTIFICATIONS_START = 'GET_NOTIFICATIONS_START';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAIL = 'GET_NOTIFICATIONS_FAIL';

export const DISMISS_ALL_NOTIFICATIONS_START = 'DISMISS_ALL_NOTIFICATIONS_START';
export const DISMISS_ALL_NOTIFICATIONS_SUCCESS = 'DISMISS_ALL_NOTIFICATIONS_SUCCESS';
export const DISMISS_ALL_NOTIFICATIONS_FAIL = 'DISMISS_ALL_NOTIFICATIONS_FAIL';

export const DISMISS_SINGLE_NOTIFICATION_START = 'DISMISS_SINGLE_NOTIFICATION_START';
export const DISMISS_SINGLE_NOTIFICATION_SUCCESS = 'DISMISS_SINGLE_NOTIFICATION_SUCCESS';
export const DISMISS_SINGLE_NOTIFICATION_FAIL = 'DISMISS_SINGLE_NOTIFICATION_FAIL';
export const VIEW_SINGLE_NOTIFICATION_START = 'VIEW_SINGLE_NOTIFICATION_START';
export const VIEW_SINGLE_NOTIFICATION_SUCCESS = 'VIEW_SINGLE_NOTIFICATION_SUCCESS';
export const VIEW_SINGLE_NOTIFICATION_FAIL = 'VIEW_SINGLE_NOTIFICATION_FAIL';

export const GET_USER_GROUPS_START = 'GET_USER_GROUPS_START';
export const GET_USER_GROUPS_SUCCESS = 'GET_USER_GROUPS_SUCCESS';
export const GET_USER_GROUPS_FAIL = 'GET_USER_GROUPS_FAIL';

export const GET_USER_GROUP_LIST_START = 'GET_USER_GROUP_LIST_START';
export const GET_USER_GROUP_LIST_SUCCESS = 'GET_USER_GROUP_LIST_SUCCESS';
export const GET_USER_GROUP_LIST_FAIL = 'GET_USER_GROUP_LIST_FAIL';
