/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/destructuring-assignment */
/** Component manager home page
 *
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { Backdrop, CircularProgress, Typography, Box, Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import useStyles from './styles';
import { getComponents } from './actions';
import images from '../../../assets/images';
import Card from './Card';
import messages from './messages';
import { componentTypes } from '../utilities';

class ComponentManager extends Component {
  constructor() {
    super();

    this.state = {
      componentsTypesList: [
        {
          typeOfComponents: componentTypes.ESS_BATTERY,
          title: messages.essBattery,
          description: messages.components,
          icon: images.essBatteryIcon,
          totalComponents: 0,
        },
        {
          typeOfComponents: componentTypes.ESS_INVERTER,
          title: messages.essInverter,
          description: messages.components,
          icon: images.essBatteryIcon,
          totalComponents: 0,
        },
        {
          typeOfComponents: componentTypes.ESS_CONVERTER,
          title: messages.essConverter,
          description: messages.components,
          icon: images.essBatteryIcon,
          totalComponents: 0,
        },
        {
          typeOfComponents: componentTypes.SOLAR_INVERTER,
          title: messages.solarInverter,
          description: messages.components,
          icon: images.iconSolar,
          totalComponents: 0,
        },
        {
          typeOfComponents: componentTypes.SOLAR_CONVERTER,
          title: messages.solarConverter,
          description: messages.components,
          icon: images.iconSolar,
          totalComponents: 0,
        },
        {
          typeOfComponents: componentTypes.ENGINE,
          title: messages.engine,
          description: messages.components,
          icon: images.iconEngine,
          totalComponents: 0,
        },
        {
          typeOfComponents: componentTypes.EV,
          title: messages.ev,
          description: messages.components,
          icon: images.iconEv,
          totalComponents: 0,
        },
        {
          typeOfComponents: componentTypes.FLEET,
          title: messages.fleet,
          description: messages.components,
          icon: images.iconFleet,
          totalComponents: 0,
        },
        {
          typeOfComponents: componentTypes.ENCLOSURE,
          title: messages.enclosure,
          description: messages.components,
          icon: images.enclosureIcon,
          totalComponents: 0,
        },
        {
          typeOfComponents: componentTypes.ENCLOSURE_COMPONENT,
          title: messages.enclosureComponent,
          description: messages.components,
          icon: images.enclosureIcon,
          totalComponents: 0,
        },
        {
          typeOfComponents: componentTypes.DCAC_INVERTER,
          title: messages.dcacInverterComponent,
          description: messages.components,
          icon: images.iconInverter,
          totalComponents: 0,
        },
      ],
      isLoading: false,
    };
  }

  componentDidMount() {
    // eslint-disable-next-line
    for (const componentType in componentTypes) {
      this.getComponentByType(componentTypes[componentType.valueOf()]);
    }
  }

  /**
   * getComponentByType
   * @param {string} componentType
   */
  getComponentByType(componentType) {
    this.props.getComponents(componentType);
  }

  /**
   * handleCardOnClick
   * handles when the user clicks over a card to see his components list
   * @param {*} selectedComponentType
   */
  handleCardOnClick = (selectedComponentType) => {
    const { history } = this.props;
    history.push({
      pathname: `/componentmanager/${selectedComponentType}`,
    });
  };

  /**
   * handleCardOnKeyPress
   * Handles the Card on Key Press
   * @param {object} event Key Press Event Object
   * @param {string} selectedComponentType Component Type
   */
  handleCardOnKeyPress = (event, selectedComponentType) => {
    if (event.key === 'Enter') {
      this.handleCardOnClick(selectedComponentType);
    }
  };

  render() {
    const { componentsTypesList, isLoading } = this.state;
    const { classes, components } = this.props;
    return (
      <>
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <Box className={classes.headerContainer}>
          <FormattedMessage {...messages.title}>
            {(title) => (
              <Typography variant="h2" className={classes.leftSeparator}>
                {title}
              </Typography>
            )}
          </FormattedMessage>
          <Box className={classes.bodyContainer}>
            <Grid container spacing={2}>
              {componentsTypesList.map((component, index) => {
                const componentAux = components[component.typeOfComponents];
                const length = componentAux?.length || 0;
                return (
                  <Grid
                    tabIndex={index + 1}
                    className={classes.gridItem}
                    key={component.typeOfComponents}
                    item
                    xs={12}
                    sm={4}
                    md={3}
                    lg={3}
                    onKeyPress={(event) => this.handleCardOnKeyPress(event, component.typeOfComponents)}
                  >
                    <Card
                      title={component.title}
                      description={component.description}
                      typeOfComponents={component.typeOfComponents}
                      icon={component.icon}
                      totalComponents={length}
                      onClick={this.handleCardOnClick}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
      </>
    );
  }
}

ComponentManager.propTypes = {
  classes: PropTypes.object,
  getComponents: PropTypes.func,
  createNewComponent: PropTypes.func,
  deactivateComponent: PropTypes.func,
  history: PropTypes.object,
  essComponents: PropTypes.array,
  isAdmin: PropTypes.bool,
  components: PropTypes.array,
};

const mapStateToProps = (state) => {
  const {
    auth: { userGroups, userGroupList },
    componentManager,
  } = state;
  return { isAdmin: userGroups === userGroupList.Admins, ...componentManager };
};

export default connect(mapStateToProps, {
  getComponents,
})(withStyles(useStyles)(ComponentManager));
