/* eslint-disable default-param-last */
import * as actionTypes from './types';

const NA_GROUP = 'NA_GROUP';

const INITIAL_STATE = {
  user: null,
  userGroups: null,
  notifications: [],
  singleNotification: null,
  userGroupList: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SET_USER:
      return { ...state, user: action.payload };
    case actionTypes.CLEAR_USER:
      return { ...state, user: null };
    case actionTypes.GET_NOTIFICATIONS_START:
      return { ...state, isBusy: true };
    case actionTypes.GET_NOTIFICATIONS_SUCCESS:
      return { ...state, isBusy: false, notifications: action.payload };
    case actionTypes.GET_NOTIFICATIONS_FAIL:
      return { ...state, isBusy: false, notifications: [] };
    case actionTypes.DISMISS_ALL_NOTIFICATIONS_START:
      return { ...state, isBusy: true };
    case actionTypes.DISMISS_ALL_NOTIFICATIONS_SUCCESS:
      return { ...state, isBusy: false, notifications: [] };
    case actionTypes.DISMISS_ALL_NOTIFICATIONS_FAIL:
      return { ...state, isBusy: false };
    case actionTypes.DISMISS_SINGLE_NOTIFICATION_START:
      return { ...state, isBusy: true };
    case actionTypes.DISMISS_SINGLE_NOTIFICATION_SUCCESS:
      return { ...state, isBusy: false, singleNotification: action.payload };
    case actionTypes.DISMISS_SINGLE_NOTIFICATION_FAIL:
      return { ...state, isBusy: false, singleNotification: null };
    case actionTypes.VIEW_SINGLE_NOTIFICATION_START:
      return { ...state, isBusy: true };
    case actionTypes.VIEW_SINGLE_NOTIFICATION_SUCCESS:
      return { ...state, isBusy: false, singleNotification: action.payload };
    case actionTypes.VIEW_SINGLE_NOTIFICATION_FAIL:
      return { ...state, isBusy: false, singleNotification: null };
    case actionTypes.GET_USER_GROUPS_START:
      return { ...state, isBusy: true };
    case actionTypes.GET_USER_GROUPS_SUCCESS:
      return { ...state, isBusy: false, userGroups: action.payload || NA_GROUP };
    case actionTypes.GET_USER_GROUPS_FAIL:
      return { ...state, isBusy: false, userGroups: null };
    case actionTypes.GET_USER_GROUP_LIST_START:
      return { ...state, isBusy: true };
    case actionTypes.GET_USER_GROUP_LIST_SUCCESS:
      return { ...state, isBusy: false, userGroupList: action.payload };
    case actionTypes.GET_USER_GROUP_LIST_FAIL:
      return { ...state, isBusy: false, userGroupList: null };
    default:
      return state;
  }
};
