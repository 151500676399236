/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/function-component-definition */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { FormattedMessage } from 'react-intl';
import NotificationRow from './NotificationRow';
import messages from './messages';
import images from '../../assets/images';
import useStyles from './styles';
import Icon from '../Icon';

/**
 * AppBarComponent
 * @param {object} params
 * @param {object[]} params.notifications List of notifications
 * @param {function} params.onDismissAllClick Event fired when user clicks on Dismiss All button
 * @param {function} params.onDismissClick Event fired when user clicks on Dismiss button
 * @param {function} params.onViewNotificationClick Event fired when user clicks on view notification
 * @param {function} params.onLogoutClick Event fired when user clicks on logout
 */
const AppBarComponent = ({
  notifications = [],
  onDismissAllClick,
  onDismissClick,
  onViewNotificationClick,
  onLogoutClick,
  hideNotifications = false,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  /**
   * On Notification Click
   * @param {object} event Click Event
   */
  const onNotificationClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Handles Notification Close
   */
  const onNotificationsClose = () => {
    setAnchorEl(null);
  };

  /**
   * Handle Dismiss All Click
   */
  const handleDismissAllClick = () => {
    setAnchorEl(null);
    onDismissAllClick();
  };

  /**
   * Handle View Notification Click
   * @param {string} opportunityId Opportunity Id
   * @param {string} notificationUrl Notification Url
   */
  const handleViewNotificationClick = (opportunityId, notificationUrl) => {
    setAnchorEl(null);
    onViewNotificationClick(opportunityId, notificationUrl);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const notificationClasses = [classes.iconButton];

  if (open) {
    notificationClasses.push(classes.selectedIconBackground);
  }

  /**
   * renderNotifications
   * Renders Notification button and indicator
   */
  const renderNotifications = () => (
    <>
      <IconButton
        edge="end"
        color="inherit"
        aria-label="menu"
        className={clsx(notificationClasses)}
        onClick={onNotificationClick}
        data-testid="notification-button-component"
      >
        <img src={images.iconBellWhite} alt="alerts" />
      </IconButton>
      <div className={classes.countIndicator}>{notifications.length}</div>
    </>
  );

  return (
    <AppBar position="fixed" className={classes.appBar} elevation={0} data-testid="app-bar-component">
      <Toolbar className={classes.toolBar} data-testid="tool-bar-component">
        <img width="142" height="42" src={images.logoWhiteSM} alt="EnTech Logo" />
        <div className={classes.spacing} />
        {!hideNotifications && renderNotifications()}
        <Icon src={images.iconLogout} onClick={onLogoutClick} toolTipMessage={messages.logoutToolTip} alt={messages.logoutToolTip} />
        <Popover
          key={id}
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={onNotificationsClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          data-testid="popover-component"
        >
          {notifications.map((notification) => (
            <NotificationRow
              key={notification.notificationUserId}
              title={notification.title}
              description={notification.message}
              onViewClick={() => handleViewNotificationClick(notification.externalId, notification.viewUrl)}
              onDismissClick={() => onDismissClick(notification.notificationUserId)}
              whenHappened={notification.whenHappened}
            />
          ))}

          {notifications.length === 0 && (
            <div className={classes.noNotificationsWrapper}>
              <img src={images.emptyEnvelop} alt="" />
              <Typography className={classes.noNewNotificationsText}>
                <FormattedMessage {...messages.noNewNotifications} />
              </Typography>
            </div>
          )}

          {notifications.length > 0 && (
            <div className={classes.dismissAllWrapper}>
              <Typography>
                <Link onClick={handleDismissAllClick} className={classes.dismissAllButton} data-testid="dismiss-all-button">
                  <FormattedMessage {...messages.dismissAll} />
                </Link>
              </Typography>
            </div>
          )}
        </Popover>
      </Toolbar>
    </AppBar>
  );
};

AppBarComponent.propTypes = {
  notifications: PropTypes.array,
  onDismissAllClick: PropTypes.func,
  onDismissClick: PropTypes.func,
  onViewNotificationClick: PropTypes.func,
  onLogoutClick: PropTypes.func,
  hideNotifications: PropTypes.bool,
};

export default AppBarComponent;
