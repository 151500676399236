/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/function-component-definition */
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import useStyles from './styles';
import images from '../../../assets/images';

const NotificationRow = ({ title, description, onDismissClick, onViewClick, whenHappened }) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.container}>
        <img src={images.notificationRowBellIcon} alt="notification" className={classes.bellIcon} />
        <div className={classes.bodyContainer}>
          <Typography variant="caption" className={classes.captionBold}>
            {title}
          </Typography>
          <Typography variant="caption" className={classes.notificationDescription}>
            {description}
          </Typography>
          <div className={classes.actionRow}>
            <Typography variant="caption">{whenHappened}</Typography>
            <div className={classes.spacing} />
            <Button className={classes.notifyButton} onClick={onDismissClick} data-testid="dismiss-button">
              <FormattedMessage {...messages.dismiss} />
            </Button>
            <Button className={classes.notifyButton} color="primary" onClick={onViewClick} data-testid="view-button">
              <FormattedMessage {...messages.view} />
            </Button>
          </div>
        </div>
      </div>
      <div className={classes.separator} />
    </div>
  );
};

NotificationRow.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onDismissClick: PropTypes.func,
  onViewClick: PropTypes.func,
  whenHappened: PropTypes.string,
};

export default NotificationRow;
