import { Colors } from '../../theme';

const styles = {
  bodyWrapper: {
    display: 'flex',
    position: 'absolute',
    left: 82,
    top: 56,
    bottom: 0,
    right: 0,
  },
  buttonsModal: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
  },
  cancelModal: {
    color: Colors.mountainMeadow,
    cursor: 'pointer',
    textDecoration: 'underline',
    paddingRight: '1.25rem',
  },
};

export default styles;
