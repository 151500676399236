/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/**
 *
 * HeaderQuickConfiguration.js
 * Header content for quick configuration
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import messages from '../messages';
import Button from '../../Button';
import Input from '../../Input';
import images from '../../../assets/images';
import useStyles from './styles';

/**
 * HeaderQuickConfiguration
 * @param {string} title Title to show
 * @param {string} searchText Search Text
 * @param {function} onSearchTextChange Event to execute when search text value changes
 * @param {function} onCreateNew Event to execute when clicking the new button
 */
const HeaderQuickConfiguration = ({ title = '', onSearchTextChange = () => ({}), onCreateNew }) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = React.useState('');

  React.useEffect(() => {
    onSearchTextChange(searchTerm);
  }, [searchTerm]);

  /**
   * handleCreateNewClick
   * Handle Create New Quick Configuration Click Event
   * @param {object} event Create new event
   */
  const handleCreateNewClick = (event) => {
    onCreateNew(event);
  };

  return (
    <>
      <Box className={classes.upperWrapper}>
        <Box className={classes.titleWrapper}>
          <Typography variant="h2" className={classes.leftSeparator}>
            {title}
          </Typography>
        </Box>
        <Box className={classes.controlsWrapper}>
          <FormattedMessage {...messages.search}>
            {(placeholder) => (
              <Input
                data-testid="header-search-input"
                className={classes.searchInput}
                placeholder={placeholder}
                icon={images.searchIcon}
                value={searchTerm}
                onChange={(evt) => setSearchTerm(evt.target.value)}
                endAdornment={
                  <InputAdornment>
                    <IconButton
                      onClick={() => {
                        setSearchTerm('');
                      }}
                    >
                      <img className={classes.icon} src={images.clearIcon} alt={placeholder} id="icon" />
                    </IconButton>
                  </InputAdornment>
                }
              />
            )}
          </FormattedMessage>
          <div className={classes.buttonWrapper}>
            <Button data-testid="create-new-quick-config" secondary handleRoute={handleCreateNewClick}>
              <FormattedMessage {...messages.createNew} />
            </Button>
          </div>
        </Box>
      </Box>
    </>
  );
};

HeaderQuickConfiguration.propTypes = {
  title: PropTypes.string,
  onCreateNew: PropTypes.func,
  searchText: PropTypes.string,
  onSearchTextChange: PropTypes.func,
};

export default HeaderQuickConfiguration;
