/* eslint-disable react/require-default-props */
/* eslint-disable react/function-component-definition */
import React from 'react';
import PropTypes from 'prop-types';
import Switch from '@mui/material/Switch';
import useStyles from './styles';

const SimpleSwitch = (props) => {
  const classes = useStyles();
  const { disabled = false, onClick, checked } = props;

  const handleChange = () => {
    onClick();
  };

  const enterKeyHandler = (e) => {
    if (e.keyCode === 13) {
      onClick();
    }
  };

  return (
    <Switch
      onKeyDown={(e) => enterKeyHandler(e)}
      focusVisibleClassName=".Mui-focusVisible"
      data-testid="simple-switch-component"
      className={[classes.switch, disabled ? classes.disabled : '']}
      checked={checked}
      onClick={handleChange}
      disabled={disabled}
    />
  );
};

SimpleSwitch.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
};

export default SimpleSwitch;
