/* eslint-disable default-param-last */
import * as actionTypes from './types';

const INITIAL_STATE = {
  quickConfigurations: null,
  quickConfiguration: null,
  activeOpportunities: null,
  isBusy: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Quick Configurations __________________
    case actionTypes.GET_QUICK_CONFIGURATIONS_START:
      return { ...state, isBusy: true };
    case actionTypes.GET_QUICK_CONFIGURATIONS_SUCCESS:
      return { ...state, isBusy: false, quickConfigurations: action.payload };
    case actionTypes.GET_QUICK_CONFIGURATIONS_FAIL:
      return { ...state, isBusy: false, quickConfigurations: null };
    case actionTypes.POST_QUICK_CONFIGURATIONS_START:
      return { ...state, isBusy: true };
    case actionTypes.POST_QUICK_CONFIGURATIONS_SUCCESS:
      return { ...state, isBusy: false, quickConfiguration: action.payload };
    case actionTypes.POST_QUICK_CONFIGURATIONS_FAIL:
      return { ...state, isBusy: false, quickConfiguration: null };
    case actionTypes.DELETE_QUICK_CONFIGURATIONS_START:
      return { ...state, isBusy: true };
    case actionTypes.DELETE_QUICK_CONFIGURATIONS_SUCCESS:
      return { ...state, isBusy: false };
    case actionTypes.DELETE_QUICK_CONFIGURATIONS_FAIL:
      return { ...state, isBusy: false };
    case actionTypes.PATCH_QUICK_CONFIGURATIONS_START:
      return { ...state, isBusy: true };
    case actionTypes.PATCH_QUICK_CONFIGURATIONS_SUCCESS:
      return { ...state, isBusy: false, quickConfiguration: action.payload };
    case actionTypes.PATCH_QUICK_CONFIGURATIONS_FAIL:
      return { ...state, isBusy: false, quickConfiguration: null };
    case actionTypes.GET_ACTIVE_OPPORTUNITIES_START:
      return { ...state, isBusy: true };
    case actionTypes.GET_ACTIVE_OPPORTUNITIES_SUCCESS:
      return { ...state, isBusy: false, activeOpportunities: action.payload };
    case actionTypes.GET_ACTIVE_OPPORTUNITIES_FAIL:
      return { ...state, isBusy: false, activeOpportunities: null };

    default:
      return state;
  }
};
