const useStyles = (theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: 82,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 82,
  },
  drawerContainer: {
    overflow: 'hidden',
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  spacing: {
    flexGrow: 1,
  },
  itemIcon: {
    justifyContent: 'center',
  },
  listItem: {
    height: 48,
    padding: 0,
    justifyContent: 'center',
    '&.Mui-selected': {
      background: '#d0eedd',
    },
  },
  selectedStyle: {
    position: 'absolute',
    width: 4,
    backgroundColor: '#20c161',
    top: 0,
    bottom: 0,
    right: 0,
  },
  avatarContainer: {
    marginLeft: '0.4rem',
  },
});

export default useStyles;
