/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
import axios from 'axios';
import * as actionTypes from './types';
import envVars from '../../../utils/envVars';

const { doLog } = envVars;
const logTag = '[AuthActions]';
const componentManagerServiceUrl = `${envVars.domainName}/feat-configuration-service/`;

/**
 * getComponents
 * @param {string} componentType
 * @returns an component array
 */
export const getComponents = (componentType) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: actionTypes.GET_COMPONENTS_START });
    axios
      .get(`${componentManagerServiceUrl}${componentType}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: actionTypes.GET_COMPONENTS_SUCCESS,
            payload: { components: response.data, componentType, length: response.data.length },
          });
          resolve(response.data);
        } else {
          dispatch({ type: actionTypes.GET_COMPONENTS_FAIL });
          reject(response);
        }
      })
      .catch((error) => {
        dispatch({ type: actionTypes.GET_COMPONENTS_FAIL });
        reject(error);
      });
  });

/**
 * New component
 * @param {string} s
 */
export const postComponent = (newComponentData, componentType) => {
  doLog && console.log(logTag, '[postComponent]', componentType, newComponentData);

  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: actionTypes.PATCH_COMPONENT_START });

      axios
        .post(`${componentManagerServiceUrl}${componentType}`, newComponentData)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            dispatch({
              type: actionTypes.PATCH_COMPONENT_SUCCESS,
              payload: response.data,
            });
            resolve(response.data);
          } else {
            dispatch({ type: actionTypes.PATCH_COMPONENT_FAIL });
            reject(response);
          }
        })
        .catch((error) => {
          dispatch({ type: actionTypes.PATCH_COMPONENT_FAIL });
          reject(error);
        });
    });
};

/**
 * Update component
 * @param {string} s
 */
export const patchComponent = (newComponentData, componentType) => {
  doLog && console.log(logTag, '[patchComponent]', componentType, newComponentData);

  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: actionTypes.PATCH_COMPONENT_START });

      axios
        .patch(`${componentManagerServiceUrl}${componentType}/${newComponentData.componentId}`, newComponentData)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            dispatch({
              type: actionTypes.PATCH_COMPONENT_SUCCESS,
              payload: response.data,
            });
            resolve(response.data);
          } else {
            dispatch({ type: actionTypes.PATCH_COMPONENT_FAIL });
            reject(response);
          }
        })
        .catch((error) => {
          dispatch({ type: actionTypes.PATCH_COMPONENT_FAIL });
          reject(error);
        });
    });
};
