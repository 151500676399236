export const GET_COMPONENTS_START = 'GET_COMPONENTS_START';
export const GET_COMPONENTS_SUCCESS = 'GET_COMPONENTS_SUCCESS';
export const GET_COMPONENTS_FAIL = 'GET_COMPONENTS_FAIL';

export const PATCH_COMPONENT_START = 'PATCH_COMPONENT_START';
export const PATCH_COMPONENT_SUCCESS = 'PATCH_COMPONENT_SUCCESS';
export const PATCH_COMPONENT_FAIL = 'PATCH_COMPONENT_FAIL';

export const DELETE_COMPONENT_START = 'DELETE_COMPONENT_START';
export const DELETE_COMPONENT_SUCCESS = 'DELETE_COMPONENT_SUCCESS';
export const DELETE_COMPONENT_FAIL = 'DELETE_COMPONENT_FAIL';
