import { makeStyles } from '@mui/styles';
import { Colors } from '../../theme';

export default makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    objectFit: 'contain',
    backgroundColor: Colors.white,
  },
});
