/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
import axios from 'axios';
import * as actionTypes from './types';
import envVars from '../../utils/envVars';

const { doLog } = envVars;
const logTag = '[AppActions]';
const featNotificationServiceUrl = `${envVars.domainName}/feat-notification-service`;
const featOpportunityServiceUrl = `${envVars.domainName}/feat-opportunity-service`;

export const setAuthenticatedUser = (user) => ({
  type: actionTypes.SET_USER,
  payload: user,
});

export const clearAuthenticatedUser = () => ({ type: actionTypes.CLEAR_USER });

export const getUserGroups = () => {
  doLog && console.log(logTag, '[getUserGroups]');

  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch({ type: actionTypes.GET_USER_GROUPS_START });

      const { user } = getState().auth;
      const username = user.username.split('@')[0];

      axios
        .get(`${featOpportunityServiceUrl}/users/${username}`)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: actionTypes.GET_USER_GROUPS_SUCCESS,
              payload: response.data.userGroup,
            });
            resolve(response.data.data);
          } else {
            dispatch({ type: actionTypes.GET_USER_GROUPS_FAIL });
            reject(response);
          }
        })
        .catch((error) => {
          dispatch({ type: actionTypes.GET_USER_GROUPS_FAIL });
          reject(error);
        });
    });
};

/**
 * getNotifications
 * Get Notifications of a given username
 * @param {string} userName User Name
 * @returns Array of notifications of a given User Name
 */
export const getNotifications = (userName) => {
  doLog && console.log(logTag, '[getNotifications]', userName);

  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: actionTypes.GET_NOTIFICATIONS_START });

      axios
        .get(`${featNotificationServiceUrl}/notifications/${userName}`)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: actionTypes.GET_NOTIFICATIONS_SUCCESS,
              payload: response.data.data,
            });
            resolve(response.data.data);
          } else {
            dispatch({ type: actionTypes.GET_NOTIFICATIONS_FAIL });
            reject(response);
          }
        })
        .catch((error) => {
          dispatch({ type: actionTypes.GET_NOTIFICATIONS_FAIL });
          reject(error);
        });
    });
};

/**
 * dismissAllNotifications
 * Dismisses all notifications for a given username
 * @param {string} userName User Name
 */
export const dismissAllNotifications = (userName) => {
  doLog && console.log(logTag, '[dismissAllNotifications]');

  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: actionTypes.DISMISS_ALL_NOTIFICATIONS_START });

      axios
        .patch(`${featNotificationServiceUrl}/notifications/${userName}`)
        .then((response) => {
          if (response.status === 200) {
            dispatch({ type: actionTypes.DISMISS_ALL_NOTIFICATIONS_SUCCESS });

            resolve(response.data);
          } else {
            dispatch({ type: actionTypes.DISMISS_ALL_NOTIFICATIONS_FAIL });
            reject(response);
          }
        })
        .catch((error) => {
          dispatch({ type: actionTypes.DISMISS_ALL_NOTIFICATIONS_FAIL });
          reject(error);
        });
    });
};

/**
 * dismissSingleNotification
 * Dismisses single notification for a given username
 * @param {string} notificationId Notification Id
 */
export const dismissSingleNotification = (notificationId) => {
  doLog && console.log(logTag, '[dismissSingleNotification]', notificationId);

  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: actionTypes.DISMISS_SINGLE_NOTIFICATION_START });

      axios
        .patch(`${featNotificationServiceUrl}/notificationUserId/${notificationId}/dismiss`)
        .then((response) => {
          if (response.status === 200) {
            dispatch({ type: actionTypes.DISMISS_SINGLE_NOTIFICATION_SUCCESS });
            resolve(response.data.data);
          } else {
            dispatch({ type: actionTypes.DISMISS_SINGLE_NOTIFICATION_FAIL });
            reject(response);
          }
        })
        .catch((error) => {
          dispatch({ type: actionTypes.DISMISS_SINGLE_NOTIFICATION_FAIL });
          reject(error);
        });
    });
};

/**
 * viewSingleNotification
 * View single notification for a given username
 * @param {string} notificationUrl Notification URL
 */
export const viewSingleNotification = (notificationUrl) => {
  doLog && console.log(logTag, '[viewSingleNotification]', notificationUrl);

  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: actionTypes.VIEW_SINGLE_NOTIFICATION_START });

      axios
        .get(`${featOpportunityServiceUrl}${notificationUrl}`)
        .then((response) => {
          if (response.status === 200) {
            dispatch({ type: actionTypes.VIEW_SINGLE_NOTIFICATION_SUCCESS });
            resolve(response.data.data);
          } else {
            dispatch({ type: actionTypes.VIEW_SINGLE_NOTIFICATION_FAIL });
            reject(response);
          }
        })
        .catch((error) => {
          dispatch({ type: actionTypes.VIEW_SINGLE_NOTIFICATION_FAIL });
          reject(error);
        });
    });
};

/**
 * getUserGroupList
 * Get the valid user group list for accessing the application
 */
export const getUserGroupList = () => {
  doLog && console.log(logTag, '[getUserGroupList]');

  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: actionTypes.GET_USER_GROUP_LIST_START });

      axios
        .get(`${featOpportunityServiceUrl}/usersgroups`)
        .then((response) => {
          if (response.status === 200) {
            dispatch({ type: actionTypes.GET_USER_GROUP_LIST_SUCCESS, payload: response.data });
            resolve(response.data);
          } else {
            dispatch({ type: actionTypes.GET_USER_GROUP_LIST_FAIL });
            reject(response);
          }
        })
        .catch((error) => {
          dispatch({ type: actionTypes.GET_USER_GROUP_LIST_FAIL });
          reject(error);
        });
    });
};
