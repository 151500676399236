export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleWrapper: {
    display: 'flex',
    flex: 1,
    height: '2.5rem',
    alignItems: 'center',
  },
  title: {
    flex: 1,
  },
  errorWrapper: {
    height: '2rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  indentLabel: {
    paddingLeft: '2.625rem',
  },
};
