import { makeStyles } from '@mui/styles';

export default makeStyles({
  icon: {
    width: '1.5rem',
    height: '1.5rem',
  },
  adornment: {
    marginLeft: '0.5rem',
  },
});
