/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../messages';
import Input from '../../../../components/Input';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';
import TwoColumnRow from '../../../../components/TwoColumnRow';
import AlertModal from '../../../../components/AlertModal';
import useStyles from './styles';
import { formatThousandsNumber, validateDecimalValue, decimalPercentageToPercentage } from '../../../../utils/stringFormatter';
import { setValueTextClass } from '../../utilities';

const REQUIRED_ERROR = 'required';
const RANGE_ERROR = 'range';
const PERCENTAGE_SYMBOL = '%';
const KW = 'kW';
const DOLLAR_SYMBOL = '$';

const MANUFACTURER = 'MANUFACTURER';
const PART_NUMBER = 'PART_NUMBER';
const POWER_RATING = 'POWER_RATING';
const INVERTER_EFFICIENCY = 'INVERTER_EFFICIENCY';
const STATIC_LOSS = 'STATIC_LOSS';
const UNIT_COST = 'UNIT_COST';
const INSTALLATION_COST = 'INSTALLATION_COST';

class DCACInverterModal extends Component {
  constructor() {
    super();
    this.state = {
      id: '',
      isCancelModalVisible: false,

      manufacturer: '',
      isManufacturerValid: true,
      manufacturerErrorMessage: '',
      hasManufacturerBeenTouched: false,

      partNumber: '',
      isPartNumberValid: true,
      partNumberErrorMessage: '',
      hasPartNumberBeenTouched: false,

      powerRating: '',
      isPowerRatingValid: true,
      powerRatingErrorMessage: '',
      hasPowerRatingBeenTouched: false,

      inverterEfficiency: '',
      isInverterEfficiencyValid: true,
      inverterEfficiencyErrorMessage: '',
      hasInverterEfficiencyBeenTouched: false,

      staticLoss: '',
      isStaticLossValid: true,
      staticLossErrorMessage: '',
      hasStaticLossBeenTouched: false,

      unitCost: '',
      isUnitCostValid: true,
      unitCostErrorMessage: '',
      hasUnitCostBeenTouched: false,

      installationCost: '',
      isInstallationCostValid: true,
      installationCostErrorMessage: '',
      hasInstallationCostBeenTouched: false,
    };
  }

  componentDidMount() {
    const { defaultData, readonly } = this.props;
    this.setState({
      id: `${defaultData?.componentId ?? ''}`,
      manufacturer: `${defaultData?.manufacturer ?? ''}`,
      partNumber: `${defaultData?.partNumber ?? ''}`,
      powerRating: `${defaultData?.powerRating ?? ''}`,
      staticLoss: `${defaultData?.staticLoss || '0'}`,
      unitCost: `${defaultData?.unitCost ?? ''}`,
      installationCost: `${defaultData?.installationCost ?? ''}`,
    });

    if (readonly) {
      this.setState({ inverterEfficiency: `${decimalPercentageToPercentage(defaultData?.efficiency) ?? ''}` });
    } else {
      this.setState({ inverterEfficiency: `${defaultData?.efficiency ?? ''}` });
    }
  }

  componentDidUpdate(prevProps) {
    this.validateChanges(prevProps);
  }

  /**
   * validateChanges - validate if the component receive new props and parse them to this state
   * @param {*} prevProps previous props from componentDidUpdate
   */
  validateChanges = (prevProps) => {
    const currentDefaultData = this.props.defaultData;
    const prevDefaultData = prevProps.defaultData;
    if (currentDefaultData && JSON.stringify({ ...currentDefaultData }) !== JSON.stringify({ ...prevDefaultData })) {
      this.setState({
        id: `${currentDefaultData.id || ''}`,
        manufacturer: `${currentDefaultData.manufacturer || ''}`,
        partNumber: `${currentDefaultData.partNumber || ''}`,
        powerRating: `${currentDefaultData.powerRating || ''}`,
        inverterEfficiency: `${currentDefaultData.inverterEfficiency || ''}`,
        staticLoss: `${currentDefaultData.staticLoss || ''}`,
        unitCost: `${currentDefaultData.unitCost || ''}`,
        installationCost: `${currentDefaultData.installationCost || ''}`,
        isActive: currentDefaultData.isActive,
      });
    }
  };

  /**
   *  resetForm
   *  resets form
   */
  resetForm = () => {
    this.setState({
      manufacturer: '',
      isManufacturerValid: true,
      manufacturerErrorMessage: '',
      hasManufacturerBeenTouched: false,
      partNumber: '',
      isPartNumberValid: true,
      partNumberErrorMessage: '',
      hasPartNumberBeenTouched: false,
      powerRating: '',
      isPowerRatingValid: false,
      powerRatingErrorMessage: '',
      hasPowerRatingBeenTouched: false,
      inverterEfficiency: '',
      isInverterEfficiencyValid: true,
      inverterEfficiencyErrorMessage: '',
      hasInverterEfficiencyBeenTouched: false,
      staticLoss: '0',
      isStaticLossValid: true,
      staticLossErrorMessage: '',
      hasStaticLossBeenTouched: false,
      unitCost: '',
      isUnitCostValid: true,
      unitCostErrorMessage: '',
      hasUnitCostBeenTouched: false,
      installationCost: '',
      isInstallationCostValid: true,
      installationCostErrorMessage: '',
      hasInstallationCostBeenTouched: false,
    });
  };

  /*
   * validateField
   * Validates a field
   * @param {string} key Field type
   * @param {string} value Value to validate
   */
  validateField = (key, value) => {
    let isValidValue;
    let validation;
    let errorMessage;
    let numberValue;
    const cleanValue = value.toString().trim();
    switch (key) {
      case MANUFACTURER:
        isValidValue = cleanValue !== '' && cleanValue.length > 0;
        if (!isValidValue) {
          errorMessage = REQUIRED_ERROR;
        }
        this.setState({ isManufacturerValid: isValidValue, manufacturerErrorMessage: errorMessage });
        break;

      case PART_NUMBER:
        isValidValue = cleanValue !== '' && cleanValue.length > 0;
        if (!isValidValue) {
          errorMessage = REQUIRED_ERROR;
        }
        this.setState({ isPartNumberValid: isValidValue, partNumberErrorMessage: errorMessage });
        break;

      case POWER_RATING:
        numberValue = parseFloat(cleanValue.replace(/,/g, ''));
        isValidValue = cleanValue.length > 0 && numberValue >= 0.1 && numberValue <= 9999.0;
        this.setState({
          isPowerRatingValid: isValidValue,
          powerRatingErrorMessage: cleanValue.length === 0 ? REQUIRED_ERROR : RANGE_ERROR,
        });
        break;

      case INVERTER_EFFICIENCY:
        numberValue = parseFloat(cleanValue.replace(/,/g, ''));
        isValidValue = cleanValue.length > 0 && numberValue >= 0.1 && numberValue <= 100.0;
        this.setState({
          isInverterEfficiencyValid: isValidValue,
          inverterEfficiencyErrorMessage: cleanValue.length === 0 ? REQUIRED_ERROR : RANGE_ERROR,
        });
        break;

      case STATIC_LOSS:
        numberValue = parseFloat(cleanValue.replace(/,/g, ''));
        isValidValue = cleanValue.length > 0 && numberValue >= 0 && numberValue <= 9999.0;
        this.setState({ isStaticLossValid: isValidValue, staticLossErrorMessage: cleanValue.length === 0 ? REQUIRED_ERROR : RANGE_ERROR });
        break;

      case UNIT_COST:
        validation = this.validateNumericValue(cleanValue, 999999, true);
        this.setState({ isUnitCostValid: validation.isValid, unitCostErrorMessage: validation.errorType });
        break;

      case INSTALLATION_COST:
        validation = this.validateNumericValue(cleanValue, 999999, true);
        this.setState({ isInstallationCostValid: validation.isValid, installationCostErrorMessage: validation.errorType });
        break;

      default:
        break;
    }
  };

  /*
   * validateNumericValue
   * Validates a numeric value
   * @param {string} value Value to validate
   * @param {boolean} hasBeenTouched Flag to determine if the input have ever been focused
   * return object
   */
  validateNumericValue = (value, maxValue, hasBeenTouched) => {
    const stringValue = `${value.replace(/[^\d]/g, '')}`;
    let errorType = '';
    let isValid = true;
    if (hasBeenTouched) {
      const isInputEmpty = stringValue.length === 0;
      errorType = isInputEmpty ? REQUIRED_ERROR : '';
      isValid = !isInputEmpty;
      if (!isInputEmpty) {
        const numberValue = parseInt(stringValue.replace(/,/g, ''), 10);
        if (numberValue > maxValue) {
          isValid = false;
          errorType = RANGE_ERROR;
        }
      }
    }
    return { errorType, isValid };
  };

  /*
   * hasChanges
   * Flag to determine if there are any changes in the form
   * return boolean
   */
  hasChanges = () => {
    const { defaultData } = this.props;
    const { manufacturer, partNumber, powerRating, inverterEfficiency, staticLoss, unitCost, installationCost, id } = this.state;
    const currentData = {
      manufacturer,
      partNumber,
      powerRating,
      inverterEfficiency,
      staticLoss,
      unitCost,
      installationCost,
    };
    const propsData = {
      manufacturer: `${defaultData?.manufacturer}`,
      partNumber: `${defaultData?.partNumber}`,
      powerRating: `${defaultData?.powerRating}`,
      inverterEfficiency: `${defaultData?.inverterEfficiency}`,
      staticLoss: `${defaultData?.staticLoss}`,
      unitCost: `${defaultData?.unitCost}`,
      installationCost: `${defaultData?.installationCost}`,
    };
    let hasChanges = false;
    if (id) {
      hasChanges = JSON.stringify({ ...currentData }) !== JSON.stringify({ ...propsData });
    } else {
      hasChanges =
        manufacturer.length > 0 ||
        partNumber.length > 0 ||
        powerRating.toString().length > 0 ||
        inverterEfficiency.toString().length > 0 ||
        staticLoss.toString().length > 0 ||
        unitCost.length > 0 ||
        installationCost.length > 0;
    }
    return hasChanges;
  };

  /*
   * enableSaveButton
   * Enables or disables the save button if the form has changes or the values are valid
   * return boolean
   */
  enableSaveButton = () => {
    const {
      isManufacturerValid,
      isPartNumberValid,
      isPowerRatingValid,
      isInverterEfficiencyValid,
      isStaticLossValid,
      isUnitCostValid,
      isInstallationCostValid,

      manufacturer = '',
      partNumber = '',
      powerRating,
      inverterEfficiency,
      staticLoss,
      unitCost,
      installationCost,
    } = this.state;

    const isSaveButtonEnabled =
      this.hasChanges() &&
      isManufacturerValid &&
      isPartNumberValid &&
      isPowerRatingValid &&
      isInverterEfficiencyValid &&
      isStaticLossValid &&
      isUnitCostValid &&
      isInstallationCostValid &&
      manufacturer.length > 0 &&
      partNumber.length > 0 &&
      powerRating.toString().length > 0 &&
      inverterEfficiency.toString().length > 0 &&
      staticLoss.toString().length > 0 &&
      unitCost.length > 0 &&
      installationCost.length > 0;

    return isSaveButtonEnabled;
  };

  /**
   * handleSaveClick
   * handle the click event when the user clicks on save button inside createNewModal component
   */
  handleSaveClick = () => {
    const { id, manufacturer, partNumber, powerRating, inverterEfficiency, staticLoss, unitCost, installationCost, isActive } = this.state;

    const newComponentData = {
      componentId: id,
      manufacturer,
      partNumber,
      efficiency: Number(inverterEfficiency.toString().replace(/,/g, '')) / 100,
      powerRating: Number(powerRating.toString().replace(/,/g, '')),
      staticLoss: Number(staticLoss.toString().replace(/,/g, '')),
      unitCost: Number(unitCost.replace(/,/g, '')),
      installationCost: Number(installationCost.replace(/,/g, '')),
      isActive: isActive || true,
    };

    this.resetForm();
    this.props.onAccept(newComponentData);
  };

  /*
   * handleInputChange
   * Handles the inputs change event
   * @param {string} eventt Event object
   */
  handleInputChange = (event) => {
    let formattedValue;
    let cleanNumber;
    const { inverterEfficiency, powerRating, staticLoss } = this.state;
    const { value, name } = event.target;
    switch (name) {
      case MANUFACTURER:
        this.validateField(MANUFACTURER, value);
        this.setState({ manufacturer: value, hasManufacturerBeenTouched: true });
        break;

      case PART_NUMBER:
        this.validateField(PART_NUMBER, value);
        this.setState({ partNumber: value, hasPartNumberBeenTouched: true });
        break;

      case POWER_RATING:
        cleanNumber = value.replace(/,/g, '');
        formattedValue = validateDecimalValue(cleanNumber, 1) ? value : powerRating;
        this.validateField(POWER_RATING, formattedValue);
        this.setState({ powerRating: formattedValue, hasPowerRatingBeenTouched: true });
        break;

      case INVERTER_EFFICIENCY:
        cleanNumber = value.replace(/,/g, '');
        formattedValue = validateDecimalValue(cleanNumber, 1) ? value : inverterEfficiency;
        this.validateField(INVERTER_EFFICIENCY, formattedValue);
        this.setState({ inverterEfficiency: formattedValue, hasInverterEfficiencyBeenTouched: true });
        break;

      case STATIC_LOSS:
        formattedValue = validateDecimalValue(value.replace(/,/g, ''), 3) ? value : staticLoss;
        this.validateField(STATIC_LOSS, formattedValue);
        this.setState({ staticLoss: formattedValue, hasStaticLossBeenTouched: true });
        break;

      case UNIT_COST:
        formattedValue = `${value.replace(/[^\d]/g, '')}`;
        this.validateField(UNIT_COST, formattedValue);
        this.setState({ unitCost: formattedValue, hasUnitCostBeenTouched: true });
        break;

      case INSTALLATION_COST:
        formattedValue = `${value.replace(/[^\d]/g, '')}`;
        this.validateField(INSTALLATION_COST, formattedValue);
        this.setState({ installationCost: formattedValue, hasInstallationCostBeenTouched: true });
        break;

      default:
        break;
    }
  };

  /*
   * handleInputBlur
   * Handles the input blur event
   * @param {string} fieldName Name of the field
   */
  handleInputBlur = (fieldName) => {
    const { inverterEfficiency, unitCost, installationCost, powerRating, staticLoss, manufacturer, partNumber } = this.state;
    let formattedValue;
    switch (fieldName) {
      case UNIT_COST:
        this.setState({ unitCost: unitCost.length > 0 ? parseInt(unitCost.toString().replace(/,/g, ''), 10).toString() : '' });
        this.validateField(UNIT_COST, unitCost);
        break;

      case POWER_RATING:
        formattedValue = powerRating.toString().length > 0 ? parseFloat(powerRating.toString().replace(/,/g, '')) : '';
        this.setState({ powerRating: formattedValue });
        this.validateField(POWER_RATING, formattedValue);
        break;

      case INSTALLATION_COST:
        this.setState({
          installationCost: installationCost.length > 0 ? parseInt(installationCost.toString().replace(/,/g, ''), 10).toString() : '',
        });
        this.validateField(INSTALLATION_COST, installationCost);
        break;

      case INVERTER_EFFICIENCY:
        formattedValue = inverterEfficiency.toString().length > 0 ? parseFloat(inverterEfficiency.toString().replace(/,/g, '')) : '';
        this.setState({ inverterEfficiency: formattedValue });
        this.validateField(INVERTER_EFFICIENCY, formattedValue);
        break;

      case STATIC_LOSS:
        formattedValue = staticLoss.toString().length > 0 ? parseFloat(staticLoss.toString().replace(/,/g, '')) : '';
        this.setState({ staticLoss: formattedValue });
        this.validateField(STATIC_LOSS, formattedValue);
        break;

      case MANUFACTURER:
        this.setState({ manufacturer: manufacturer.length > 0 ? manufacturer : '' });
        this.validateField(MANUFACTURER, manufacturer);
        break;

      case PART_NUMBER:
        this.setState({ partNumber: partNumber.length > 0 ? partNumber : '' });
        this.validateField(PART_NUMBER, partNumber);
        break;

      default:
        break;
    }
  };

  /**
   * handleCancelChanges
   * handles when the user cancel the creation or edition of a component
   */
  handleCancelChanges = () => {
    this.resetForm();
    this.setState({ isCancelModalVisible: false, defaultData: null });
    this.props.onCancel();
  };

  /**
   * handleContinueUpdating
   * handles when the user cancel the creation or edition of a component and the user cancel that action
   */
  handleContinueUpdating = () => {
    this.setState({ isCancelModalVisible: false });
  };

  /**
   * handleCreateNewDialogClose
   * handle the close event for form modal
   */
  handleCreateNewDialogClose = () => {
    if (this.hasChanges()) {
      this.setState({ isCancelModalVisible: true });
    } else {
      this.props.onCancel();
      this.resetForm();
    }
  };

  /*
   * renderInputField
   * Renders an input value
   * @param {string} key Field name
   * @param {string} title Name to display to the left of the field
   * @param {string} rightUnit Unit to display to the right of the input
   * @param {string} value Input value
   * @param {string} type Type of the field
   * @param {string} errorText Text to display under the input
   * @param {boolean} isValid Flag t determine if the field is valid and display the error message
   * @param {boolean} autoFocus Auto focus field flag
   * @param {boolean} isEnabled flag to determine if the control is enabled or not
   * @param {string} titleComplement string that complets the title if is the case
   * @param {string} leftUnit Unit to display to the left of the input
   * @param {boolean} small Flag to determine the sice of the input
   * @param {object} inputProps Extra props to add to the input
   */
  renderInputField = ({
    key,
    title,
    rightUnit,
    value,
    type,
    alignRight = true,
    errorText,
    isValid,
    autoFocus = false,
    isEnabled,
    titleComplement,
    leftUnit,
    small,
    inputProps,
  }) => {
    const { classes, readonly } = this.props;
    const errorMessage = this.renderErrorMessage(!isValid, errorText);
    const titleText = title ? <FormattedMessage {...title} values={{ type: titleComplement || '' }} /> : '';
    return (
      <TwoColumnRow title={titleText} error={errorMessage} key={key} testId={`two-column-${key}`}>
        <div className={classes.inputContainer}>
          {leftUnit && (
            <Typography color="primary" className={classes.currencyText}>
              {leftUnit}
            </Typography>
          )}
          <div className={setValueTextClass(rightUnit, leftUnit, small, classes, readonly)}>
            {readonly ? (
              value
            ) : (
              <Input
                autoFocus={autoFocus}
                invalid={!isValid}
                name={key}
                value={value}
                type={type}
                onChange={this.handleInputChange}
                alignRight={alignRight}
                onFocus={this.handleInputFocus}
                onBlur={() => this.handleInputBlur(key)}
                inputProps={inputProps}
                disabled={isEnabled}
              />
            )}
          </div>
          {rightUnit && (
            <Typography color="primary" variant="caption" className={`${classes.unitText} ${!readonly ? classes.notReadOnlyUnitText : ''}`}>
              {rightUnit}
            </Typography>
          )}
        </div>
      </TwoColumnRow>
    );
  };

  /*
   * renderErrorMessage
   * Renders the error message to display bellow each of the fields
   * @param {boolean} error Flag to display the error message
   * @param {string} message Message to display
   */
  renderErrorMessage(error, message) {
    if (error && message) {
      return (
        <Typography variant="caption" color="error">
          <FormattedMessage {...message} />
        </Typography>
      );
    }
    return null;
  }

  render() {
    const { classes, isModalVisible, readonly } = this.props;
    const {
      isCancelModalVisible,

      manufacturer,
      manufacturerErrorMessage,
      isManufacturerValid,

      partNumber,
      isPartNumberValid,
      partNumberErrorMessage,

      powerRating,
      isPowerRatingValid,
      powerRatingErrorMessage,

      inverterEfficiency,
      isInverterEfficiencyValid,
      inverterEfficiencyErrorMessage,

      staticLoss,
      isStaticLossValid,
      staticLossErrorMessage,

      unitCost,
      isUnitCostValid,
      unitCostErrorMessage,

      installationCost,
      isInstallationCostValid,
      installationCostErrorMessage,
    } = this.state;
    const modalTitle = readonly ? messages.viewTitleDCACBusInverter : messages.createNewTitleDCACBusInverter;
    const paperPropsStyle = { maxWidth: '30rem' };
    return (
      <>
        {isCancelModalVisible && (
          <AlertModal
            title={messages.alertCancelChangesTitle}
            message={messages.alertCancelChangesMessage}
            isVisible={isCancelModalVisible}
            acceptLabel={messages.alertYesLabel}
            cancelLabel={messages.alertNoLabel}
            onAccept={this.handleCancelChanges}
            onCancel={this.handleContinueUpdating}
            size="30rem"
          />
        )}
        <Modal
          open={isModalVisible}
          onClose={this.handleCreateNewModalClose}
          title={<FormattedMessage {...modalTitle} />}
          paperPropsStyle={paperPropsStyle}
        >
          <>
            {this.renderInputField({
              key: MANUFACTURER,
              title: messages.manufactureLabel,
              value: manufacturer,
              type: 'text',
              alignRight: false,
              errorText: manufacturerErrorMessage === REQUIRED_ERROR && messages.manufactureError,
              isValid: isManufacturerValid,
              inputProps: { maxLength: 200 },
            })}

            {this.renderInputField({
              key: PART_NUMBER,
              title: messages.partNumberLabel,
              value: partNumber,
              type: 'text',
              alignRight: false,
              errorText: partNumberErrorMessage === REQUIRED_ERROR ? messages.partNumberRequiredError : messages.partNumberFormatError,
              isValid: isPartNumberValid,
              inputProps: { maxLength: 100 },
            })}

            {this.renderInputField({
              key: POWER_RATING,
              title: messages.powerRatingLabel,
              value: formatThousandsNumber(powerRating),
              rightUnit: KW,
              type: 'text',
              errorText:
                powerRatingErrorMessage === REQUIRED_ERROR
                  ? messages.powerRatingRequiredErrorMessage
                  : messages.powerRatingPatternErrorMessage,
              isValid: isPowerRatingValid,
            })}

            {this.renderInputField({
              key: INVERTER_EFFICIENCY,
              title: messages.inverterEfficiencyLabel,
              value: formatThousandsNumber(inverterEfficiency),
              rightUnit: PERCENTAGE_SYMBOL,
              type: 'text',
              errorText:
                inverterEfficiencyErrorMessage === REQUIRED_ERROR
                  ? messages.inverterEfficiencyRequiredErrorMessage
                  : messages.inverterEfficiencyPatternErrorMessage,
              isValid: isInverterEfficiencyValid,
            })}

            {this.renderInputField({
              key: STATIC_LOSS,
              title: messages.staticLossLabel,
              value: formatThousandsNumber(staticLoss),
              rightUnit: KW,
              type: 'text',
              errorText:
                staticLossErrorMessage === REQUIRED_ERROR
                  ? messages.staticLossRequiredErrorMessage
                  : messages.staticLossPatternErrorMessage,
              isValid: isStaticLossValid,
            })}

            {this.renderInputField({
              key: UNIT_COST,
              title: messages.unitCostLabel,
              value: formatThousandsNumber(unitCost),
              type: 'text',
              errorText:
                unitCostErrorMessage === REQUIRED_ERROR ? messages.unitCostRequiredErrorMessage : messages.unitCostPatternErrorMessage,
              isValid: isUnitCostValid,
              leftUnit: DOLLAR_SYMBOL,
            })}

            {this.renderInputField({
              key: INSTALLATION_COST,
              title: messages.installationCostLabel,
              type: 'text',
              errorText:
                installationCostErrorMessage === REQUIRED_ERROR
                  ? messages.installationCostRequiredErrorMessage
                  : messages.installationCostPatternErrorMessage,
              isValid: isInstallationCostValid,
              leftUnit: DOLLAR_SYMBOL,
              value: formatThousandsNumber(installationCost),
            })}
          </>
          {!readonly && (
            <>
              <div className={classes.saveButton}>
                <Button
                  handleRoute={this.handleSaveClick}
                  disabled={!this.enableSaveButton()}
                  disableRipple={false}
                  data-testid="save-button"
                >
                  <FormattedMessage {...messages.save} />
                </Button>
              </div>
              <div className={classes.cancelButton}>
                <Button onClick={this.handleCreateNewDialogClose}>
                  <FormattedMessage {...messages.cancel} />
                </Button>
              </div>
            </>
          )}
          {readonly && (
            <div>
              <Button onClick={() => this.props.onCancel()}>
                <FormattedMessage {...messages.close} />
              </Button>
            </div>
          )}
        </Modal>
      </>
    );
  }
}

DCACInverterModal.propTypes = {
  classes: PropTypes.object,
  readonly: PropTypes.bool,
  onCancel: PropTypes.func,
  onAccept: PropTypes.func,
  isModalVisible: PropTypes.bool,
  defaultData: PropTypes.shape({
    id: PropTypes.string,
    manufacturer: PropTypes.string,
    partNumber: PropTypes.string,
    powerRating: PropTypes.number,
    inverterEfficiency: PropTypes.string,
    staticLoss: PropTypes.string,
    unitCost: PropTypes.number,
    installationCost: PropTypes.string,
  }),
  intl: PropTypes.object,
};

export default withStyles(useStyles)(injectIntl(DCACInverterModal));
