/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
import React from 'react';
import { Tooltip, Fade } from '@mui/material';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import IconButton from '@mui/material/IconButton';
import useStyles from './styles';

const Icon = ({ src, toolTipMessage, isDisabled = false, onClick, size = 'small', alt, name, dataTestid }) => {
  const classes = useStyles();
  const icon = (
    <IconButton data-testid={dataTestid} edge="end" color="inherit" aria-label="menu" size={size} onClick={onClick} disabled={isDisabled}>
      <img name={name} src={src} alt={<FormattedMessage {...alt} />} className={isDisabled && classes.disabledIcon} />
    </IconButton>
  );
  if (toolTipMessage) {
    return (
      <Tooltip title={<FormattedMessage {...toolTipMessage} />} arrow TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
        {icon}
      </Tooltip>
    );
  }

  return icon;
};

Icon.propTypes = {
  src: PropTypes.string,
  toolTipMessage: PropTypes.object,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.string,
  alt: PropTypes.object,
  name: PropTypes.string,
  dataTestid: PropTypes.string,
};

export default Icon;
