/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/function-component-definition */
/**
 *
 * HeaderTariffDetails.js
 * Header content for configuration
 */

import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from '../../Button';
import Breadcrumbs from '../../Breadcrumbs';
import images from '../../../assets/images';
import useStyles from './styles';
import messages from '../messages';

/**
 * HeaderTariffDetails
 * @param {string} title Title to show
 * @param {string} subtitle2 Subtitle to show in green format
 * @param {object} breadcrumbs Breadcrumbs to show
 * @param {string} masterTariffId Master Tariff Id
 * @param {string} tariffId  Tariff Id
 * @param {function} onSave Method to trigger then the save button is pressed
 * @param {function} onGoBack Method to trigger then the back icon is pressed
 * @param {Boolean} disableSaveButton Disable save button
 */
const HeaderTariffDetails = ({
  title = '',
  subtitle2 = '',
  masterTariffId = '',
  tariffId = '',
  breadcrumbs = [],
  onSave,
  onGoBack,
  disableSaveButton,
}) => {
  const classes = useStyles();

  const handleSaveTariffDetail = () => onSave();
  const handleGoBack = () => onGoBack();

  return (
    <div className={classes.wrapper}>
      <Box className={classes.chevronWrapper}>
        <button type="button" className={classes.button} onClick={handleGoBack}>
          <img alt="back" src={images.backChevron} />
        </button>
      </Box>
      <Box className={classes.upperWrapper}>
        <Box className={classes.titleWrapper}>
          <Box className={classes.statusWrapper}>
            <Typography variant="h2" className={classes.leftSeparator}>
              {title}
            </Typography>
          </Box>
          <Typography variant="caption" className={clsx([classes.leftSeparator, classes.overflowName])}>
            {subtitle2}
          </Typography>
          <Box className={classes.informationParentWrapper}>
            <Box className={classes.informationWrapper}>
              <Typography variant="caption" className={classes.leftSeparator}>
                <FormattedMessage {...messages.tariffMtId}>{(text) => text}</FormattedMessage> {masterTariffId}
              </Typography>
              <Typography variant="caption" className={classes.leftSeparator}>
                <FormattedMessage {...messages.tariffId}>{(text) => text}</FormattedMessage> {tariffId}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <div className={classes.lowerWrapper}>
        <div className={classes.actionsWrapper}>
          {onSave && (
            <div className={classes.saveButton}>
              <Button handleRoute={handleSaveTariffDetail} disabled={disableSaveButton}>
                <FormattedMessage {...messages.saveTitle}>{(text) => text}</FormattedMessage>
              </Button>
            </div>
          )}
          {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
        </div>
      </div>
    </div>
  );
};

HeaderTariffDetails.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle2: PropTypes.string.isRequired,
  masterTariffId: PropTypes.number.isRequired,
  tariffId: PropTypes.number.isRequired,
  breadcrumbs: PropTypes.array,
  onSave: PropTypes.func,
  onGoBack: PropTypes.func.isRequired,
  disableSaveButton: PropTypes.bool.isRequired,
};

export default injectIntl(HeaderTariffDetails);
