export const componentTypes = {
  ESS_BATTERY: 'ess-battery-component',
  ESS_INVERTER: 'ess-inverter-component',
  ESS_CONVERTER: 'ess-converter-component',
  SOLAR_INVERTER: 'solar-inverter-component',
  SOLAR_CONVERTER: 'solar-converter-component',
  ENGINE: 'engine-component',
  EV: 'ev-component',
  FLEET: 'fleet-component',
  ENCLOSURE: 'enclosures',
  ENCLOSURE_COMPONENT: 'enclosure-component-addon',
  DCAC_INVERTER: 'dcac-bus-inverter-component',
};

/**
 * setValueTextClass
 * Sets the class for container of a form input (if !readonly) or plain text (if readonly)
 * @param {boolean} rightUnit the input or text has a "unit" to it's right
 * @param {boolean} leftUnit the input or text has a "unit" to it's left
 * @param {boolean} small
 * @param {object} classes
 * @param {boolean} readonly
 * @returns a class name
 */
export const setValueTextClass = (rightUnit, leftUnit, small, classes, readonly) => {
  let className = '';
  if (!readonly && (rightUnit || leftUnit || small)) {
    className = classes.smallInputContainer;
  } else if (!readonly) {
    className = classes.regularInputContainer;
  }
  return className;
};
