import { makeStyles } from '@mui/styles';

export default makeStyles({
  leftSeparator: {
    marginLeft: '2rem',
  },
  searchInput: {
    width: '16.25rem',
    height: '2rem',
    marginRight: '1rem',
  },
  searchIcon: {
    width: '1.5rem',
    height: '1.5rem',
  },
  upperWrapper: {
    display: 'flex',
    flexDirection: 'row',
    height: '4.5rem',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
  },
  controlsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    objectFit: 'contain',
    marginRight: '1.75rem',
    marginLeft: 'auto',
    alignItems: 'center',
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonWrapper: {
    height: '2.5rem',
    width: '7.875rem',
    marginLeft: '1rem',
    marginRight: '1rem',
  },
});
