/* eslint-disable default-param-last */
/* eslint-disable no-case-declarations */
import * as actionTypes from './types';

const INITIAL_STATE = {
  isBusy: false,
  facilityData: {},
  trendsData: {},
  solarData: {},
  engineData: {},
  essData: {},
  evData: {},
  fleetData: {},
  otherData: {},
  windData: {},
  isCalculationDone: false,
  progress: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Facility __________________
    case actionTypes.GET_FACILITY_DATA_START:
      return { ...state, isBusy: true };
    case actionTypes.GET_FACILITY_DATA_SUCCESS:
      const newFacilityData = { ...state.facilityData };
      newFacilityData[action.chartId] = action.payload;
      return { ...state, isBusy: false, facilityData: newFacilityData };
    case actionTypes.GET_FACILITY_DATA_FAIL:
      return { ...state, isBusy: false };

    // Trends ____________________
    case actionTypes.GET_TRENDS_DATA_START:
      return { ...state, isBusy: true };
    case actionTypes.GET_TRENDS_DATA_SUCCESS:
      const newTrendsData = { ...state.trendsData };
      newTrendsData[action.chartId] = action.payload;
      return { ...state, isBusy: false, trendsData: newTrendsData };
    case actionTypes.GET_TRENDS_DATA_FAIL:
      return { ...state, isBusy: false };

    // Solar ____________________
    case actionTypes.GET_SOLAR_DATA_START:
      return { ...state, isBusy: true };
    case actionTypes.GET_SOLAR_DATA_SUCCESS:
      const newSolarData = { ...state.SolarData };
      newSolarData[action.chartId] = action.payload;
      return { ...state, isBusy: false, SolarData: newSolarData };
    case actionTypes.GET_SOLAR_DATA_FAIL:
      return { ...state, isBusy: false };

    // Engine ____________________
    case actionTypes.GET_ENGINE_DATA_START:
      return { ...state, isBusy: true };
    case actionTypes.GET_ENGINE_DATA_SUCCESS:
      const newEngineData = { ...state.EngineData };
      newEngineData[action.chartId] = action.payload;
      return { ...state, isBusy: false, EngineData: newEngineData };
    case actionTypes.GET_ENGINE_DATA_FAIL:
      return { ...state, isBusy: false };

    // Ess ____________________
    case actionTypes.GET_ESS_DATA_START:
      return { ...state, isBusy: true };
    case actionTypes.GET_ESS_DATA_SUCCESS:
      const newEssData = { ...state.EssData };
      newEssData[action.chartId] = action.payload;
      return { ...state, isBusy: false, EssData: newEssData };
    case actionTypes.GET_ESS_DATA_FAIL:
      return { ...state, isBusy: false };

    // Ev ____________________
    case actionTypes.GET_EV_DATA_START:
      return { ...state, isBusy: true };
    case actionTypes.GET_EV_DATA_SUCCESS:
      const newEvData = { ...state.EvData };
      newEvData[action.chartId] = action.payload;
      return { ...state, isBusy: false, EvData: newEvData };
    case actionTypes.GET_EV_DATA_FAIL:
      return { ...state, isBusy: false };

    // Fleet ____________________
    case actionTypes.GET_FLEET_DATA_START:
      return { ...state, isBusy: true };
    case actionTypes.GET_FLEET_DATA_SUCCESS:
      const newFleetData = { ...state.FleetData };
      newFleetData[action.chartId] = action.payload;
      return { ...state, isBusy: false, FleetData: newFleetData };
    case actionTypes.GET_FLEET_DATA_FAIL:
      return { ...state, isBusy: false };

    // Other ____________________
    case actionTypes.GET_OTHER_DATA_START:
      return { ...state, isBusy: true };
    case actionTypes.GET_OTHER_DATA_SUCCESS:
      const newOtherData = { ...state.otherData };
      newOtherData[action.chartId] = action.payload;
      return { ...state, isBusy: false, otherData: newOtherData };
    case actionTypes.GET_OTHER_DATA_FAIL:
      return { ...state, isBusy: false };

    // Wind ____________________
    case actionTypes.GET_WIND_DATA_START:
      return { ...state, isBusy: true };
    case actionTypes.GET_WIND_DATA_SUCCESS:
      const newWindData = { ...state.WindData };
      newWindData[action.chartId] = action.payload;
      return { ...state, isBusy: false, WindData: newWindData };
    case actionTypes.GET_WIND_DATA_FAIL:
      return { ...state, isBusy: false };

    // Calculation _______________
    case actionTypes.TRIGGER_CALCULATION_DATA_START:
      return { ...state, isBusy: true, isCalculationDone: false };
    case actionTypes.TRIGGER_CALCULATION_DATA_SUCCESS:
      return { ...state, isBusy: false, isCalculationDone: action.payload };
    case actionTypes.TRIGGER_CALCULATION_DATA_FAIL:
      return { ...state, isBusy: false, isCalculationDone: true };
    case actionTypes.REQUEST_PROCESS_LOGS_START:
      return { ...state, isBusy: true };
    case actionTypes.REQUEST_PROCESS_LOGS_SUCCESS:
      return { ...state, isBusy: false, progress: action.payload };
    case actionTypes.REQUEST_PROCESS_LOGS_FAIL:
      return { ...state, isBusy: false };
    default:
      return state;
  }
};
