import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    width: '28rem',
    display: 'flex',
    flexDirection: 'row',
  },
  bellIcon: {
    marginLeft: '1rem',
    marginTop: '0.812rem',
  },
  bodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0.562rem 0.562rem 0.75rem 1rem',
    flex: 1,
  },
  actionRow: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '0.438rem',
    alignItems: 'center',
  },
  spacing: {
    flexGrow: 1,
  },
  captionBold: {
    fontWeight: '700',
  },
  notificationDescription: {
    marginTop: '0.25rem',
  },
  notifyButton: {
    fontSize: 14,
    textTransform: 'capitalize',
  },
  separator: {
    position: 'relative',
    bottom: 0,
    left: 0,
    right: 0,
    height: 1,
    backgroundColor: '#d7dde3',
  },
}));

export default useStyles;
