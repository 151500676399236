/* eslint-disable import/no-useless-path-segments */
/* eslint-disable global-require */
const images = {
  // eslint-disable-next-line import/no-useless-path-segments
  essBatteryIcon: require('../assets/images/icons/small/01-icons-02-categories-ic-ess.svg').default,
  logoWhiteSM: require('../assets/images/logo-white-sm.svg').default,
  ednaLogoWhiteSM: require('../assets/images/logo-edna-white-sm.png'),
  ednaColorfullLogo: require('../assets/images/logo-edna-colorfull.png'),
  iconBellWhite: require('../assets/images/icons/appBar/ic-bell.svg').default,
  iconLogout: require('../assets/images/icons/appBar/ic-logout.svg').default,
  iconDashboard: require('../assets/images/icons/menu/Dashboard.svg').default,
  iconService: require('../assets/images/icons/menu/Service.svg').default,
  iconConfiguration: require('../assets/images/icons/large/ic-config.svg').default,
  iconUtility: require('../assets/images/icons/categories/ic-utility.svg').default,
  iconSolar: require('../assets/images/icons/categories/ic-solar.svg').default,
  iconEss: require('../assets/images/icons/categories/ic-ddr.svg').default,
  iconEngine: require('../assets/images/icons/categories/ic-engine.svg').default,
  iconEv: require('../assets/images/icons/categories/ic-ev.svg').default,
  iconFleet: require('../assets/images/icons/categories/ic-fleet.svg').default,
  iconOther: require('../assets/images/icons/categories/ic-other.svg').default,
  iconWind: require('../assets/images/icons/categories/ic-wind.svg').default,
  searchIcon: require('../assets/images/icons/small/ic-search.png'),
  notificationRowBellIcon: require('../assets/images/icons/small/group.svg').default,
  alertIcon: require('../assets/images/icons/small/ic-alert.svg').default,
  editIcon: require('../assets/images/icons/small/ic-edit-dark.svg').default,
  deleteIcon: require('../assets/images/icons/small/ic-delete.svg').default,
  chevronIcon: require('../assets/images/icons/small/ic-chevron-down.svg').default,
  chevronGreenIcon: require('../assets/images/icons/small/ic-chevron-down-green.svg').default,
  chevronGreyIcon: require('../assets/images/icons/small/ic-chevron-down-grey.svg').default,
  chevronOrangeIcon: require('../assets/images/icons/small/ic-chevron-down-orange.svg').default,
  chevronPurpleIcon: require('../assets/images/icons/small/ic-chevron-down-purple.svg').default,
  chevronRedIcon: require('../assets/images/icons/small/ic-chevron-down-red.svg').default,
  chevronYellowIcon: require('../assets/images/icons/small/ic-chevron-down-yellow.svg').default,
  checkIcon: require('../assets/images/icons/small/ic-check.svg').default,
  elipsisIcon: require('../assets/images/icons/small/elipsis.svg').default,
  bellIcon: require('../assets/images/icons/small/ic-bell.svg').default,
  redBellIcon: require('../assets/images/icons/small/ic-bell-red.svg').default,
  deleteDarkIcon: require('../assets/images/icons/small/ic-delete-dark.svg').default,
  duplicateIcon: require('../assets/images/icons/small/ic-duplicate.svg').default,
  assignIcon: require('../assets/images/icons/small/assign.svg').default,
  pin: require('../assets/images/pin.svg').default,
  map: require('../assets/images/icons/large/ic-map.svg').default,
  mapLocate: require('../assets/images/icons/small/ic-map-locate.svg').default,
  mapExpand: require('../assets/images/icons/small/ic-map-expand.svg').default,
  configurations: require('../assets/images/icons/large/ic-configuration.svg').default,
  iconFacility: require('../assets/images/icons/categories/ic-facility.svg').default,
  iconTrend: require('../assets/images/icons/categories/ic-trend.svg').default,
  iconDownload: require('../assets/images/icons/small/ic-download.svg').default,
  iconCopy: require('../assets/images/icons/small/ic-copy.svg').default,
  emptyEnvelop: require('../assets/images/icons/large/ic-notification.svg').default,
  btnUnchecked: require('../assets/images/btn-unchecked.svg').default,
  btnChecked: require('../assets/images/btn-checked.svg').default,
  dcAcIcon: require('../assets/images/icons/categories/ic-acdc.svg').default,
  viewIcon: require('../assets/images/icons/small/01-icons-01-small-ic-eye.svg').default,
  clearIcon: require('../assets/images/icons/small/01-icons-01-small-ic-clear.svg').default,
  componentManager: require('../assets/images/icons/menu/ComponentManager.svg').default,
  activeIcon: require('../assets/images/icons/small/01-icons-01-small-ic-active.svg').default,
  inactiveIcon: require('../assets/images/icons/small/01-icons-01-small-ic-inactive.svg').default,
  noComponents: require('../assets/images/icons/containers/NoComponents.svg').default,
  enclosureIcon: require('../assets/images/icons/small/ic-enclosure.svg').default,
  iconInverter: require('../assets/images/icons/small/01-icons-02-categories-ic-acdc.svg').default,
  backChevron: require('../assets/images/icons/small/01-icons-01-small-ic-back.svg').default,
  radioButtonUnchecked: require('../assets/images/icons/small/radio-btn-unchecked.svg').default,
  radioButtonChecked: require('../assets/images/icons/small/radio-btn-checked.svg').default,
  oneLineDiagramIcon: require('../assets/images/icons/small/ic-line_diagram.svg').default,
  billingOfMaterialIcon: require('../assets/images/icons/small/ic-billing.svg').default,
  csvIcon: require('../assets/images/icons/small/ic-csv.svg').default,
  genabilityChartIcon: require('../assets/images/icons/small/genability-chart-icon.svg').default,
  searchIconWhite: require('../assets/images/icons/small/01-icons-01-small-ic-search-white.svg').default,
  alertIconWhite: require('../assets/images/icons/small/01-icons-01-small-ic-info-white.svg').default,
  alertIconOrange: require('../assets/images/icons/small/01-icons-01-small-ic-alert-or.svg').default,
  nmIcon: require('../assets/images/icons/small/ic-let-nm.svg').default,
  crIcon: require('../assets/images/icons/small/ic-let-cr.svg').default,
  buyIcon: require('../assets/images/icons/small/ic-let-buy.svg').default,
  generalClassIcon: require('../assets/images/icons/small/ic-class-gen.svg').default,
  residentialClassIcon: require('../assets/images/icons/small/ic-class-res.svg').default,
  calendarIcon: require('../assets/images/icons/small/ic-cal.svg').default,
  flagIcon: require('../assets/images/icons/small/flag-icon.svg').default,
  selectedFlagIcon: require('../assets/images/icons/small/flag-icon-selected.svg').default,
  gidIcon: require('../assets/images/icons/small/ic-gid.svg').default,
};

export default images;
