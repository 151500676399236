import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 700,
    borderRadius: 0,
    '& .MuiPaper-rounded': {
      borderRadius: 0,
    },
  },
  row: {
    height: '2.5rem',
  },
  headerTitle: {
    fontWeight: 'bold',
  },
  buttonsCell: {
    spacing: '1.5rem',
  },
  iconButton: {
    marginLeft: '0.5rem',
  },
  configurationName: {
    fontSize: '0.875rem',
  },
  trimmedManufacturer: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '10rem',
  },
  trimmedPartNumber: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '5rem',
  },
  trimmedDescription: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '40rem',
  },
}));

export default useStyles;
