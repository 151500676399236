import TableCell from '@mui/material/TableCell';
import { withStyles } from '@mui/styles';
import { Colors } from '../../theme';

/**
 * Styled Table Cell
 */
const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: Colors.mercury,
    color: Colors.lunarGreen,
    height: '2.5rem',
    fontSize: '0.9rem',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textAlign: 'center',
  },
  body: {
    fontSize: '0.875rem',
    minHeight: '1.5rem',
  },
  footer: {
    backgroundColor: Colors.mercury,
    color: Colors.lunarGreen,
    height: '2rem',
  },
  root: {
    borderBottom: 'none',
    '&:first-child': {
      padding: '0 0 0 1rem',
    },
    '&:last-child': {
      padding: '0 1rem 0 0',
    },
  },
}))(TableCell);

export default StyledTableCell;
