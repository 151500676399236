/* eslint-disable import/prefer-default-export */
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig, loginRequest, apiRequest } from './authConfig';

export class AuthService {
  constructor() {
    this.msalApp = new PublicClientApplication(msalConfig);
  }

  handleRedirectPromise = () => this.msalApp.handleRedirectPromise();

  getAllAccounts = () => this.msalApp.getAllAccounts();

  signIn = () => {
    this.msalApp.loginRedirect(loginRequest);
  };

  signOut = (homeAccountId) => {
    const logoutRequest = {
      account: this.msalApp.getAccountByHomeId(homeAccountId),
    };

    this.msalApp.logout(logoutRequest);
  };

  acquireToken = () => {
    const allAccounts = this.msalApp.getAllAccounts();
    const firstAccount = allAccounts[0];

    return new Promise((resolve, reject) => {
      this.msalApp
        .acquireTokenSilent({
          ...apiRequest,
          account: firstAccount,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.errorCode === 'login_required') {
            this.msalApp.acquireTokenPopup(apiRequest).then(() => {
              this.msalApp
                .acquireTokenSilent({
                  ...apiRequest,
                  account: firstAccount,
                })
                .then((response) => {
                  resolve(response);
                })
                .catch((err) => {
                  reject(err);
                });
            });
          }
        });
    });
  };
}
