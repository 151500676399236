import { makeStyles } from '@mui/styles';
import { Colors } from '../../../theme';

export default makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '6rem',
    backgroundColor: Colors.white,
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '1.12rem',
    paddingTop: '.5rem',
    width: '80%',
  },
  lowerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    objectFit: 'contain',
    alignSelf: 'flex-end',
    justifyContent: 'space-between',
  },
  statusView: {
    fontSize: '0.75rem',
    fontWeight: '600',
    margin: '0 0.25rem',
    color: Colors.white,
    borderRadius: '2px',
    textAlign: 'center',
    height: 'fit-content',
    padding: '0 0.25rem',
  },
  opporunityName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '50rem',
    minWidth: '20rem',
    whiteSpace: 'nowrap',
  },
  privateContainer: {
    display: 'flex',
  },
  toggleButtonLabel: {
    fontSize: '0.9rem',
    paddingRight: '0.5rem',
  },
  testOpportunityToggle: {
    display: 'flex',
    paddingTop: '.5rem',
  },
  testContainer: {
    display: 'flex',
    paddingTop: '0.5rem',
  },
  toggleButtonLabelDisabled: {
    color: Colors.greyDisabled,
  },
  toggleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
    alignItems: 'end',
  },
  subtitle: {
    minHeight: '1.2rem',
  },
});
