/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/function-component-definition */
/**
 * Form modal for components configurations
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import useStyles from './styles';
import messages from '../messages';
import Input from '../../../../components/Input';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';
import TwoColumnRow from '../../../../components/TwoColumnRow';
import AlertModal from '../../../../components/AlertModal';
import {
  formatThousandsNumber,
  validateDecimalValue,
  validateDecimalNumericValue,
  decimalPercentageToPercentage,
} from '../../../../utils/stringFormatter';
import { setValueTextClass } from '../../utilities';

const MANUFACTURER = 'MANUFACTURER';
const PART_NUMBER = 'PART_NUMBER';
const NAMEPLATE_ENERGY_CAPACITY = 'NAMEPLATE_ENERGY_CAPACITY';
const POWER_RATING = 'POWER_RATING';
const CONNECTION = 'CONNECTION';
const UNIT_COST = 'UNIT_COST';
const INSTALLATION_COST = 'INSTALLATION_COST';
const DEPTH_OF_DISCHARGE = 'DEPTH_OF_DISCHARGE';
const BATTERY_EFFICIENCY = 'BATTERY_EFFICIENCY';

const REQUIRED_ERROR = 'required';
const PERCENTAGE_SYMBOL = '%';
const KWH = 'kWh';
const KW = 'kW';
const DOLLAR_SYMBOL = '$';
const EITHER = 'EITHER';
const DC = 'DC';
const AC = 'AC';

const ESSBatteryModal = ({ defaultData, isModalVisible, readonly, onAccept, onCancel }) => {
  // form fields
  const [manufacturer, setManufacturer] = useState(defaultData?.manufacturer ?? '');
  const [partNumber, setPartNumber] = useState(defaultData?.partNumber ?? '');
  const [connection, setConnection] = useState(defaultData?.connection ?? AC);
  const [nameplateEnergyCapacity, setNamePlateEnergyCapacity] = useState(defaultData?.nameplateEnergyCapacity.toString() ?? '');
  const [powerRating, setPowerRating] = useState(defaultData?.powerRating ?? '');
  const [depthOfDischarge, setDepthOfDischarge] = useState(defaultData?.depthOfDischarge ?? '');
  const [unitCost, setUnitCost] = useState(defaultData?.unitCost ?? '');
  const [batteryEfficiency, setBatteryEfficiency] = useState(defaultData?.efficiency ?? '');
  const [installationCost, setInstallationCost] = useState(defaultData?.installationCost ?? '');
  const [usableEnergyCapacity, setUsableEnergyCapacity] = useState(defaultData?.usableEnergyCapacity ?? '');

  const classes = useStyles();
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [id, setId] = useState('');
  const [isActive] = useState(defaultData?.isActive || false);
  const [isManufacturerValid, setIsManufacturerValid] = useState(true);
  const [manufacturerErrorMessage, setManufacturerErrorMessage] = useState('');
  const [isPartNumberValid, setIsPartNumberValid] = useState(true);
  const [partNumberErrorMessage, setPartNumberErrorMessage] = useState('');
  const [isNameplateEnergyCapacityValid, setIsNameplateEnergyCapacityValid] = useState(true);
  const [nameplateEnergyCapacityValidErrorMessage, setNameplateEnergyCapacityValidErrorMessage] = useState('');
  const [isPowerRatingValid, setIsPowerRatingValid] = useState(true);
  const [powerRatingErrorMessage, setPowerRatingErrorMessage] = useState('');
  const [isConnectionValid, setIsConnectionValid] = useState(true);
  const [isUnitCostValid, setIsUnitCostValid] = useState(true);
  const [unitCostErrorMessage, setUnitCostErrorMessage] = useState('');
  const [isInstallationCostValid, setIsInstallationCostValid] = useState(true);
  const [installationCostErrorMessage, setInstallationCostErrorMessage] = useState('');
  const [isDepthOfDischargeValid, setIsDepthOfDischargeValid] = useState(true);
  const [depthOfDischargeErrorMessage, setDepthOfDischargeErrorMessage] = useState('');
  const [isBatteryEfficiencyValid, setIsBatteryEfficiencyValid] = useState(true);
  const [batteryEfficiencyErrorMessage, setBatteryEfficiencyErrorMessage] = useState('');

  useEffect(() => {
    setDepthOfDischarge(readonly ? decimalPercentageToPercentage(depthOfDischarge) : depthOfDischarge);
    setBatteryEfficiency(readonly ? decimalPercentageToPercentage(batteryEfficiency) : batteryEfficiency);
  }, []);

  useEffect(() => {
    const nameplateEnergyCapacityValue = Number(nameplateEnergyCapacity.replace(/,/g, '')) || 0;
    const depthOfDischargeValue = parseFloat(depthOfDischarge || 0).toFixed(1) / 100;
    setUsableEnergyCapacity(parseFloat(nameplateEnergyCapacityValue * depthOfDischargeValue).toFixed(2));
  }, [nameplateEnergyCapacity, depthOfDischarge]);
  /*
   * validateField
   * Validates a field
   * @param {string} key Field type
   * @param {string} value Value to validate
   */
  const validateField = (key, value) => {
    let isValidValue;
    let validation;
    let errorMessage;
    switch (key) {
      case MANUFACTURER:
        isValidValue = value.trim() !== '' && value.length > 0;
        if (!isValidValue) {
          errorMessage = REQUIRED_ERROR;
        }
        setIsManufacturerValid(isValidValue);
        setManufacturerErrorMessage(errorMessage);
        break;

      case PART_NUMBER:
        isValidValue = value.trim() !== '' && value.length > 0;
        if (!isValidValue) {
          errorMessage = REQUIRED_ERROR;
        }
        setIsPartNumberValid(isValidValue);
        setPartNumberErrorMessage(errorMessage);
        break;

      case NAMEPLATE_ENERGY_CAPACITY:
        validation = validateDecimalNumericValue(value, '99999.9', true, 0, true);
        setIsNameplateEnergyCapacityValid(validation.isValid);
        setNameplateEnergyCapacityValidErrorMessage(validation.errorType);
        break;

      case POWER_RATING:
        validation = validateDecimalNumericValue(value, '9999.0', true, 0.1, true);
        setIsPowerRatingValid(validation.isValid);
        setPowerRatingErrorMessage(validation.errorType);
        break;

      case UNIT_COST:
        validation = validateDecimalNumericValue(value, '999999', true, 0);
        setIsUnitCostValid(validation.isValid);
        setUnitCostErrorMessage(validation.errorType);
        break;

      case INSTALLATION_COST:
        validation = validateDecimalNumericValue(value, '999999', true, 0);
        setIsInstallationCostValid(validation.isValid);
        setInstallationCostErrorMessage(validation.errorType);
        break;

      case DEPTH_OF_DISCHARGE:
        validation = validateDecimalNumericValue(value, '100.0', true, 0.0, true);
        setIsDepthOfDischargeValid(validation.isValid);
        setDepthOfDischargeErrorMessage(validation.errorType);
        break;

      case BATTERY_EFFICIENCY:
        validation = validateDecimalNumericValue(value, '100.0', true, 0.1, true);
        setIsBatteryEfficiencyValid(validation.isValid);
        setBatteryEfficiencyErrorMessage(validation.errorType);
        break;

      default:
        break;
    }
  };

  /*
   * hasChanges
   * Flag to determine if there are any changes in the form
   * return boolean
   */
  const hasChanges = () => {
    const currentData = {
      connection,
      manufacturer,
      partNumber,
      powerRating,
      depthOfDischarge,
      unitCost,
      installationCost,
      nameplateEnergyCapacity,
      batteryEfficiency,
    };
    const propsData = {
      connection: `${defaultData?.connection}`,
      manufacturer: `${defaultData?.manufacturer}`,
      partNumber: `${defaultData?.partNumber}`,
      powerRating: `${defaultData?.powerRating}`,
      depthOfDischarge: `${defaultData?.depthOfDischarge}`,
      unitCost: `${defaultData?.unitCost}`,
      installationCost: `${defaultData?.installationCost}`,
      nameplateEnergyCapacity: `${defaultData?.nameplateEnergyCapacity}`,
      batteryEfficiency: `${defaultData?.batteryEfficiency}`,
    };
    let hasChangesResult = false;
    if (id) {
      hasChangesResult = JSON.stringify({ ...currentData }) !== JSON.stringify({ ...propsData });
    } else {
      hasChangesResult =
        manufacturer.length > 0 ||
        partNumber.length > 0 ||
        powerRating.length > 0 ||
        depthOfDischarge.length > 0 ||
        unitCost.length > 0 ||
        installationCost.length > 0 ||
        nameplateEnergyCapacity.length > 0 ||
        batteryEfficiency.length > 0;
    }
    return hasChangesResult;
  };

  /*
   * enableSaveButton
   * Enables or disables the save button if the form has changes or the values are valid
   * return boolean
   */
  const enableSaveButton = () => {
    const isSaveButtonEnabled =
      hasChanges() &&
      isManufacturerValid &&
      isPartNumberValid &&
      isPowerRatingValid &&
      isConnectionValid &&
      isUnitCostValid &&
      isInstallationCostValid &&
      isDepthOfDischargeValid &&
      isNameplateEnergyCapacityValid &&
      isBatteryEfficiencyValid &&
      nameplateEnergyCapacity.length > 0 &&
      manufacturer.length > 0 &&
      partNumber.length > 0 &&
      powerRating.length > 0 &&
      connection.length > 0 &&
      unitCost.length > 0 &&
      installationCost.length > 0 &&
      depthOfDischarge.length > 0 &&
      batteryEfficiency.length > 0;

    return isSaveButtonEnabled;
  };

  /**
   *  resetForm
   *  resets form
   */
  const resetForm = () => {
    setId('');
    setManufacturer('');
    setIsManufacturerValid(true);
    setManufacturerErrorMessage('');
    setPartNumber('');
    setIsPartNumberValid(true);
    setPartNumberErrorMessage('');
    setNamePlateEnergyCapacity('');
    setIsNameplateEnergyCapacityValid(true);
    setNameplateEnergyCapacityValidErrorMessage('');
    setPowerRating('');
    setIsPowerRatingValid(true);
    setPowerRatingErrorMessage('');
    setConnection(AC);
    setIsConnectionValid(true);
    setUnitCost('');
    setIsUnitCostValid(true);
    setUnitCostErrorMessage('');
    setInstallationCost('');
    setIsInstallationCostValid(true);
    setInstallationCostErrorMessage('');
    setDepthOfDischarge('');
    setIsDepthOfDischargeValid(true);
    setDepthOfDischargeErrorMessage('');
    setBatteryEfficiency('');
    setIsBatteryEfficiencyValid(true);
    setBatteryEfficiencyErrorMessage('');
  };

  /**
   * handleSaveClick
   * handle the click event when the user clicks on save button inside createNewModal component
   */
  const handleSaveClick = () => {
    const newComponentData = {
      componentId: id,
      manufacturer,
      partNumber,
      essSize: usableEnergyCapacity,
      powerRating: Number(powerRating.replace(/,/g, '')),
      connection,
      depthOfDischarge: parseFloat(depthOfDischarge).toFixed(1) / 100,
      unitCost: Number(unitCost.replace(/,/g, '')),
      installationCost: Number(installationCost.replace(/,/g, '')),
      isActive: isActive || true,
      efficiency: parseFloat(batteryEfficiency).toFixed(1) / 100,
      nameplateEnergyCapacity: Number(nameplateEnergyCapacity.replace(/,/g, '')),
    };

    resetForm();
    onAccept(newComponentData);
  };

  /*
   * handleInputChange
   * Handles the inputs change event
   * @param {string} eventt Event object
   */
  const handleInputChange = (event) => {
    const { value, name } = event.target;
    let formattedValue;
    switch (name) {
      case MANUFACTURER:
        validateField(MANUFACTURER, value);
        setManufacturer(value);
        break;

      case PART_NUMBER:
        validateField(PART_NUMBER, value);
        setPartNumber(value);
        break;

      case NAMEPLATE_ENERGY_CAPACITY:
        validateField(NAMEPLATE_ENERGY_CAPACITY, value);
        setNamePlateEnergyCapacity(value);
        break;

      case POWER_RATING:
        formattedValue = validateDecimalValue(value.replace(/,/g, ''), 1) ? value : powerRating;
        validateField(POWER_RATING, formattedValue);
        setPowerRating(formattedValue);
        break;

      case UNIT_COST:
        formattedValue = `${value.replace(/[^\d]/g, '')}`;
        validateField(UNIT_COST, formattedValue);
        setUnitCost(formattedValue);
        break;

      case INSTALLATION_COST:
        formattedValue = `${value.replace(/[^\d]/g, '')}`;
        validateField(INSTALLATION_COST, formattedValue);
        setInstallationCost(formattedValue);
        break;

      case DEPTH_OF_DISCHARGE:
        validateField(DEPTH_OF_DISCHARGE, value);
        setDepthOfDischarge(value);
        break;

      case BATTERY_EFFICIENCY:
        validateField(BATTERY_EFFICIENCY, value);
        setBatteryEfficiency(value);
        break;

      default:
        break;
    }
  };

  /*
   * handleInputBlur
   * Handles the input blur event
   * @param {string} fieldName Name of the field
   */
  const handleInputBlur = (fieldName) => {
    switch (fieldName) {
      case UNIT_COST:
        setUnitCost(unitCost.length > 0 ? unitCost : '');
        validateField(UNIT_COST, unitCost);
        break;

      case MANUFACTURER:
        setManufacturer(manufacturer.length > 0 ? manufacturer : '');
        validateField(MANUFACTURER, manufacturer);
        break;

      case PART_NUMBER:
        setPartNumber(partNumber.length > 0 ? partNumber : '');
        validateField(PART_NUMBER, partNumber);
        break;

      case NAMEPLATE_ENERGY_CAPACITY:
        setNamePlateEnergyCapacity(nameplateEnergyCapacity.length > 0 ? nameplateEnergyCapacity : '');
        validateField(NAMEPLATE_ENERGY_CAPACITY, nameplateEnergyCapacity);
        break;

      case INSTALLATION_COST:
        setInstallationCost(installationCost.length > 0 ? installationCost : '');
        validateField(INSTALLATION_COST, installationCost);
        break;

      case DEPTH_OF_DISCHARGE:
        setDepthOfDischarge(depthOfDischarge.length > 0 ? parseFloat(depthOfDischarge.replace(/,/g, '')).toFixed(1) : '');
        validateField(DEPTH_OF_DISCHARGE, depthOfDischarge);
        break;

      case BATTERY_EFFICIENCY:
        setBatteryEfficiency(batteryEfficiency.length > 0 ? parseFloat(batteryEfficiency.replace(/,/g, '')).toFixed(1) : '');
        validateField(BATTERY_EFFICIENCY, batteryEfficiency);
        break;

      case POWER_RATING:
        setPowerRating(powerRating.length > 0 ? powerRating : '');
        validateField(POWER_RATING, powerRating);
        break;

      default:
        break;
    }
  };

  /**
   * handleCancelChanges
   * handles when the user cancel the creation or edition of a component
   */
  const handleCancelChanges = () => {
    setIsCancelModalVisible(false);
    onCancel();
  };

  /**
   * handleContinueUpdating
   * handles when the user cancel the creation or edition of a component and the user cancel that action
   */
  const handleContinueUpdating = () => {
    setIsCancelModalVisible(false);
  };

  /**
   * handleCreateNewDialogClose
   * handle the close event for form modal
   */
  const handleCreateNewDialogClose = () => {
    if (hasChanges()) {
      setIsCancelModalVisible(true);
    } else {
      onCancel();
      resetForm();
    }
  };

  /**
   * handleChangeConnection
   * handles the change event for connection input
   * @param {*} connection string
   */
  const handleChangeConnection = (newAlignement) => {
    setConnection(newAlignement);
    setIsConnectionValid(true);
  };

  /*
   * renderInputField
   * Renders an input value
   * @param {string} key Field name
   * @param {string} title Name to display to the left of the field
   * @param {string} rightUnit Unit to display to the right of the input
   * @param {string} value Input value
   * @param {string} type Type of the field
   * @param {string} errorText Text to display under the input
   * @param {boolean} isValid Flag t determine if the field is valid and display the error message
   * @param {boolean} autoFocus Auto focus field flag
   * @param {boolean} isEnabled flag to determine if the control is enabled or not
   * @param {string} titleComplement string that complets the title if is the case
   * @param {string} leftUnit Unit to display to the left of the input
   * @param {boolean} small Flag to determine the sice of the input
   * @param {object} inputProps Extra props to add to the input
   */
  const renderInputField = ({
    key,
    title,
    rightUnit,
    value,
    type,
    alignRight = true,
    errorText,
    isValid,
    autoFocus = false,
    isEnabled,
    titleComplement,
    leftUnit,
    small,
    inputProps,
  }) => {
    const errorMessage = renderErrorMessage(!isValid, errorText);
    const titleText = title ? <FormattedMessage {...title} values={{ type: titleComplement || '' }} /> : '';
    return (
      <TwoColumnRow title={titleText} error={errorMessage} key={key} testId={`two-column-${key}`}>
        <div className={classes.inputContainer}>
          {leftUnit && (
            <Typography color="primary" className={classes.currencyText}>
              {leftUnit}
            </Typography>
          )}
          <div className={setValueTextClass(rightUnit, leftUnit, small, classes, readonly)}>
            {readonly ? (
              value
            ) : (
              <Input
                autoFocus={autoFocus}
                invalid={!isValid}
                name={key}
                value={value}
                type={type}
                onChange={handleInputChange}
                alignRight={alignRight}
                onBlur={() => handleInputBlur(key)}
                inputProps={inputProps}
                disabled={isEnabled}
              />
            )}
          </div>
          {rightUnit && (
            <Typography color="primary" variant="caption" className={`${classes.unitText} ${!readonly ? classes.notReadOnlyUnitText : ''}`}>
              {rightUnit}
            </Typography>
          )}
        </div>
      </TwoColumnRow>
    );
  };

  /**
   * renderErrorMessage
   * Renders the error message to display bellow each of the fields
   * @param {boolean} error Flag to display the error message
   * @param {string} message Message to display
   */
  const renderErrorMessage = (error, message) => {
    if (error && message) {
      return (
        <Typography variant="caption" color="error">
          <FormattedMessage {...message} />
        </Typography>
      );
    }
    return null;
  };

  const modalTitle = readonly ? messages.ViewComponentModalTitle : messages.createComponentModalTitle;
  const paperPropsStyle = { maxWidth: '30rem' };
  return (
    <>
      <AlertModal
        title={messages.alertCancelChangesTitle}
        message={messages.alertCancelChangesMessage}
        isVisible={isCancelModalVisible}
        acceptLabel={messages.alertYesLabel}
        cancelLabel={messages.alertNoLabel}
        onAccept={handleCancelChanges}
        onCancel={handleContinueUpdating}
        size="30rem"
      />

      <Modal open={isModalVisible} title={<FormattedMessage {...modalTitle} />} paperPropsStyle={paperPropsStyle}>
        <>
          {renderInputField({
            key: MANUFACTURER,
            title: messages.manufactureLabel,
            value: manufacturer,
            type: 'text',
            alignRight: false,
            errorText: manufacturerErrorMessage === REQUIRED_ERROR && messages.manufactureError,
            isValid: isManufacturerValid,
            inputProps: { maxLength: 200 },
          })}

          {renderInputField({
            key: PART_NUMBER,
            title: messages.partNumberLabel,
            value: partNumber,
            type: 'text',
            alignRight: false,
            errorText: partNumberErrorMessage === REQUIRED_ERROR ? messages.partNumberRequiredError : messages.partNumberFormatError,
            isValid: isPartNumberValid,
            inputProps: { maxLength: 100 },
          })}

          <FormattedMessage {...messages.connectionLabel}>
            {(label) => (
              <TwoColumnRow title={label} key={CONNECTION}>
                {!readonly && (
                  <div className={classes.inputContainer}>
                    <div className={classes.regularInputContainer}>
                      <ToggleButtonGroup
                        value={connection}
                        exclusive
                        onChange={(ev, newAlignement) => handleChangeConnection(newAlignement)}
                        aria-label="text alignment"
                      >
                        <ToggleButton value={EITHER} aria-label="left aligned" className={classes.alt} data-testid="either-toggle">
                          {EITHER}
                        </ToggleButton>

                        <ToggleButton value={AC} aria-label="centered" className={classes.alt} data-testid="ac-toggle">
                          {AC}
                        </ToggleButton>

                        <ToggleButton value={DC} aria-label="right aligned" className={classes.alt} data-testid="dc-toggle">
                          {DC}
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  </div>
                )}
                {readonly && <div>{connection}</div>}
              </TwoColumnRow>
            )}
          </FormattedMessage>

          {renderInputField({
            key: NAMEPLATE_ENERGY_CAPACITY,
            title: messages.essSizeLabel,
            value: formatThousandsNumber(nameplateEnergyCapacity),
            rightUnit: KWH,
            type: 'text',
            errorText:
              nameplateEnergyCapacityValidErrorMessage === REQUIRED_ERROR
                ? messages.nameplateEnergyCapacityValidRequiredErrorMessage
                : messages.nameplateEnergyCapacityValidPatternErrorMessage,
            isValid: isNameplateEnergyCapacityValid,
          })}

          {renderInputField({
            key: POWER_RATING,
            title: messages.powerRatingLabel,
            rightUnit: KW,
            value: formatThousandsNumber(powerRating),
            type: 'text',
            errorText:
              powerRatingErrorMessage === REQUIRED_ERROR
                ? messages.powerRatingRequiredErrorMessage
                : messages.batteryPowerRatingPatternErrorMessage,
            isValid: isPowerRatingValid,
          })}
          {renderInputField({
            key: BATTERY_EFFICIENCY,
            title: messages.batteryEfficiencyLabel,
            rightUnit: PERCENTAGE_SYMBOL,
            value: formatThousandsNumber(batteryEfficiency),
            type: 'text',
            errorText:
              batteryEfficiencyErrorMessage === REQUIRED_ERROR
                ? messages.batteryEfficiencyRequiredErrorMessage
                : messages.batteryEfficiencyPatternErrorMessage,
            isValid: isBatteryEfficiencyValid,
          })}
          {renderInputField({
            key: DEPTH_OF_DISCHARGE,
            title: messages.depthOfDischargeLabel,
            rightUnit: PERCENTAGE_SYMBOL,
            value: formatThousandsNumber(depthOfDischarge),
            type: 'text',
            errorText:
              depthOfDischargeErrorMessage === REQUIRED_ERROR
                ? messages.depthOfDischargeRequiredErrorMessage
                : messages.depthOfDischargePatternErrorMessage,
            isValid: isDepthOfDischargeValid,
          })}

          {renderInputField({
            key: UNIT_COST,
            title: messages.unitCostLabel,
            value: formatThousandsNumber(unitCost),
            type: 'text',
            errorText:
              unitCostErrorMessage === REQUIRED_ERROR ? messages.unitCostRequiredErrorMessage : messages.unitCostPatternErrorMessage,
            isValid: isUnitCostValid,
            leftUnit: DOLLAR_SYMBOL,
          })}

          {renderInputField({
            key: INSTALLATION_COST,
            title: messages.installationCostLabel,
            type: 'text',
            errorText:
              installationCostErrorMessage === REQUIRED_ERROR
                ? messages.installationCostRequiredErrorMessage
                : messages.installationCostPatternErrorMessage,
            isValid: isInstallationCostValid,
            leftUnit: DOLLAR_SYMBOL,
            value: formatThousandsNumber(installationCost),
          })}
          <FormattedMessage {...messages.usableEnergyCapacityTitle}>
            {(titleText) => (
              <TwoColumnRow title={titleText}>
                {usableEnergyCapacity}
                <Typography color="primary" variant="caption" className={`${classes.unitText}`}>
                  {KWH}
                </Typography>
              </TwoColumnRow>
            )}
          </FormattedMessage>
        </>
        <>
          {!readonly && (
            <>
              <div className={classes.saveButton}>
                <Button handleRoute={handleSaveClick} disabled={!enableSaveButton()} disableRipple={false} data-testid="save-button">
                  <FormattedMessage {...messages.save} />
                </Button>
              </div>
              <div className={classes.cancelButton}>
                <Button onClick={handleCreateNewDialogClose}>
                  <FormattedMessage {...messages.cancel} />
                </Button>
              </div>
            </>
          )}
          {readonly && (
            <div>
              <Button onClick={() => onCancel()}>
                <FormattedMessage {...messages.close} />
              </Button>
            </div>
          )}
        </>
      </Modal>
    </>
  );
};

ESSBatteryModal.propTypes = {
  classes: PropTypes.object,
  isModalVisible: PropTypes.bool,
  onCancel: PropTypes.func,
  onAccept: PropTypes.func,
  defaultData: PropTypes.shape({
    id: PropTypes.string,
    essComponentId: PropTypes.string,
    manufacturer: PropTypes.string,
    partNumber: PropTypes.string,
    maxCharge: PropTypes.string,
    powerRating: PropTypes.string,
    depthOfDischarge: PropTypes.string,
    unitCost: PropTypes.string,
    installationCost: PropTypes.string,
  }),
  readonly: PropTypes.bool,
  componentsType: PropTypes.string,
  // Render input prop validations
  key: PropTypes.string,
  title: PropTypes.string,
  rightUnit: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  errorText: PropTypes.string,
  isValid: PropTypes.bool,
  autoFocus: PropTypes.bool,
  isEnabled: PropTypes.bool,
  titleComplement: PropTypes.string,
  leftUnit: PropTypes.string,
  small: PropTypes.bool,
  inputProps: PropTypes.object,
  isActive: PropTypes.bool,
};

export default withStyles(useStyles)(ESSBatteryModal);
