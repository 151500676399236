/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { injectIntl, FormattedMessage } from 'react-intl';
import messages from '../messages';
import FormikInput from '../../../../components/FormikInput';
import { commonValidators } from '../../../../utils/yupValidation';
import { decimalPercentageToPercentage } from '../../../../utils/stringFormatter';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';
import AlertModal from '../../../../components/AlertModal';
import useStyles from './styles';

const PERCENTAGE_SYMBOL = '%';
const KW = 'kW';
const DOLLAR_SYMBOL = '$';
// field IDs - required for formik.

const MANUFACTURER = 'manufacturer';
const PART_NUMBER = 'partNumber';
const POWER_RATING = 'powerRating';
const CONVERTER_EFFICIENCY = 'converterEfficiency';
const STATIC_LOSS = 'staticLoss';
const UNIT_COST = 'unitCost';
const INSTALLATION_COST = 'installationCost';

const SolarConverterModal = (props) => {
  const { classes, isModalVisible, defaultData, onCancel, onAccept, intl, readonly } = props;

  const [id, setId] = useState('');
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);

  useEffect(() => {
    setId(defaultData?.componentId || '');
  }, [defaultData]);

  const initialValues = {
    manufacturer: `${defaultData?.manufacturer ?? ''}`,
    partNumber: `${defaultData?.partNumber ?? ''}`,
    powerRating: `${defaultData?.powerRating ?? ''}`,
    converterEfficiency: `${defaultData?.efficiency ?? ''}`,
    staticLoss: `${defaultData?.staticLoss ?? '0'}`,
    unitCost: `${defaultData?.unitCost ?? ''}`,
    installationCost: `${defaultData?.installationCost ?? ''}`,
    isActive: defaultData?.isActive ?? true,
  };

  if (readonly) {
    initialValues.converterEfficiency = decimalPercentageToPercentage(defaultData?.efficiency) ?? '';
  } else {
    initialValues.converterEfficiency = defaultData?.efficiency ?? '';
  }

  const validationSchema = Yup.object({
    manufacturer: commonValidators.REQUIRED_STRING,
    partNumber: commonValidators.REQUIRED_STRING,
    powerRating: commonValidators.REQUIRED_NUMBER_COMMA_VALIDATION_MIN_MAX('0.1', '9999.0'),
    converterEfficiency: commonValidators.REQUIRED_NUMBER_COMMA_VALIDATION_MIN_MAX('0.1', '100.0'),
    staticLoss: commonValidators.REQUIRED_NUMBER_COMMA_VALIDATION_MIN_MAX('0.000', '9,999.000'),
    unitCost: commonValidators.REQUIRED_NUMBER_COMMA_VALIDATION_MIN_MAX('0', '999,999'),
    installationCost: commonValidators.REQUIRED_NUMBER_COMMA_VALIDATION_MIN_MAX('0', '999,999'),
    isActive: Yup.boolean(),
  });

  /**
   * checkIfIsValid
   * @param value given a field value, uses the validationSchema to validate the field.
   * @returns set the state of isSaveButtonEnabled true or false based on the results.
   */
  const checkIfIsValid = (value) =>
    validationSchema
      .validate(value)
      .then(() => {
        setIsSaveButtonEnabled(true);
      })
      .catch(() => {
        setIsSaveButtonEnabled(false);
      });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validate: checkIfIsValid,
  });

  /**
   * handleSave
   * given form values from Formik, format and POST them to the api.
   */
  const handleSave = () => {
    const formVals = formik?.values;

    const newComponentData = {
      componentId: id,
      manufacturer: formVals?.manufacturer,
      partNumber: formVals?.partNumber,
      efficiency: Number(formVals?.converterEfficiency.replace(/,/g, '')) / 100,
      powerRating: Number(formVals?.powerRating.replace(/,/g, '')),
      staticLoss: Number(formVals?.staticLoss.replace(/,/g, '')),
      unitCost: Number(formVals?.unitCost.replace(/,/g, '')),
      installationCost: Number(formVals?.installationCost.replace(/,/g, '')),
      isActive: formVals?.isActive || true,
    };

    onAccept(newComponentData);
  };

  // handles primary cancel button
  const handleCancel = () => {
    setIsCancelModalVisible(false);
    onCancel();
  };

  const modalTitle = intl.formatMessage(
    readonly ? messages.viewSolarConverterComponentModalTitle : messages.createSolarConverterComponentModalTitle,
  );

  const paperPropsStyle = { maxWidth: '30rem' };

  return (
    <>
      {isCancelModalVisible && (
        <AlertModal
          title={messages.alertCancelChangesTitle}
          message={messages.alertCancelChangesMessage}
          isVisible={isCancelModalVisible}
          acceptLabel={messages.alertYesLabel}
          cancelLabel={messages.alertNoLabel}
          onAccept={handleCancel}
          onCancel={() => setIsCancelModalVisible(false)}
          size="30rem"
        />
      )}
      <Modal open={isModalVisible} title={modalTitle} paperPropsStyle={paperPropsStyle}>
        <form>
          <>
            <FormikInput id={MANUFACTURER} title={messages.manufactureLabel} readonly={readonly} maxLength={200} formik={formik} />
            <FormikInput id={PART_NUMBER} title={messages.partNumberLabel} readonly={readonly} maxLength={100} formik={formik} />
            <FormikInput
              id={POWER_RATING}
              dataTestId={`input-${POWER_RATING}`}
              dataTestWrapperId={`input-wrapper-${POWER_RATING}`}
              title={messages.powerRatingLabel}
              formatByThousands
              maxLength={8}
              decimalPlaces={1}
              readonly={readonly}
              rightUnit={KW}
              formik={formik}
              alignRight
            />
            <FormikInput
              id={CONVERTER_EFFICIENCY}
              dataTestId={`input-${CONVERTER_EFFICIENCY}`}
              dataTestWrapperId={`input-wrapper-${CONVERTER_EFFICIENCY}`}
              title={messages.converterEfficiencyLabel}
              maxLength={5}
              formatByThousands
              rightUnit={PERCENTAGE_SYMBOL}
              decimalPlaces={1}
              readonly={readonly}
              formik={formik}
              alignRight
            />
            <FormikInput
              id={STATIC_LOSS}
              dataTestId={`input-${STATIC_LOSS}`}
              dataTestWrapperId={`input-wrapper-${STATIC_LOSS}`}
              title={messages.staticLossLabel}
              formatByThousands
              maxLength={9}
              rightUnit={KW}
              decimalPlaces={3}
              readonly={readonly}
              formik={formik}
              alignRight
            />
            <FormikInput
              id={UNIT_COST}
              dataTestId={`input-${UNIT_COST}`}
              dataTestWrapperId={`input-wrapper-${UNIT_COST}`}
              title={messages.unitCostLabel}
              formatByThousands
              integersOnly
              maxLength={9}
              leftUnit={DOLLAR_SYMBOL}
              readonly={readonly}
              formik={formik}
              alignRight
            />
            <FormikInput
              id={INSTALLATION_COST}
              dataTestId={`input-${INSTALLATION_COST}`}
              dataTestWrapperId={`input-wrapper-${INSTALLATION_COST}`}
              title={messages.installationCostLabel}
              formatByThousands
              integersOnly
              maxLength={9}
              leftUnit={DOLLAR_SYMBOL}
              readonly={readonly}
              formik={formik}
              alignRight
            />
          </>
        </form>
        {!readonly && (
          <>
            <div className={classes.saveButton}>
              <Button
                handleRoute={handleSave}
                disabled={!isSaveButtonEnabled}
                type="submit"
                disableRipple={false}
                data-testid="save-button"
              >
                {intl.formatMessage(messages.save)}
              </Button>
            </div>
            <div className={classes.cancelButton}>
              <Button onClick={() => setIsCancelModalVisible(true)}>{intl.formatMessage(messages.cancel)}</Button>
            </div>
          </>
        )}
        {readonly && (
          <div>
            <Button onClick={() => onCancel()}>
              <FormattedMessage {...messages.close} />
            </Button>
          </div>
        )}
      </Modal>
    </>
  );
};

SolarConverterModal.propTypes = {
  classes: PropTypes.object,
  readonly: PropTypes.bool,
  onCancel: PropTypes.func,
  onAccept: PropTypes.func,
  isModalVisible: PropTypes.bool,
  defaultData: PropTypes.shape({
    id: PropTypes.string,
    manufacturer: PropTypes.string,
    partNumber: PropTypes.string,
    powerRating: PropTypes.number,
    converterEfficiency: PropTypes.string,
    staticLoss: PropTypes.string,
    unitCost: PropTypes.number,
    installationCost: PropTypes.string,
  }),
  intl: PropTypes.object,
};

export default withStyles(useStyles)(injectIntl(SolarConverterModal));
