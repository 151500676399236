/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/**
 *
 * SelectStatus.js
 * Custom select for status
 */

import { Box, Button, MenuItem, Popover, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import images from '../../../assets/images';
import { getValueIfExists } from '../../../containers/Opportunities/Configuration/OneLineDiagram/DiagramComponents/utils/propertyValidation';
import useStyles from './styles';

/**
 * SelectStatus
 * @param {Object[text, value]} selectData Data to show
 * @param {Object} selectedObject Object selected by default
 * @param {function} onChange Function to execute when changing value
 */
function SelectStatus({ selectData = [], selectedObject = {}, onChange }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles({ selectedColor: selectedObject.color });

  const openSelect = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = (element) => {
    const { text = null, color = null, value = null, chevron = null } = getValueIfExists(() => element, {});
    if (!text && !color && !value && !chevron) {
      setAnchorEl(null);
      return;
    }
    onChange(value);
    setAnchorEl(null);
  };

  const children = selectData.map((element) => (
    <MenuItem
      value={element.value}
      key={element.value}
      onClick={() => handleSelect(element)}
      style={{ width: '13.5rem' }}
      data-testid={`status-options-select-${element.value}`}
    >
      <Box style={{ border: `solid 2px ${element.color}` }}>
        <Typography
          className={classes.childrenText}
          style={{
            color: element.color,
          }}
        >
          {getValueIfExists(() => element.text.toUpperCase(), 'name')}
        </Typography>
      </Box>
      {selectedObject.text === element.text && <img alt="checked" src={images.checkIcon} className={classes.iconCheck} />}
    </MenuItem>
  ));

  return (
    <>
      <Button
        aria-controls="search-menu"
        aria-haspopup="true"
        onClick={openSelect}
        className={classes.selectMock}
        fullWidth
        data-testid="status-options-select"
      >
        {selectedObject.text}
        <img alt="v" src={selectedObject.chevron} className={classes.iconChevron} />
      </Button>
      <Popover
        id="search-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleSelect()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {children}
      </Popover>
    </>
  );
}

SelectStatus.propTypes = {
  selectData: PropTypes.array,
  onChange: PropTypes.any,
  selectedObject: PropTypes.object,
};

export default SelectStatus;
