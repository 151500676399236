/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/function-component-definition */
import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import useStyles from './styles';
import { stringAvatar } from '../../utils/stringFormatter';
import images from '../../assets/images';

const DrawerComponent = ({ classes, options, version, id, currentUser }) => (
  <Drawer
    id={id}
    className={classes.drawer}
    variant="permanent"
    classes={{
      paper: classes.drawerPaper,
    }}
    data-testid="drawer-component"
  >
    <Toolbar />
    <div className={classes.drawerContainer}>
      <List>
        <ListItem className={classes.avatarContainer}>
          <Tooltip title={currentUser} placement="right" arrow>
            <Avatar {...stringAvatar(currentUser)} />
          </Tooltip>
        </ListItem>
        {options.map((params) => (
          <Link href={params.url} key={params.id}>
            <ListItem button selected={params.selected} className={classes.listItem} key={params.id} tabIndex={-1}>
              <ListItemIcon className={classes.itemIcon}>
                <Tooltip title={<FormattedMessage {...params.toolTipMessage} />} placement="right" arrow>
                  <img src={params.icon} alt="" />
                </Tooltip>
              </ListItemIcon>
              {params.selected && <div className={classes.selectedStyle} />}
            </ListItem>
          </Link>
        ))}
      </List>
    </div>
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 10 }}>
      <img width="61" height="31" src={images.ednaColorfullLogo} alt="EnTech Logo" />
      <Typography>
        <FormattedMessage {...messages.version} />
      </Typography>
      <Typography data-testid="drawer-verstion-text">{version}</Typography>
    </div>
  </Drawer>
);

DrawerComponent.propTypes = {
  id: PropTypes.string,
  options: PropTypes.array,
  classes: PropTypes.object,
  version: PropTypes.string.isRequired,
  currentUser: PropTypes.string.isRequired,
};

export default withStyles(useStyles)(DrawerComponent);
