import { makeStyles } from '@mui/styles';
import { Colors } from '../../../theme';

export default makeStyles({
  wrapper: {
    height: '5.813rem',
  },
  leftSeparator: {
    marginLeft: '2rem',
    maxWidth: '40vw',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginTop: '-5px',
    paddingTop: '0.3rem',
  },
  upperWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    alignItems: 'center',
    paddingTop: (props) => (props.isQuickConfiguration ? '1.3rem' : '0'),
  },
  lowerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    objectFit: 'contain',
    justifyContent: 'space-between',
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  statusWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  statusView: {
    fontSize: '0.75rem',
    fontWeight: '600',
    margin: '0 0.25rem',
    color: Colors.white,
    borderRadius: '2px',
    textAlign: 'center',
    height: 'fit-content',
    padding: '0 0.25rem',
  },
  button: {
    height: '1.5rem',
    width: '1.5rem',
    border: 0,
    backgroundColor: 'transparent',
    outline: 'none',
    margin: '0',
    cursor: 'pointer',
    '&:focus': {
      backgroundColor: Colors.mercury,
    },
    '&:hover': {
      backgroundColor: Colors.mercury,
    },
    padding: '0',
  },
  overflowName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: 'auto',
    whiteSpace: 'nowrap',
  },
});
