/* eslint-disable default-param-last */
/* eslint-disable no-case-declarations */
import * as actionTypes from './types';

const INITIAL_STATE = {
  opportunities: null,
  opportunity: null,
  configurations: null,
  configuration: null,
  configDetails: null,
  isBusy: false,
  isBusyConfigs: false,
  hasUnsavedData: false,
  navigateTo: null,
  currentConfigScreen: null,
  users: [],
  opportunityId: null,
  isUploadingFile: false,
  fileUploadedId: null,
  clientLoadDataFiles: null,
  movedQuickConfig: null,
  geocoding: null,
  energyDataFiles: null,
  hasChanges: false,
  hasTariffChanges: false,
  enclosureDropdowns: null,
  helioScopeProjects: null,
  helioScopeSimulations: null,
  helioScopeProjectsVisible: false,
  helioScopeDesigns: null,
  templateBinaryData: [],
  tariffs: [],
  financialCharts: {},
  selectedTariff: null,
  currentTariff: null,
  tariffHistory: null,
  calculationData: null,
  version: null,
  rider: null,
  ratesVersionRider: null,
  cachedUtility: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Opportunities __________________
    case actionTypes.GET_OPPORTUNITIES_START:
      return { ...state, isBusy: true };
    case actionTypes.GET_OPPORTUNITIES_SUCCESS:
      return { ...state, isBusy: false, opportunities: action.payload };
    case actionTypes.GET_OPPORTUNITIES_FAIL:
      return { ...state, isBusy: false, opportunities: null };
    case actionTypes.POST_OPPORTUNITY_START:
      return { ...state, isBusy: true };
    case actionTypes.POST_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        isBusy: false,
        opportunityId: action.payload,
        configurations: null,
        opportunity: { ...state.opportunity, opportunityId: action.payload },
      };
    case actionTypes.POST_OPPORTUNITY_FAIL:
      return { ...state, isBusy: false, opportunityId: null };

    // Opportunity _________________
    case actionTypes.GET_OPPORTUNITY_DETAILS_START:
      return { ...state, isBusy: true };
    case actionTypes.GET_OPPORTUNITY_DETAILS_SUCCESS:
      return { ...state, isBusy: false, opportunity: action.payload };
    case actionTypes.SET_OPPORTUNITY_SETTINGS_DETAIL:
      return {
        ...state,
        configDetails: { ...state.configDetails, settings: action.payload },
      };
    case actionTypes.SET_MISSING_CONFIGURATION_LIST:
      return {
        ...state,
        configDetails: { ...state.configDetails, missingConfigurationList: action.payload },
      };
    case actionTypes.GET_OPPORTUNITY_DETAILS_FAIL:
      return { ...state, isBusy: false, opportunity: null };

    // Update Opportunity __________
    case actionTypes.PATCH_OPPORTUNITY_DETAIL_START:
      return { ...state, isBusy: true };
    case actionTypes.PATCH_OPPORTUNITY_DETAIL_SUCCESS:
      return { ...state, isBusy: false, opportunity: action.payload };
    case actionTypes.PATCH_OPPORTUNITY_DETAIL_FAIL:
      return { ...state, isBusy: false };

    // Configurations _________________
    case actionTypes.GET_OPPORTUNITY_CONFIGS_START:
      return { ...state, isBusyConfigs: true };
    case actionTypes.GET_OPPORTUNITY_CONFIGS_SUCCESS:
      return { ...state, isBusyConfigs: false, configurations: action.payload };
    case actionTypes.GET_OPPORTUNITY_CONFIGS_FAIL:
      return { ...state, isBusyConfigs: false, configurations: null };
    case actionTypes.POST_OPPORTUNITY_CONFIGS_START:
      return { ...state, isBusy: true };
    case actionTypes.POST_OPPORTUNITY_CONFIGS_SUCCESS:
      return {
        ...state,
        isBusy: false,
        configuration: { ...action.payload, isSomeClientLoadDataFileChecked: state.configDetails?.isSomeClientLoadDataFileChecked },
      };
    case actionTypes.POST_OPPORTUNITY_CONFIGS_FAIL:
      return { ...state, isBusy: false, configuration: null };
    case actionTypes.DELETE_OPPORTUNITY_CONFIGS_START:
      return { ...state, isBusy: true };
    case actionTypes.DELETE_OPPORTUNITY_CONFIGS_SUCCESS:
      return { ...state, isBusy: false };
    case actionTypes.DELETE_OPPORTUNITY_CONFIGS_FAIL:
      return { ...state, isBusy: false };

    // Configuration Details _________
    case actionTypes.GET_OPPORTUNITY_CONFIG_DETAIL_START:
      return { ...state, isBusy: true };
    case actionTypes.GET_OPPORTUNITY_CONFIG_DETAIL_SUCCESS:
      return {
        ...state,
        isBusy: false,
        configDetails: { ...action.payload, isSomeClientLoadDataFileChecked: state.configDetails?.isSomeClientLoadDataFileChecked },
      };
    case actionTypes.GET_OPPORTUNITY_CONFIG_DETAIL_FAIL:
      return { ...state, isBusy: false, configDetails: null };

    case actionTypes.PATCH_OPPORTUNITY_CONFIG_DETAIL_START:
      return { ...state, isBusy: true };
    case actionTypes.PATCH_OPPORTUNITY_CONFIG_DETAIL_SUCCESS:
      const { configurations, configDetails } = state;
      const { isSomeClientLoadDataFileChecked } = configDetails;
      if (configurations) {
        const configIndex = configurations.findIndex((conf) => conf.configurationId === action.payload.configurationId);
        if (configIndex > -1) {
          configurations.splice(configIndex, 1, action.payload);
        }
      }

      return {
        ...state,
        isBusy: false,
        configDetails: { ...action.payload, isSomeClientLoadDataFileChecked },
        configurations,
      };
    case actionTypes.PATCH_OPPORTUNITY_CONFIG_DETAIL_FAIL:
      return {
        ...state,
        isBusy: false,
      };
    case actionTypes.DELETE_OPPORTUNITY_CONFIG_DETAIL_START:
      return { ...state, isBusy: true };
    case actionTypes.DELETE_OPPORTUNITY_CONFIG_DETAIL_SUCCESS:
      return {
        ...state,
        isBusy: false,
        configDetails: { ...action.payload, isSomeClientLoadDataFileChecked: state.configDetails?.isSomeClientLoadDataFileChecked },
      };
    case actionTypes.DELETE_OPPORTUNITY_CONFIG_DETAIL_FAIL:
      return { ...state, isBusy: false };
    case actionTypes.SET_HAS_UNSAVED_DATA_FLAG:
      return { ...state, hasUnsavedData: action.payload };
    case actionTypes.SET_HAS_CHANGES:
      return { ...state, hasChanges: action.payload };
    case actionTypes.SET_HAS_TARIFF_CHANGES:
      return { ...state, hasTariffChanges: action.payload };
    case actionTypes.SET_NAVIGATE_TO:
      return { ...state, navigateTo: action.payload };
    case actionTypes.SET_CURRENT_CONFIG_SCREEN:
      return {
        ...state,
        currentConfigScreen: action.payload,
        navigateTo: null,
      };

    case actionTypes.SET_CACHED_UTILITY:
      return {
        ...state,
        cachedUtility: action.payload,
      };

    // Users __________________
    case actionTypes.GET_USERS_START:
      return { ...state, isBusy: true };
    case actionTypes.GET_USERS_SUCCESS:
      return { ...state, isBusy: false, users: action.payload };
    case actionTypes.GET_USERS_FAIL:
      return { ...state, isBusy: false, users: [] };

    // Client Load Files _______
    case actionTypes.GET_OPPORTUNITY_CLIENT_LOAD_FILES_START:
      return { ...state, isBusy: true };
    case actionTypes.GET_OPPORTUNITY_CLIENT_LOAD_FILES_SUCCESS:
      return {
        ...state,
        isBusy: false,
        clientLoadDataFiles: action.payload,
        configDetails: {
          ...state.configDetails,
          isSomeClientLoadDataFileChecked: action.payload.some((file) => file.isChecked),
        },
      };
    case actionTypes.GET_OPPORTUNITY_CLIENT_LOAD_FILES_FAIL:
      return { ...state, isBusy: false, clientLoadDataFiles: null };
    case actionTypes.POST_OPPORTUNITY_CLIENT_LOAD_DATA_FILES_START:
      return { ...state, isUploadingFile: true };
    case actionTypes.POST_OPPORTUNITY_CLIENT_LOAD_DATA_FILES_SUCCESS:
      return {
        ...state,
        isUploadingFile: false,
        fileUploadedId: action.payload,
      };
    case actionTypes.POST_OPPORTUNITY_CLIENT_LOAD_DATA_FILES_FAIL:
      return { ...state, isUploadingFile: false, fileUploadedId: null };
    case actionTypes.DELETE_OPPORTUNITY_CLIENT_LOAD_FILES_START:
      return { ...state, isBusy: true };
    case actionTypes.DELETE_OPPORTUNITY_CLIENT_LOAD_FILES_SUCCESS:
      return { ...state, isBusy: false };
    case actionTypes.DELETE_OPPORTUNITY_CLIENT_LOAD_FILES_FAIL:
      return { ...state, isBusy: false };
    case actionTypes.PATCH_OPPORTUNITY_CLIENT_LOAD_FILES_START:
      return { ...state, isUploadingFile: true };
    case actionTypes.PATCH_OPPORTUNITY_CLIENT_LOAD_FILES_SUCCESS:
      return {
        ...state,
        isUploadingFile: false,
        fileUploadedId: action.payload,
      };
    case actionTypes.PATCH_OPPORTUNITY_CLIENT_LOAD_FILES_FAIL:
      return { ...state, isUploadingFile: false, fileUploadedId: null };
    case actionTypes.CLEAR_CLIENT_LOAD_DATA:
      return { ...state, isBusy: false, clientLoadDataFiles: null };
    case actionTypes.GET_FILE_TEMPLATE_START:
      return { ...state, isBusy: true };
    case actionTypes.GET_FILE_TEMPLATE_SUCCESS:
      return { ...state, isBusy: false, templateBinaryData: action.payload };
    case actionTypes.GET_FILE_TEMPLATE_FAIL:
      return { ...state, isBusy: false, templateBinaryData: [] };

    // Move Quick Configurations__
    case actionTypes.MOVE_QUICKCONFIG_OPPORTUNITIES_START:
      return { ...state, isBusy: true };
    case actionTypes.MOVE_QUICKCONFIG_OPPORTUNITIES_SUCCESS:
      return { ...state, isBusy: false, movedQuickConfig: action.payload };
    case actionTypes.MOVE_QUICKCONFIG_OPPORTUNITIES_FAIL:
      return { ...state, isBusy: false, movedQuickConfig: null };

    // Geocoding _______________
    case actionTypes.GET_GEOCODE_VALIDATION_START:
      return { ...state, isBusy: true };
    case actionTypes.GET_GEOCODE_VALIDATION_SUCCESS:
      return { ...state, isBusy: false, geocoding: action.payload };
    case actionTypes.GET_GEOCODE_VALIDATION_FAIL:
      return { ...state, isBusy: false, geocoding: null };

    // Energy Data Files _______
    case actionTypes.GET_OPPORTUNITY_ENERGY_DATA_FILES_START:
      return { ...state, isBusy: true };
    case actionTypes.GET_OPPORTUNITY_ENERGY_DATA_FILES_SUCCESS:
      return { ...state, isBusy: false, energyDataFiles: action.payload };
    case actionTypes.GET_OPPORTUNITY_ENERGY_DATA_FILES_FAIL:
      return { ...state, isBusy: false, energyDataFiles: null };
    case actionTypes.POST_OPPORTUNITY_ENERGY_DATA_FILES_START:
      return { ...state, isUploadingFile: true };
    case actionTypes.POST_OPPORTUNITY_ENERGY_DATA_FILES_SUCCESS:
      return {
        ...state,
        isUploadingFile: false,
        fileUploadedId: action.payload,
      };
    case actionTypes.POST_OPPORTUNITY_ENERGY_DATA_FILES_FAIL:
      return { ...state, isUploadingFile: false, fileUploadedId: null };
    case actionTypes.DELETE_OPPORTUNITY_ENERGY_DATA_FILES_START:
      return { ...state, isBusy: true };
    case actionTypes.DELETE_OPPORTUNITY_ENERGY_DATA_FILES_SUCCESS:
      return { ...state, isBusy: false };
    case actionTypes.DELETE_OPPORTUNITY_ENERGY_DATA_FILES_FAIL:
      return { ...state, isBusy: false };
    case actionTypes.PATCH_OPPORTUNITY_ENERGY_DATA_FILES_START:
      return { ...state, isUploadingFile: true };
    case actionTypes.PATCH_OPPORTUNITY_ENERGY_DATA_FILES_SUCCESS:
      return {
        ...state,
        isUploadingFile: false,
        fileUploadedId: action.payload,
      };
    case actionTypes.PATCH_OPPORTUNITY_ENERGY_DATA_FILES_FAIL:
      return { ...state, isUploadingFile: false, fileUploadedId: null };
    case actionTypes.CLEAR_ENERGY_SOURCE_DATA:
      return { ...state, isBusy: false, energyDataFiles: null };
    case actionTypes.GET_ENCLOSURES_DROPDOWN_START:
      return { ...state, isBusy: true };
    case actionTypes.GET_ENCLOSURES_DROPDOWN_SUCCESS:
      return { ...state, isBusy: false, enclosureDropdowns: action.payload };
    case actionTypes.GET_ENCLOSURES_DROPDOWN_FAIL:
      return { ...state, isBusy: false };

    // Helioscope
    case actionTypes.GET_HELIOSCOPE_PROJECTS_START:
      return { ...state, isBusy: true };
    case actionTypes.GET_HELIOSCOPE_PROJECTS_SUCCESS:
      return { ...state, isBusy: false, helioScopeProjects: action.payload };
    case actionTypes.GET_HELIOSCOPE_PROJECTS_FAIL:
      return { ...state, isBusy: false, helioScopeProjects: null };
    case actionTypes.GET_HELIOSCOPE_SIMULATIONS_START:
      return { ...state, isBusy: true };
    case actionTypes.GET_HELIOSCOPE_SIMULATIONS_SUCCESS:
      return { ...state, isBusy: false, helioScopeSimulations: action.payload };
    case actionTypes.GET_HELIOSCOPE_SIMULATIONS_FAIL:
      return { ...state, isBusy: false, helioScopeSimulations: null };
    case actionTypes.SET_HELIOSCOPE_PROJECTS_LIST_VISIBLE:
      return { ...state, helioScopeProjectsVisible: action.payload };
    case actionTypes.GET_HELIOSCOPE_DESIGNS_START:
      return { ...state, isBusy: true };
    case actionTypes.GET_HELIOSCOPE_DESIGNS_SUCCESS:
      return { ...state, isBusy: false, helioScopeDesigns: action.payload };
    case actionTypes.GET_HELIOSCOPE_DESIGNS_FAIL:
      return { ...state, isBusy: false, helioScopeDesigns: null };

    // Genability
    case actionTypes.GET_GENABILITY_TARIFFS_START:
      return { ...state, isBusy: true };
    case actionTypes.GET_GENABILITY_TARIFFS_SUCCESS:
      return { ...state, isBusy: false, tariffs: action.payload };
    case actionTypes.GET_GENABILITY_TARIFFS_FAIL:
      return { ...state, isBusy: false };

    case actionTypes.GET_GENABILITY_CHART_START:
      const genabilityCharts = state.financialCharts;
      if (genabilityCharts[action?.payload?.chartId]) {
        delete genabilityCharts[action.payload.chartId];
      }
      return { ...state, isBusy: true, financialCharts: genabilityCharts };
    case actionTypes.GET_GENABILITY_CHART_SUCCESS:
      let newGenabilityCharts = state.financialCharts;

      if (newGenabilityCharts[action?.payload?.chartId]) {
        newGenabilityCharts[action.payload.chartId] = { chartId: action.payload.chartId, data: action.payload.data };
      } else {
        const newChartData = { chartId: action.payload.chartId, data: action.payload.data };
        newGenabilityCharts = { ...newGenabilityCharts, newChartData };
      }
      newGenabilityCharts[action.payload.chartId] = action.payload.data;
      return { ...state, isBusy: false, financialCharts: newGenabilityCharts };
    case actionTypes.GET_GENABILITY_CHART_FAIL:
      return { ...state, isBusy: false, financialCharts: {} };
    case actionTypes.TARIFF_HISTORY_SEARCH_START:
      return { ...state, isBusy: true };
    case actionTypes.TARIFF_HISTORY_SEARCH_SUCCESS:
      return { ...state, isBusy: false, tariffHistory: action.payload };
    case actionTypes.TARIFF_HISTORY_SEARCH_FAIL:
      return { ...state, isBusy: false };
    case actionTypes.SET_VERSION_RIDER:
      return { ...state, version: action.payload.version, rider: action.payload.rider };
    case actionTypes.GET_RATES_VERSION_RIDER_START:
      return { ...state, isBusy: true };
    case actionTypes.GET_RATES_VERSION_RIDER_SUCCESS:
      return { ...state, isBusy: false, ratesVersionRider: action.payload };
    case actionTypes.GET_RATES_VERSION_RIDER_FAIL:
      return { ...state, isBusy: false };

    // Financials Calculations
    case actionTypes.GET_GENABILITY_CALCULATOR_START:
      return { ...state, isCalculationInProgress: true };
    case actionTypes.GET_GENABILITY_CALCULATOR_SUCCESS:
      return { ...state, isCalculationInProgress: false, calculationData: action.payload };
    case actionTypes.GET_GENABILITY_CALCULATOR_FAIL:
      return { ...state, isCalculationInProgress: false, calculationData: null };

    // Opportunity notes
    case actionTypes.GET_NOTES_BY_OPPORTUNITY_ID_START:
      return { ...state, isBusy: true };
    case actionTypes.GET_NOTES_BY_OPPORTUNITY_ID_SUCCESS:
      return { ...state, isBusy: false, opportunity: { ...state.opportunity, notes: action.payload } };
    case actionTypes.GET_NOTES_BY_OPPORTUNITY_ID_FAIL:
      return { ...state, isBusy: false };
    case actionTypes.POST_NOTE_START:
      return { ...state, isBusy: true };
    case actionTypes.POST_NOTE_SUCCESS:
      return { ...state, isBusy: false };
    case actionTypes.POST_NOTE_FAIL:
      return { ...state, isBusy: false };
    case actionTypes.DELETE_NOTE_START:
      return { ...state, isBusy: true };
    case actionTypes.DELETE_NOTE_SUCCESS:
      return { ...state, isBusy: false };
    case actionTypes.DELETE_NOTE_FAIL:
      return { ...state, isBusy: false };
    case actionTypes.PATCH_NOTE_START:
      return { ...state, isBusy: true };
    case actionTypes.PATCH_NOTE_FAIL:
      return { ...state, isBusy: false };
    case actionTypes.PATCH_NOTE_SUCCESS:
      return { ...state, isBusy: false };
    case actionTypes.SET_IS_SOME_CLIENT_LOAD_DATA_FILE_CHECKED:
      return {
        ...state,
        configDetails: { ...state.configDetails, isSomeClientLoadDataFileChecked: action.payload },
      };
    default:
      return state;
  }
};
