/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import useStyles from './styles';

const InputComponent = ({
  icon,
  placeholder,
  onChange,
  value,
  name,
  type,
  id,
  helperText,
  multiline,
  invalid,
  disabled,
  alignRight,
  onFocus,
  onBlur,
  inputProps,
  rows,
  autoFocus,
  textFieldStyle,
  inputClasses,
  autoComplete = 'new-password',
  required = false,
  endAdornment,
  dataTestId = '',
  fullWidth = false,
}) => {
  const classes = useStyles();
  const alignRightStyle = {
    textAlign: 'right',
    paddingRight: '0.7rem',
  };
  const inputStyles = alignRight ? alignRightStyle : {};

  return (
    <TextField
      multiline={multiline || false}
      error={invalid || false}
      fullWidth={fullWidth}
      name={name}
      id={id}
      onChange={onChange}
      value={value}
      type={type}
      helperText={invalid ? helperText : null}
      disabled={disabled || false}
      placeholder={placeholder}
      variant="outlined"
      onFocus={onFocus}
      onBlur={onBlur}
      rows={rows || 1}
      autoComplete={autoComplete}
      required={required}
      InputProps={{
        startAdornment: (
          <>
            {icon && (
              <InputAdornment className={classes.adornment}>
                <img className={classes.icon} src={icon} alt={placeholder} id="icon" />
              </InputAdornment>
            )}
          </>
        ),
        endAdornment,
        inputProps: {
          form: {
            autocomplete: 'off',
          },
          style: {
            marginLeft: '0.3rem',
            ...inputStyles,
          },
          'data-testid': dataTestId,
          ...inputProps,
        },
      }}
      classes={{ root: inputClasses }}
      autoFocus={autoFocus}
      style={textFieldStyle}
    />
  );
};

InputComponent.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  value: PropTypes.any,
  type: PropTypes.string,
  id: PropTypes.string,
  helperText: PropTypes.string,
  multiline: PropTypes.bool,
  invalid: PropTypes.bool,
  disabled: PropTypes.bool,
  alignRight: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  inputProps: PropTypes.object,
  rows: PropTypes.number,
  autoFocus: PropTypes.bool,
  autoComplete: PropTypes.string,
  required: PropTypes.bool,
  endAdornment: PropTypes.object,
  dataTestId: PropTypes.string,
  fullWidth: PropTypes.bool,
  textFieldStyle: PropTypes.object,
  inputClasses: PropTypes.string,
};

export default InputComponent;
