/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/function-component-definition */
/**
 * HeaderComponentManager.js
 * Header content for component Manager containers
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Input from '../../Input';
import images from '../../../assets/images';
import useStyles from './styles';
import messages from './messages';
import Button from '../../Button';

const TAB_ACTIVE = 'Active';
const TAB_INACTIVE = 'Inactive';

/**
 * HeaderComponentManager
 * @param {String} title='' Title to show in the header
 * @param {Number} tabSelected=0 Index of tab selected
 * @param {function} handleTabChange Function to execute when selecting tabs
 * @param {function} handleSearch Function to execute when searching cards
 * @param {function} handleSelectChange Function to execute when selecting a user
 * @param {function} isAdmin Flag that indicates if the current user is an admin
 */
const HeaderComponentManager = ({ title = '', tabSelected = 0, handleTabChange, handleSearch, onCreateNew, isAdmin }) => {
  const classes = useStyles();
  const [value, setValue] = useState(tabSelected);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleSearch(searchTerm);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchTerm]);

  /**
   * handleChange
   * @param {Object} event Event executed
   * @param {Number} newValue Index of table selected
   */
  const handleChange = (event, newValue) => {
    setValue(newValue);
    handleTabChange(newValue);
  };

  const handleCreateEssComponent = (event) => onCreateNew(event);

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.upperWrapper}>
        <Box className={classes.titleWrapper}>
          <Typography variant="h2" className={classes.leftSeparator}>
            {title}
          </Typography>
        </Box>
        <Box className={classes.searchWrapper}>
          <FormControl variant="outlined">
            <FormattedMessage {...messages.search}>
              {(placeholder) => (
                <Input
                  className={classes.searchInput}
                  placeholder={placeholder}
                  icon={images.searchIcon}
                  onChange={(evt) => setSearchTerm(evt.target.value)}
                  value={searchTerm}
                />
              )}
            </FormattedMessage>
          </FormControl>
          {isAdmin && (
            <div className={classes.buttonWrapper}>
              <Button secondary handleRoute={handleCreateEssComponent}>
                <FormattedMessage {...messages.createNew} />
              </Button>
            </div>
          )}
        </Box>
      </Box>
      <Box className={classes.lowerWrapper}>
        <Tabs value={value} onChange={handleChange} className={classes.leftSeparator}>
          <Tab className={classes.tab} label={TAB_ACTIVE} />
          <Tab className={classes.tab} label={TAB_INACTIVE} />
        </Tabs>
      </Box>
    </Box>
  );
};

HeaderComponentManager.propTypes = {
  title: PropTypes.string.isRequired,
  tabSelected: PropTypes.number.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  showActive: PropTypes.bool,
  onCreateNew: PropTypes.func,
  isAdmin: PropTypes.bool,
};

export default HeaderComponentManager;
