import { createTheme } from '@mui/material/styles';

const Colors = {
  white: '#ffffff',
  black: '#000000',
  jaggeIce: '#d0eedd',
  surfCrest: '#c8e4c8',
  tiber: '#062c3f',
  mountainMeadow: '#20c161',
  dustyGray: '#959595',
  athensGray: '#f3f6f8',
  silver: '#bfbfbf',
  hokeyPok: '#d8b031',
  havelockBlue: '#6383dd',
  mercury: '#d7dde3',
  lunarGreen: '#373a37',
  olivine: '#23df78',
  codGray: '#191919',
  goldenDream: '#ebcb39',
  christine: '#f36f13',
  thunderbird: '#c13017',
  pacificBlue: '#0b94bc',
  tarawera: '#063951',
  primaryLight: '#62d390',
  primaryDark: '#179048',
  secondaryLight: '#d2f3df',
  secondaryDark: '#a6e6c0',
  alizarinCrimson: '#db544a',
  rollingStone: '#737578',
  silverSand: '#b4bbbd',
  lynch: '#647f99',
  apple: '#43b542',
  aquaSpring: '#e9f4e9',
  sanJuan: '#285469',
  eucalyptus: '#299d5e',
  peachYellow: '#faeaa3',
  cadillac: '#af4a74',
  cancan: '#da96a6',
  buccaneer: '#5e2727',
  red: '#ff0000',
  burgundy: '#673147',
  diagramBackground: '#515e51',
  greyDisabled: '#bdbdbd',
};

const FeatTheme = createTheme({
  palette: {
    primary: {
      light: Colors.primaryLight,
      main: Colors.mountainMeadow,
      dark: Colors.primaryDark,
      contrastText: Colors.white,
    },
    error: {
      main: Colors.alizarinCrimson,
    },
  },
  typography: {
    htmlFontSize: 16,
    fontSize: 16,
    fontFamily: 'Inter, sans-serif',
    h1: {
      fontSize: '1.5rem',
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    h2: {
      fontSize: '1.25rem',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '-0.12px',
    },
    h3: {
      fontSize: '1rem',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '-0.1px',
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.5',
      letterSpacing: '-0.1px',
    },
    caption: {
      fontSize: '0.875rem',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '-0.1px',
      color: Colors.lunarGreen,
    },
    subtitle1: {
      fontSize: '1rem',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '-0.08px',
      color: Colors.mountainMeadow,
    },
    fontWeightBold: 600,
  },
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '2px',
          padding: '0',
          fontSize: '0.875rem',
        },
        containedPrimary: {
          height: 'auto',
          minHeight: '2.5rem',
          minWidth: '10rem',
          padding: '0rem 0.5rem',
          '.Mui-hover': {
            backgroundColor: Colors.primaryLight,
          },
          '.Mui-active': {
            backgroundColor: Colors.primaryDark,
          },
          '.Mui-disabled': {
            opacity: 0.2,
          },
        },
        outlinedPrimary: {
          height: 'auto',
          minHeight: '2.5rem',
          minWidth: '10rem',
          padding: '0rem 0.5rem',
          '.Mui-focused': {
            backgroundColor: Colors.secondaryLight,
          },
          '.Mui-hover': {
            backgroundColor: Colors.secondaryLight,
          },
          '.Mui-active': {
            backgroundColor: Colors.secondaryDark,
          },
          '.Mui-disabled': {
            opacity: 0.2,
          },
        },
        label: {
          padding: '0rem 0.5rem',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
        button: {
          color: Colors.mountainMeadow,
          textDecoration: 'underline',
          cursor: 'pointer',
          '&:hover': {
            color: Colors.primaryLight,
          },
          '&:active': {
            color: Colors.primaryDark,
          },
          '&:disabled': {
            color: Colors.surfCrest,
            cursor: 'unset',
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          border: 0,
          boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.06)',
          overflow: 'hidden',
          marginBottom: '0.5rem',
          '&:not(:last-child)': {
            borderBottom: 0,
          },
          '&:before': {
            display: 'none',
          },
          '&$expanded': {
            margin: '0 0 0.5rem 0',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          marginBottom: -1,
          minHeight: '3rem',
          '&$expanded': {
            minHeight: '3rem',
          },
          border: 0,
          padding: '0 1rem 0 .75rem',
        },
        content: {
          '&$expanded': {
            margin: '12px 0',
            border: 0,
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '.5rem 0.875rem 1rem',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        backgroundColor: Colors.athensGray,
        input: {
          padding: '0 0.75rem 0 0 ',
          color: Colors.lunarGreen,
          fontSize: '0.875rem',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: Colors.athensGray,
          '&.Mui-disabled': {
            backgroundColor: Colors.mercury,
          },
          '&:not(.MuiOutlinedInput-multiline)': {
            padding: 0,
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: Colors.dustyGray,
            },
            '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
              borderColor: Colors.silver,
            },
          },
        },
        notchedOutline: {
          borderWidth: '1px',
          borderStyle: 'solid',
          borderRadius: '4px',
        },
        adornedStart: {
          paddingLeft: '0.3rem',
        },
        input: {
          padding: '10.5px 7px',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter',
          fontFize: '0.875rem',
          fontWeight: 'normal',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 1.14,
          letterSpacing: 'normal',
        },
        contained: {
          marginTop: '0.125',
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          height: '2.5rem',
          minWidth: ' 0.125rem',
          color: Colors.surfCrest,
        },
        fixed: {
          height: '2.5rem',
          color: Colors.surfCrest,
        },
        indicator: {
          backgroundColor: Colors.mountainMeadow,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          height: '2.5rem',
          minHeight: '2.5rem',
          display: 'flex',
          flexDirection: 'row-reverse',
          color: Colors.surfCrest,
          '&$selected': {
            color: Colors.mountainMeadow,
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: Colors.white,
          border: 0,
          padding: '0',
          width: '2.912rem',
          height: '2.5rem',
          fontSize: '0.875rem',
          borderRadius: 2,
          textTransform: 'none',
          backgroundColor: Colors.silver,
          '&:hover': {
            backgroundColor: Colors.silver,
          },
          '&.Mui-selected': {
            color: Colors.white,
            backgroundColor: Colors.mountainMeadow,
            '&:hover': {
              backgroundColor: Colors.mountainMeadow,
            },
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paperWidthSm: {
          width: '39rem',
          maxWidth: '39rem',
        },
        paper: {
          margin: 0,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '1.5rem 2rem 1rem',
          fontFamily: 'Inter',
          fontSize: '1.5rem',
          fontWeight: 600,
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          letterSpacing: '-0.12px',
          color: Colors.lunarGreen,
          borderBottom: '1px solid',
          borderBottomColor: Colors.mercury,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '1rem 2rem !important',
          fontSize: '0.875rem',
          fontWeight: 'normal',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          letterSpacing: '-0.08px',
          color: Colors.lunarGreen,
          minHeight: '5rem',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '1.5rem 2rem',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: Colors.silverSand,
          '&$checked': {
            color: Colors.mountainMeadow,
          },
        },
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        avatar: {
          width: '1.25rem',
          height: '1.25rem',
          fontSize: '0.625rem',
          fontWeight: 'bold',
          letterSpacing: '-0.06px',
          textAlign: 'center',
          backgroundColor: Colors.mountainMeadow,
          marginLeft: '0.25em',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          '&.MuiSelect-outlined': {
            paddingRight: '3.312rem',
          },
        },
      },
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          minWidth: '1.25rem',
          paddingRight: '0.75rem',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color: Colors.white,
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          overflowX: 'inherit',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
      },
    },
  },
  props: {
    MuiButton: {
      disableRipple: true,
      disableElevation: true,
    },
    MuiCheckbox: {
      disableRipple: true,
    },
  },
});

export { FeatTheme, Colors };
