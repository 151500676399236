/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/function-component-definition */
/**
 *  Alert Modal
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import useStyles from './styles';
import Modal from '../Modal';
import Button from '../Button';

const AlertModal = ({
  title,
  message,
  acceptLabel,
  cancelLabel,
  onAccept,
  onCancel,
  size,
  isVisible,
  classes,
  description,
  descriptionComplement,
}) => (
  <FormattedMessage {...title}>
    {(titleValue) => (
      <Modal open={isVisible} onClose={onCancel} title={titleValue} paperPropsStyle={{ maxWidth: size }}>
        {message && <FormattedMessage {...message} />}
        {description && (
          <>
            <br /> <br />
            {onCancel && (
              <div className={classes.descriptionContent}>
                <FormattedMessage {...description} values={{ type: descriptionComplement || '' }} />
              </div>
            )}
          </>
        )}
        <>
          {acceptLabel && (
            <div className={classes.saveButton}>
              <Button handleRoute={onAccept} disableRipple={false}>
                <FormattedMessage {...acceptLabel} />
              </Button>
            </div>
          )}
          {cancelLabel && (
            <div className={classes.cancelButton}>
              <Button onClick={onCancel}>
                <FormattedMessage {...cancelLabel} />
              </Button>
            </div>
          )}
        </>
      </Modal>
    )}
  </FormattedMessage>
);

AlertModal.propTypes = {
  message: PropTypes.object,
  description: PropTypes.object,
  descriptionComplement: PropTypes.string,
  acceptLabel: PropTypes.object,
  cancelLabel: PropTypes.object,
  size: PropTypes.string,
  onCancel: PropTypes.func,
  onAccept: PropTypes.func,
  isVisible: PropTypes.bool,
  classes: PropTypes.object,
  title: PropTypes.object,
};

export default withStyles(useStyles)(AlertModal);
