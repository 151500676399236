export const GET_OPPORTUNITIES_START = 'GET_OPPORTUNITIES_START';
export const GET_OPPORTUNITIES_SUCCESS = 'GET_OPPORTUNITIES_SUCCESS';
export const GET_OPPORTUNITIES_FAIL = 'GET_OPPORTUNITIES_FAIL';

export const GET_OPPORTUNITY_DETAILS_START = 'GET_OPPORTUNITY_DETAILS_START';
export const GET_OPPORTUNITY_DETAILS_SUCCESS = 'GET_OPPORTUNITY_DETAILS_SUCCESS';
export const GET_OPPORTUNITY_DETAILS_FAIL = 'GET_OPPORTUNITY_DETAILS_FAIL';

export const GET_OPPORTUNITY_CONFIGS_START = 'GET_OPPORTUNITY_CONFIGS_START';
export const GET_OPPORTUNITY_CONFIGS_SUCCESS = 'GET_OPPORTUNITY_CONFIGS_SUCCESS';
export const GET_OPPORTUNITY_CONFIGS_FAIL = 'GET_OPPORTUNITY_CONFIGS_FAIL';

export const GET_OPPORTUNITY_CONFIG_DETAIL_START = 'GET_OPPORTUNITY_CONFIG_DETAIL_START';
export const GET_OPPORTUNITY_CONFIG_DETAIL_SUCCESS = 'GET_OPPORTUNITY_CONFIG_DETAIL_SUCCESS';
export const GET_OPPORTUNITY_CONFIG_DETAIL_FAIL = 'GET_OPPORTUNITY_CONFIG_DETAIL_FAIL';

export const SET_OPPORTUNITY_SETTINGS_DETAIL = 'SET_OPPORTUNITY_SETTINGS_DETAIL';
export const SET_MISSING_CONFIGURATION_LIST = 'SET_MISSING_CONFIGURATION_LIST';

export const PATCH_OPPORTUNITY_CONFIG_DETAIL_START = 'PATCH_OPPORTUNITY_CONFIG_DETAIL_START';
export const PATCH_OPPORTUNITY_CONFIG_DETAIL_SUCCESS = 'PATCH_OPPORTUNITY_CONFIG_DETAIL_SUCCESS';
export const PATCH_OPPORTUNITY_CONFIG_DETAIL_FAIL = 'PATCH_OPPORTUNITY_CONFIG_DETAIL_FAIL';

export const DELETE_OPPORTUNITY_CONFIG_DETAIL_START = 'DELETE_OPPORTUNITY_CONFIG_DETAIL_START';
export const DELETE_OPPORTUNITY_CONFIG_DETAIL_SUCCESS = 'DELETE_OPPORTUNITY_CONFIG_DETAIL_SUCCESS';
export const DELETE_OPPORTUNITY_CONFIG_DETAIL_FAIL = 'DELETE_OPPORTUNITY_CONFIG_DETAIL_FAIL';

export const SET_HAS_UNSAVED_DATA_FLAG = 'SET_HAS_UNSAVED_DATA_FLAG';

export const SET_NAVIGATE_TO = 'SET_NAVIGATE_TO';
export const SET_CURRENT_CONFIG_SCREEN = 'SET_CURRENT_CONFIG_SCREEN';

export const GET_USERS_START = 'GET_USERS_START';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';

export const POST_OPPORTUNITY_START = 'POST_OPPORTUNITY_START';
export const POST_OPPORTUNITY_SUCCESS = 'POST_OPPORTUNITY_SUCCESS';
export const POST_OPPORTUNITY_FAIL = 'POST_OPPORTUNITY_FAIL';

export const PATCH_OPPORTUNITY_DETAIL_START = 'PATCH_OPPORTUNITY_DETAIL_START';
export const PATCH_OPPORTUNITY_DETAIL_SUCCESS = 'PATCH_OPPORTUNITY_DETAIL_SUCCESS';
export const PATCH_OPPORTUNITY_DETAIL_FAIL = 'PATCH_OPPORTUNITY_DETAIL_FAIL';

export const GET_OPPORTUNITY_CLIENT_LOAD_FILES_START = 'GET_OPPORTUNITY_CLIENT_LOAD_FILES_START';
export const GET_OPPORTUNITY_CLIENT_LOAD_FILES_SUCCESS = 'GET_OPPORTUNITY_CLIENT_LOAD_FILES_SUCCESS';
export const GET_OPPORTUNITY_CLIENT_LOAD_FILES_FAIL = 'GET_OPPORTUNITY_CLIENT_LOAD_FILES_FAIL';
export const POST_OPPORTUNITY_CLIENT_LOAD_DATA_FILES_START = 'POST_OPPORTUNITY_CLIENT_LOAD_DATA_FILES_START';
export const POST_OPPORTUNITY_CLIENT_LOAD_DATA_FILES_SUCCESS = 'POST_OPPORTUNITY_CLIENT_LOAD_DATA_FILES_SUCCESS';
export const POST_OPPORTUNITY_CLIENT_LOAD_DATA_FILES_FAIL = 'POST_OPPORTUNITY_CLIENT_LOAD_DATA_FILES_FAIL';
export const DELETE_OPPORTUNITY_CLIENT_LOAD_FILES_START = 'DELETE_OPPORTUNITY_CLIENT_LOAD_FILES_START';
export const DELETE_OPPORTUNITY_CLIENT_LOAD_FILES_SUCCESS = 'DELETE_OPPORTUNITY_CLIENT_LOAD_FILES_SUCCESS';
export const DELETE_OPPORTUNITY_CLIENT_LOAD_FILES_FAIL = 'DELETE_OPPORTUNITY_CLIENT_LOAD_FILES_FAIL';
export const PATCH_OPPORTUNITY_CLIENT_LOAD_FILES_START = 'PATCH_OPPORTUNITY_CLIENT_LOAD_FILES_START';
export const PATCH_OPPORTUNITY_CLIENT_LOAD_FILES_SUCCESS = 'PATCH_OPPORTUNITY_CLIENT_LOAD_FILES_SUCCESS';
export const PATCH_OPPORTUNITY_CLIENT_LOAD_FILES_FAIL = 'PATCH_OPPORTUNITY_CLIENT_LOAD_FILES_FAIL';

export const MOVE_QUICKCONFIG_OPPORTUNITIES_START = 'MOVE_QUICKCONFIG_OPPORTUNITIES_START';
export const MOVE_QUICKCONFIG_OPPORTUNITIES_SUCCESS = 'MOVE_QUICKCONFIG_OPPORTUNITIES_SUCCESS';
export const MOVE_QUICKCONFIG_OPPORTUNITIES_FAIL = 'MOVE_QUICKCONFIG_OPPORTUNITIES_FAIL';

export const GET_GEOCODE_VALIDATION_START = 'GET_GEOCODE_VALIDATION_START';
export const GET_GEOCODE_VALIDATION_SUCCESS = 'GET_GEOCODE_VALIDATION_SUCCESS';
export const GET_GEOCODE_VALIDATION_FAIL = 'GET_GEOCODE_VALIDATION_FAIL';

export const POST_OPPORTUNITY_CONFIGS_START = 'POST_OPPORTUNITY_CONFIGS_START';
export const POST_OPPORTUNITY_CONFIGS_SUCCESS = 'POST_OPPORTUNITY_CONFIGS_SUCCESS';
export const POST_OPPORTUNITY_CONFIGS_FAIL = 'POST_OPPORTUNITY_CONFIGS_FAIL';
export const DELETE_OPPORTUNITY_CONFIGS_START = 'DELETE_OPPORTUNITY_CONFIGS_START';
export const DELETE_OPPORTUNITY_CONFIGS_SUCCESS = 'DELETE_OPPORTUNITY_CONFIGS_SUCCESS';
export const DELETE_OPPORTUNITY_CONFIGS_FAIL = 'DELETE_OPPORTUNITY_CONFIGS_FAIL';

export const GET_OPPORTUNITY_ENERGY_DATA_FILES_START = 'GET_OPPORTUNITY_ENERGY_DATA_FILES_START';
export const GET_OPPORTUNITY_ENERGY_DATA_FILES_SUCCESS = 'GET_OPPORTUNITY_ENERGY_DATA_FILES_SUCCESS';
export const GET_OPPORTUNITY_ENERGY_DATA_FILES_FAIL = 'GET_OPPORTUNITY_ENERGY_DATA_FILES_FAIL';
export const POST_OPPORTUNITY_ENERGY_DATA_FILES_START = 'POST_OPPORTUNITY_ENERGY_DATA_FILES_START';
export const POST_OPPORTUNITY_ENERGY_DATA_FILES_SUCCESS = 'POST_OPPORTUNITY_ENERGY_DATA_FILES_SUCCESS';
export const POST_OPPORTUNITY_ENERGY_DATA_FILES_FAIL = 'POST_OPPORTUNITY_ENERGY_DATA_FILES_FAIL';
export const DELETE_OPPORTUNITY_ENERGY_DATA_FILES_START = 'DELETE_OPPORTUNITY_ENERGY_DATA_FILES_START';
export const DELETE_OPPORTUNITY_ENERGY_DATA_FILES_SUCCESS = 'DELETE_OPPORTUNITY_ENERGY_DATA_FILES_SUCCESS';
export const DELETE_OPPORTUNITY_ENERGY_DATA_FILES_FAIL = 'DELETE_OPPORTUNITY_ENERGY_DATA_FILES_FAIL';
export const PATCH_OPPORTUNITY_ENERGY_DATA_FILES_START = 'PATCH_OPPORTUNITY_ENERGY_DATA_FILES_START';
export const PATCH_OPPORTUNITY_ENERGY_DATA_FILES_SUCCESS = 'PATCH_OPPORTUNITY_ENERGY_DATA_FILES_SUCCESS';
export const PATCH_OPPORTUNITY_ENERGY_DATA_FILES_FAIL = 'PATCH_OPPORTUNITY_ENERGY_DATA_FILES_FAIL';

export const CLEAR_CLIENT_LOAD_DATA = 'CLEAR_CLIENT_LOAD_DATA';
export const CLEAR_ENERGY_SOURCE_DATA = 'CLEAR_ENERGY_SOURCE_DATA';

export const SET_HAS_CHANGES = 'SET_HAS_CHANGES';
export const SET_HAS_TARIFF_CHANGES = 'SET_HAS_TARIFF_CHANGES';

export const GET_ENCLOSURES_DROPDOWN_START = 'GET_ENCLOSURES_DROPDOWN_START';
export const GET_ENCLOSURES_DROPDOWN_SUCCESS = 'GET_ENCLOSURES_DROPDOWN_SUCCESS';
export const GET_ENCLOSURES_DROPDOWN_FAIL = 'GET_ENCLOSURES_DROPDOWN_FAIL';

// Helioscope
export const GET_HELIOSCOPE_PROJECTS_START = 'GET_HELIOSCOPE_PROJECTS_START';
export const GET_HELIOSCOPE_PROJECTS_SUCCESS = 'GET_HELIOSCOPE_PROJECTS_SUCCESS';
export const GET_HELIOSCOPE_PROJECTS_FAIL = 'GET_HELIOSCOPE_PROJECTS_FAIL';

export const GET_HELIOSCOPE_SIMULATIONS_START = 'GET_HELIOSCOPE_SIMULATIONS_START';
export const GET_HELIOSCOPE_SIMULATIONS_SUCCESS = 'GET_HELIOSCOPE_SIMULATIONS_SUCCESS';
export const GET_HELIOSCOPE_SIMULATIONS_FAIL = 'GET_HELIOSCOPE_SIMULATIONS_FAIL';

export const SET_HELIOSCOPE_PROJECTS_LIST_VISIBLE = 'SET_HELIOSCOPE_PROJECTS_LIST_VISIBLE';

export const GET_HELIOSCOPE_DESIGNS_START = 'GET_HELIOSCOPE_DESIGNS_START';
export const GET_HELIOSCOPE_DESIGNS_SUCCESS = 'GET_HELIOSCOPE_DESIGNS_SUCCESS';
export const GET_HELIOSCOPE_DESIGNS_FAIL = 'GET_HELIOSCOPE_DESIGNS_FAIL';

export const GET_FILE_TEMPLATE_START = 'GET_FILE_TEMPLATE_START';
export const GET_FILE_TEMPLATE_SUCCESS = 'GET_FILE_TEMPLATE_SUCCES';
export const GET_FILE_TEMPLATE_FAIL = 'GET_FILE_TEMPLATE_FAIL';

// Genability Tariffs Search
export const GET_GENABILITY_TARIFFS_START = 'GET_GENABILITY_TARIFFS_START';
export const GET_GENABILITY_TARIFFS_SUCCESS = 'GET_GENABILITY_TARIFFS_SUCCESS';
export const GET_GENABILITY_TARIFFS_FAIL = 'GET_GENABILITY_TARIFFS_FAIL';
export const GET_GENABILITY_CHART_START = 'GET_GENABILITY_CHART_START';
export const GET_GENABILITY_CHART_FAIL = 'GET_GENABILITY_CHART_FAIL';
export const GET_GENABILITY_CHART_SUCCESS = 'GET_GENABILITY_CHART_SUCCESS';

export const GET_GENABILITY_CALCULATOR_START = 'GET_GENABILITY_CALCULATOR_START';
export const GET_GENABILITY_CALCULATOR_FAIL = 'GET_GENABILITY_CALCULATOR_FAIL';
export const GET_GENABILITY_CALCULATOR_SUCCESS = 'GET_GENABILITY_CALCULATOR_SUCCESS';

export const TARIFF_HISTORY_SEARCH_START = 'TARIFF_HISTORY_SEARCH_START';
export const TARIFF_HISTORY_SEARCH_SUCCESS = 'TARIFF_HISTORY_SEARCH_SUCCESS';
export const TARIFF_HISTORY_SEARCH_FAIL = 'TARIFF_HISTORY_SEARCH_FAIL';

export const SET_VERSION_RIDER = 'SET_VERSION_RIDER';

export const GET_RATES_VERSION_RIDER_START = 'GET_RATES_VERSION_RIDER_START';
export const GET_RATES_VERSION_RIDER_SUCCESS = 'GET_RATES_VERSION_RIDER_SUCCESS';
export const GET_RATES_VERSION_RIDER_FAIL = 'GET_RATES_VERSION_RIDER_FAIL';

// Opportunity Notes
export const GET_NOTES_BY_OPPORTUNITY_ID_START = 'GET_NOTES_BY_OPPORTUNITY_ID_START';
export const GET_NOTES_BY_OPPORTUNITY_ID_SUCCESS = 'GET_NOTES_BY_OPPORTUNITY_ID_SUCCESS';
export const GET_NOTES_BY_OPPORTUNITY_ID_FAIL = 'GET_NOTES_BY_OPPORTUNITY_ID_FAIL';

export const POST_NOTE_START = 'POST_NOTE_START';
export const POST_NOTE_SUCCESS = 'POST_NOTE_SUCCESS';
export const POST_NOTE_FAIL = 'POST_NOTE_FAIL';

export const DELETE_NOTE_START = 'DELETE_NOTE_START';
export const DELETE_NOTE_SUCCESS = 'DELETE_NOTE_SUCCESS';
export const DELETE_NOTE_FAIL = 'DELETE_NOTE_FAIL';

export const PATCH_NOTE_START = 'PATCH_NOTE_START';
export const PATCH_NOTE_FAIL = 'PATCH_NOTE_FAIL';
export const PATCH_NOTE_SUCCESS = 'PATCH_NOTE_SUCCESS';
export const REQUEST_PROCESS_START = 'REQUEST_PROCESS_START';
export const REQUEST_PROCESS_SUCCESS = 'REQUEST_PROCESS_SUCCESS';
export const REQUEST_PROCESS_FAIL = 'REQUEST_PROCESS_FAIL';

export const SET_IS_SOME_CLIENT_LOAD_DATA_FILE_CHECKED = 'SET_IS_SOME_CLIENT_LOAD_DATA_FILE_CHECKED';

export const SET_CACHED_UTILITY = 'SET_CACHED_UTILITY';
