/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/function-component-definition */
/**
 *
 * HeaderDetails.js
 * Header content for details
 */

import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { FormattedMessage } from 'react-intl';
import { Colors } from '../../../theme';
import Breadcrumbs from '../../Breadcrumbs';
import configurationStatus from '../../../utils/configurationStatus';
import useStyles from './styles';
import Select from '../../Select';
import images from '../../../assets/images';
import SimpleSwitch from '../../SimpleSwitch';
import messages from '../messages';

const sentToIcianOptions = [
  {
    text: configurationStatus.underContract,
    value: configurationStatus.underContract,
    color: Colors.tarawera,
    selected: false,
    chevron: images.chevronIcon,
  },
  {
    text: configurationStatus.sentToIcian,
    value: configurationStatus.sentToIcian,
    color: Colors.havelockBlue,
    selected: false,
    chevron: images.chevronPurpleIcon,
  },
];

const opportunityStatus = [
  {
    text: configurationStatus.new,
    value: configurationStatus.new,
    color: Colors.mountainMeadow,
    selected: false,
    chevron: images.chevronGreenIcon,
  },
  {
    text: configurationStatus.inProgress,
    value: configurationStatus.inProgress,
    color: Colors.hokeyPok,
    selected: false,
    chevron: images.chevronYellowIcon,
  },
  {
    text: configurationStatus.inReview,
    value: configurationStatus.inReview,
    color: Colors.alizarinCrimson,
    selected: false,
    chevron: images.chevronRedIcon,
  },
  {
    text: configurationStatus.underContract,
    value: configurationStatus.underContract,
    color: Colors.tarawera,
    selected: false,
    chevron: images.chevronIcon,
  },
  {
    text: configurationStatus.lost,
    value: configurationStatus.lost,
    color: Colors.rollingStone,
    selected: false,
    chevron: images.chevronGreyIcon,
  },
  {
    text: configurationStatus.archive,
    value: configurationStatus.archiveValue,
    color: Colors.alizarinCrimson,
    selected: false,
    chevron: images.chevronRedIcon,
  },
  {
    text: configurationStatus.sentToIcian,
    value: configurationStatus.sentToIcian,
    color: Colors.havelockBlue,
    selected: false,
    chevron: images.chevronPurpleIcon,
  },
];

/**
 *
 * @param {string} title Title to show
 * @param {string} subtitle1 Subtitle to show
 * @param {object} breadcrumbs Breadcrumbs to show
 * @param {Object[]{text:'',value:''}} status Status to show in list
 */
const HeaderDetails = ({
  title = '',
  subtitle1 = '',
  breadcrumbs = [],
  selectedStatus = '',
  onStatusChange,
  isAdminOrEngineer,
  isPrivateOpportunity = false,
  isTestOpportunity = false,
  handleOpportunityFlagsChange,
  disableOpportunityChanges = false,
}) => {
  const classes = useStyles();

  let statusColor = '';

  switch (selectedStatus) {
    case configurationStatus.new:
      statusColor = Colors.mountainMeadow;
      break;
    case configurationStatus.inProgress:
      statusColor = Colors.hokeyPok;
      break;
    case configurationStatus.inReview:
      statusColor = Colors.alizarinCrimson;
      break;
    case configurationStatus.underContract:
      statusColor = Colors.tarawera;
      break;
    case configurationStatus.sentToIcian:
      statusColor = Colors.havelockBlue;
      break;
    case configurationStatus.archive:
    case configurationStatus.archiveValue:
      statusColor = Colors.alizarinCrimson;
      break;
    case configurationStatus.lost:
      statusColor = Colors.rollingStone;
      break;
    default:
      statusColor = Colors.black;
  }

  /**
   * Handle Dropdown select status
   * @param {Object} status Selected status
   */
  const handleSelectStatus = (status) => {
    onStatusChange(status);
  };

  const statusList = selectedStatus === configurationStatus.underContract ? sentToIcianOptions : opportunityStatus;
  const isToggleDisabled = disableOpportunityChanges || !isAdminOrEngineer;
  const foundObject = statusList.find((element) => element.value === selectedStatus);
  return (
    <div className={classes.container}>
      <div className={classes.testContainer}>
        <div className={classes.titleWrapper}>
          <Tooltip title={title}>
            <Typography variant="h2" className={classes.opporunityName}>
              {title}
            </Typography>
          </Tooltip>
          {isAdminOrEngineer && selectedStatus && (
            <Select selectedObject={foundObject} selectData={statusList} onChange={handleSelectStatus} status />
          )}
          {!isAdminOrEngineer && (
            <Typography className={classes.statusView} style={{ backgroundColor: statusColor }}>
              {selectedStatus.toUpperCase()}
            </Typography>
          )}
        </div>
        <div className={classes.toggleWrapper}>
          <div className={classes.privateContainer}>
            <Typography
              variant="caption"
              className={[classes.toggleButtonLabel, isToggleDisabled ? classes.toggleButtonLabelDisabled : '']}
            >
              <FormattedMessage {...messages.privateOpportunityFlagLabel} />
            </Typography>
            <SimpleSwitch
              disabled={isToggleDisabled}
              key="private-toggle"
              onClick={() => handleOpportunityFlagsChange('isPrivate', !isPrivateOpportunity)}
              checked={isPrivateOpportunity}
            />
          </div>
          <div className={classes.testOpportunityToggle}>
            <Typography
              variant="caption"
              className={[classes.toggleButtonLabel, isToggleDisabled ? classes.toggleButtonLabelDisabled : '']}
            >
              <FormattedMessage {...messages.testOpportunityFlagLabel} />
            </Typography>
            <SimpleSwitch
              disabled={isToggleDisabled}
              key="test-toggle"
              onClick={() => handleOpportunityFlagsChange('isTesting', !isTestOpportunity)}
              checked={isTestOpportunity}
            />
          </div>
        </div>
      </div>
      <Typography variant="subtitle1" className={classes.subtitle}>
        {subtitle1}
      </Typography>
      <div className={classes.lowerWrapper}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </div>
    </div>
  );
};

HeaderDetails.propTypes = {
  title: PropTypes.string,
  subtitle1: PropTypes.string,
  breadcrumbs: PropTypes.array,
  selectedStatus: PropTypes.string,
  onStatusChange: PropTypes.func,
  isAdminOrEngineer: PropTypes.bool,
  isPrivateOpportunity: PropTypes.bool,
  isTestOpportunity: PropTypes.bool,
  handleOpportunityFlagsChange: PropTypes.func,
  disableOpportunityChanges: PropTypes.bool,
};

export default HeaderDetails;
