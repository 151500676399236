/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
/**
 *
 * App.js
 *
 */

import React, { Component } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { GlobalStyles } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';
import axios from 'axios';
import {
  setAuthenticatedUser,
  clearAuthenticatedUser,
  getNotifications,
  dismissAllNotifications,
  dismissSingleNotification,
  viewSingleNotification,
  getUserGroups,
  getUserGroupList,
} from './actions';
import OpportunityDashboardPage from '../Opportunities/Dashboard/Loadable';
import OpportunityDetailPage from '../Opportunities/OpportunityDetail/Loadable';
import OpportunityConfigurationPage from '../Opportunities/Configuration/Loadable';
import QuickConfigurationsDashboardPage from '../QuickConfigurations/Dashboard/Loadable';
import ComponentManager from '../ComponentManager/ComponentTypeContainer';
import ComponentManagerPage from '../ComponentManager/Home';
import NotFoundPage from '../NotFoundPage/Loadable';
import NoAccessPage from '../NoAccessPage/Loadable';

import GlobalStyle from '../../global-styles';
import messages from './messages';
import packageJson from '../../../package.json';

// Material-UI CSS reset file

import AppBarComponent from '../../components/AppBar';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import NavigationDrawer from './NavigationDrawer';

import { AuthService } from '../../service/authService';
import { FeatTheme } from '../../theme';

import styles from './styles';

class Root extends Component {
  constructor() {
    super();
    this.state = {
      notificationsError: false,
      notificationList: [],
      isSignOutModalVisible: false,
    };
    // Initialize OAuth service
    this.authService = new AuthService();
  }

  componentDidMount() {
    const { notifications } = this.props;
    this.setState({ notificationList: notifications });

    // Register Callbacks for Redirect flow
    this.authService
      .handleRedirectPromise()
      .then((response) => {
        this.handleAuthenticationResponse(response);
      })
      .catch((error) => {
        this.setState({ notificationsError: error });
      });
  }

  componentDidUpdate() {
    const { notificationList } = this.state;
    const { notifications } = this.props;
    if (notifications && notificationList !== notifications) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ notificationList: notifications });
    }
  }

  /**
   * userHasAccess
   * Method that evaluates if the logged in user has access to FEAT Web app based on the users role (group)
   */
  userHasAccess = () => {
    const { userGroups, userGroupList } = this.props;
    let response = false;

    Object.keys(userGroupList).forEach((key) => {
      if (userGroupList[key] === userGroups) {
        response = true;
      }
    });

    return response;
  };

  /**
   * handleAuthenticationResponse
   * Handle the redirect response
   * @param {object} resp Response object
   */
  handleAuthenticationResponse = (resp) => {
    const { authService } = this; // Closure variable

    axios.defaults.timeout = 0;

    // Set http interceptor to add auth token before every request
    axios.interceptors.request.use(async (req) =>
      authService.acquireToken().then((apiTokenResp) => {
        req.headers.authorization = `Bearer ${apiTokenResp.accessToken}`;
        return Promise.resolve(req);
      }),
    );

    if (resp !== null) {
      axios.interceptors.request.use(async (req) => {
        const username = resp.account.username.split('@')[0];
        req.headers['X-Requested-user'] = username;
        return Promise.resolve(req);
      });
      this.signInUser(resp.account);
    } else {
      const currentAccounts = this.authService.getAllAccounts();

      if (!currentAccounts || currentAccounts.length < 1) {
        this.authService.signIn();
      } else if (currentAccounts.length >= 1) {
        axios.interceptors.request.use(async (req) => {
          const username = currentAccounts[0].username.split('@')[0];
          req.headers['X-Requested-user'] = username;
          return Promise.resolve(req);
        });
        this.signInUser(currentAccounts[0]);
      }
    }
  };

  /**
   * signInUser
   * Signs in the user. Sets the Authenticated user, gets their notifications and user groups
   * @param {object} account User account
   */
  signInUser = (account) => {
    const username = account.username.split('@')[0];

    this.props.setAuthenticatedUser(account);
    this.props.getUserGroupList().then(() => {
      this.props.getUserGroups().then(() => {
        if (this.userHasAccess()) {
          this.props
            .getNotifications(username)
            .then((response) => {
              this.setState({ notificationList: response });
            })
            .catch((error) => {
              this.setState({ notificationsError: error.response.data.messages });
            });
        }
      });
    });
  };

  /**
   * signOut
   * Signs out the user
   */
  signOut = () => {
    const { user } = this.props;

    this.authService.signOut(user.homeAccountId);

    this.props.clearAuthenticatedUser();
  };

  /**
   * handleSignOutClick
   * Handles Sign Out click handler
   */
  handleSignOutClick = () => {
    this.setState({ isSignOutModalVisible: true });
  };

  /**
   * handleViewNotificationClick
   * Handles User click on view Notification
   * @param {string} opportunityId Opportunity Id
   * @param {string} notificationUrl Notification URL
   */
  handleViewNotificationClick = (opportunityId, notificationUrl) => {
    const { history } = this.props;
    this.props
      .viewSingleNotification(notificationUrl)
      .then(() => {
        history.push({
          pathname: `/opportunity/${opportunityId}`,
        });
      })
      .catch((error) => {
        this.setState({ notificationsError: error.response.data.messages });
      });
  };

  /**
   * handleDismissAllClick
   * Dismiss all notifications click handler
   */
  handleDismissAllClick = () => {
    const { user } = this.props;
    const username = user.username.split('@')[0];
    this.props.dismissAllNotifications(username).then(() => {
      this.props.getNotifications(username);
      this.setState({ notificationList: [] });
    });
  };

  /**
   * handleDismissClick
   * Dismiss selected notification click handler
   */
  handleDismissClick = (notificationUserId) => {
    const { notificationList } = this.state;
    const { user } = this.props;
    const clonedNotifications = [...notificationList];
    const username = user.username.split('@')[0];
    this.props
      .dismissSingleNotification(notificationUserId)
      .then(() => {
        const foundNotification = notificationList.findIndex((notification) => notification.notificationUserId === notificationUserId);
        clonedNotifications.splice(foundNotification, 1);
        this.props.getNotifications(username);
        this.setState((prevState) => ({
          ...prevState,
          notificationList: clonedNotifications,
        }));
      })
      .catch((error) => {
        this.setState({ notificationsError: error.response.data.messages });
      });
  };

  /**
   * renderSignOutDialog
   * Renders Sign Out Dialog
   */
  renderSignOutDialog = () => {
    const { formatMessage } = this.props.intl;
    const { hasChanges } = this.props;
    const bodyText = hasChanges ? messages.signOutUnsavedChangesBody : messages.signOutBody;

    return (
      <Modal open title={formatMessage(messages.signOutTitle)}>
        <Typography variant="caption">{formatMessage(bodyText)}</Typography>
        <div style={styles.buttonsModal}>
          <Button onClick={() => this.setState({ isSignOutModalVisible: false })}>
            <Typography variant="caption" style={styles.cancelModal}>
              {formatMessage(messages.no)}
            </Typography>
          </Button>
          <Button handleRoute={this.signOut}>{formatMessage(messages.yes)}</Button>
        </div>
      </Modal>
    );
  };

  /**
   * renderModalError
   * Show custom error message modal
   * @param {string} notificationsError Text to show the modal
   */
  renderModalError = (notificationsError) => (
    <FormattedMessage {...messages.errorModalTitle}>
      {(title) => (
        <Modal open={!!notificationsError} onClose={() => this.setState({ notificationsError: '' })} title={title}>
          <Typography variant="caption">{notificationsError}</Typography>
          <Button secondary={false} handleRoute={() => this.setState({ notificationsError: '' })}>
            <FormattedMessage {...messages.ok}>{(ok) => ok}</FormattedMessage>
          </Button>
        </Modal>
      )}
    </FormattedMessage>
  );

  /**
   * renderNoAccess
   * Renders No Access Page Screen
   */
  renderNoAccess() {
    const { isSignOutModalVisible } = this.state;

    return (
      <>
        {isSignOutModalVisible && this.renderSignOutDialog()}
        <AppBarComponent hideNotifications onLogoutClick={this.handleSignOutClick} />
        <NoAccessPage />
      </>
    );
  }

  /**
   * renderApp
   * Renders App since user is authorized and belongs to a valid role (group)
   */
  renderApp = () => {
    const { notificationList } = this.state;
    const { notificationsError, isSignOutModalVisible } = this.state;

    return (
      <>
        {notificationsError && this.renderModalError(notificationsError)}
        {isSignOutModalVisible && this.renderSignOutDialog()}
        <AppBarComponent
          notifications={notificationList}
          onLogoutClick={this.handleSignOutClick}
          onViewNotificationClick={this.handleViewNotificationClick}
          onDismissAllClick={this.handleDismissAllClick}
          onDismissClick={this.handleDismissClick}
        />
        <NavigationDrawer version={packageJson.version} />
        <div style={styles.bodyWrapper}>
          <BrowserRouter>
            <Switch>
              <Route exact path="/" component={OpportunityDashboardPage} />
              <Route exact path="/opportunity/:opportunityId" component={OpportunityDetailPage} />
              <Route exact path="/opportunity/:opportunityId/configuration/:configurationId" component={OpportunityConfigurationPage} />
              <Route exact path="/quickconfigurations" component={QuickConfigurationsDashboardPage} />
              <Route exact path="/quickconfigurations/:quickConfigurationId" component={OpportunityConfigurationPage} />
              <Route exact path="/componentmanager" component={ComponentManagerPage} />
              <Route exact path="/componentmanager/:componentsType" component={ComponentManager} />
              <Route element={NotFoundPage} />
            </Switch>
          </BrowserRouter>
        </div>
      </>
    );
  };

  render() {
    const { user, userGroups } = this.props;
    const hasAccess = this.userHasAccess();
    const appGlobalStyles = <GlobalStyles styles={GlobalStyle} />;

    if (user && userGroups) {
      return (
        <>
          {appGlobalStyles}
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={FeatTheme}>
              {hasAccess && this.renderApp()}
              {!hasAccess && this.renderNoAccess()}
            </ThemeProvider>
          </StyledEngineProvider>
          <CssBaseline />
        </>
      );
    }

    return <div />;
  }
}

Root.propTypes = {
  setAuthenticatedUser: PropTypes.func,
  clearAuthenticatedUser: PropTypes.func,
  user: PropTypes.object,
  getNotifications: PropTypes.func,
  dismissAllNotifications: PropTypes.func,
  notifications: PropTypes.array,
  history: PropTypes.object,
  dismissSingleNotification: PropTypes.func,
  viewSingleNotification: PropTypes.func,
  getUserGroups: PropTypes.func,
  hasChanges: PropTypes.bool,
  intl: PropTypes.object,
  userGroups: PropTypes.string,
  getUserGroupList: PropTypes.func,
  userGroupList: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { user, notifications, userGroups, userGroupList } = state.auth;
  const { hasChanges } = state.opportunity;
  return { user, notifications, hasChanges, userGroups, userGroupList };
};

export default connect(mapStateToProps, {
  setAuthenticatedUser,
  clearAuthenticatedUser,
  getNotifications,
  dismissAllNotifications,
  dismissSingleNotification,
  viewSingleNotification,
  getUserGroups,
  getUserGroupList,
})(injectIntl(Root));
