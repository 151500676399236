/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-props-no-spreading */
/**
 * Form modal for components configurations
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import useStyles from './styles';
import { componentTypes } from '../../utilities';
import SolarInverterModal from '../SolarInverterModal';
import SolarConverterModal from '../SolarConverterModal';
import ESSConverterModal from '../ESSConverterModal';
import ESSInverterModal from '../ESSInverterModal';
import EVModal from '../EVModal';
import EngineModal from '../EngineModal';
import ESSBatteryModal from '../ESSBatteryModal';
import FleetModal from '../FleetModal';
import EnclosureModal from '../EnclosureModal';
import DCACInverterModal from '../DCACInverterModal';
import EnclosureComponentModal from '../EnclosureComponentModal';

const CreateNewComponentModal = ({ isModalVisible, readonly, defaultData, componentsType, onCancel, onAccept }) => {
  let currentComponentModal;
  if (componentsType) {
    const currentComponentProps = {
      isModalVisible,
      readonly,
      onCancel: () => onCancel(),
      onAccept: (newComponentData) => onAccept(newComponentData),
      defaultData,
    };
    switch (componentsType) {
      case componentTypes.SOLAR_INVERTER:
        currentComponentModal = <SolarInverterModal {...currentComponentProps} />;
        break;
      case componentTypes.SOLAR_CONVERTER:
        currentComponentModal = <SolarConverterModal {...currentComponentProps} />;
        break;
      case componentTypes.ESS_CONVERTER:
        currentComponentModal = <ESSConverterModal {...currentComponentProps} />;
        break;
      case componentTypes.ESS_INVERTER:
        currentComponentModal = <ESSInverterModal {...currentComponentProps} />;
        break;
      case componentTypes.EV:
        currentComponentModal = <EVModal {...currentComponentProps} />;
        break;
      case componentTypes.ENGINE:
        currentComponentModal = <EngineModal {...currentComponentProps} />;
        break;
      case componentTypes.ESS_BATTERY:
        currentComponentModal = <ESSBatteryModal {...currentComponentProps} />;
        break;
      case componentTypes.FLEET:
        currentComponentModal = <FleetModal {...currentComponentProps} />;
        break;
      case componentTypes.ENCLOSURE:
        currentComponentModal = <EnclosureModal {...currentComponentProps} />;
        break;
      case componentTypes.ENCLOSURE_COMPONENT:
        currentComponentModal = <EnclosureComponentModal {...currentComponentProps} />;
        break;
      case componentTypes.DCAC_INVERTER:
        currentComponentModal = <DCACInverterModal {...currentComponentProps} />;
        break;
      default:
        break;
    }
  }
  return <>{currentComponentModal}</>;
};

CreateNewComponentModal.propTypes = {
  classes: PropTypes.object,
  isModalVisible: PropTypes.bool,
  onCancel: PropTypes.func,
  onAccept: PropTypes.func,
  defaultData: PropTypes.shape({
    essComponentId: PropTypes.string,
    manufacturer: PropTypes.string,
    partNumber: PropTypes.string,
    maxCharge: PropTypes.string,
    powerRating: PropTypes.string,
    depthOfDischarge: PropTypes.string,
    unitCost: PropTypes.string,
    installationCost: PropTypes.string,
  }),
  readonly: PropTypes.bool,
  componentsType: PropTypes.string,
};

export default withStyles(useStyles)(CreateNewComponentModal);
