/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/function-component-definition */
/**
 * Formik - compatible switch/toggle button component.
 * Requires the parent component to be part of a Formik form.
 * Formik will assign values of 'left' or 'right' depending which button is selected.
 */

/**
 * FormikInput
 * @param {object} param0 Formik Switch parameters
 * @param {string} param0.id formik ID - required for operation
 * @param {object} param0.formik Formik object
 * @param {string} param0.leftText the text to be displayed on the left button
 * @param {string} param0.rightText the text to be displayed on the right button
 * @param {boolean} param0.alternativeColor flag to use the alt color styles
 * @param {boolean} param0.disabled flag to disable the switch
 * @returns Formik-compatible switch
 */

import React from 'react';
import PropTypes from 'prop-types';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { capitalizeFirstLetter } from '../../utils/stringFormatter';
import useStyles from './styles';

const FormikSwitch = (props) => {
  const { id, formik, leftText, rightText, alternativeColor, disabled, readonly } = props;

  const handleFormikChange = (_event, string) => {
    if (string !== null) {
      formik.setFieldValue(id, string);
    }
  };

  /**
   * getReadOnlyValue
   * @param {string} value value of the toggle
   * @returns string translation of the value passed in from prop. End result could be returning 'yes' or 'no' if the toggle is 'right' or 'left'
   */
  const getReadOnlyValue = (value) => {
    let readOnlyValue;
    if (value === 'right') {
      readOnlyValue = rightText;
    }
    if (value === 'left') {
      readOnlyValue = leftText;
    }
    return capitalizeFirstLetter(readOnlyValue);
  };

  const classes = useStyles();
  return (
    <>
      {readonly && getReadOnlyValue(formik.values[id.toString()])}
      {!readonly && (
        <ToggleButtonGroup
          data-testid="formik-switch-component"
          value={formik.values[id.toString()]}
          onChange={handleFormikChange}
          id={id}
          exclusive
          aria-label="text alignment"
        >
          <ToggleButton value="left" aria-label="left aligned" className={alternativeColor ? classes.alt : null} disabled={disabled}>
            {leftText}
          </ToggleButton>
          <ToggleButton value="right" aria-label="centered" className={alternativeColor ? classes.alt : null} disabled={disabled}>
            {rightText}
          </ToggleButton>
        </ToggleButtonGroup>
      )}
    </>
  );
};

FormikSwitch.propTypes = {
  id: PropTypes.string.isRequired,
  leftText: PropTypes.string.isRequired,
  rightText: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  alternativeColor: PropTypes.bool,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
};

export default FormikSwitch;
