const useStyles = () => ({
  cancelButton: {
    order: 1,
    marginRight: '2rem',
  },
  saveButton: {
    order: 2,
  },
});

export default useStyles;
