import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  alt: {
    color: '#373a37',
    background: '#bfbfbf',
    '&:hover': {
      backgroundColor: '#bfbfbf',
    },
    '&.Mui-selected': {
      color: '#373a37',
      background: '#ffffff',
      '&:hover': {
        backgroundColor: '#ffffff',
      },
    },
  },
}));
