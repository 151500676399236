/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/function-component-definition */
/**
 *
 * Bradcrumbs
 * Breadcrumb generator
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumbs, Typography, Link } from '@mui/material';
import useStyles from './styles';

export const BreadcrumbsComponent = ({ breadcrumbs = [] }) => {
  const classes = useStyles();
  const crumbs = [];
  if (breadcrumbs.length > 0) {
    breadcrumbs.forEach((crumb) => {
      const { id, name, selected, link = '' } = crumb;
      let component = null;
      if (selected) {
        component = (
          <Typography key={id} color="inherit">
            {name}
          </Typography>
        );
      } else if (typeof link === 'string') {
        component = (
          <Link key={id} href={`/${link}`} color="primary" className={classes.clickableLink} data-testid={id}>
            {name}
          </Link>
        );
      } else {
        component = (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link color="primary" className={classes.clickableLink} onClick={link} disableRipple={false} data-testid={id}>
            {name}
          </Link>
        );
      }
      crumbs.push(component);
    });
  }

  return <Breadcrumbs className={classes.breadcrumbsWrapper}>{crumbs}</Breadcrumbs>;
};

BreadcrumbsComponent.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
};

export default BreadcrumbsComponent;
