/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/**
 *
 * Modal
 * This modal supports any amount of children. Last child within props.children
 * will always be added to the bottom section alocated for the  action buttons.
 * The buttons should change the modal's `open` property on their click event
 * to false to close it that way. Pressing Esc key will also close the modal.
 *
 */

import React, { Children } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

function ModalComponent(props) {
  const { onClose, open, title, children, paperPropsStyle } = props;

  const modalContent = Children.toArray(children);
  const childrenAmount = modalContent.length - 1;

  return (
    <div>
      <Dialog
        disableBackdropClick
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        PaperProps={{
          // NOTE: We add this styles in here because we want to target only the
          // paper element that it's created when the modal is rendered, so it
          // doesnt affect other components that might use the Paper element
          style: {
            borderRadius: 0,
            boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.06)',
            ...paperPropsStyle,
          },
        }}
      >
        <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
        <DialogContent>{modalContent.slice(0, childrenAmount)}</DialogContent>
        <DialogActions>{modalContent[childrenAmount]}</DialogActions>
      </Dialog>
    </div>
  );
}

ModalComponent.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClose: PropTypes.func,
  open: PropTypes.bool,
  children: PropTypes.node.isRequired,
  paperPropsStyle: PropTypes.object,
};

export default ModalComponent;
