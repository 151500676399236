/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/function-component-definition */
/**
 *
 * HeaderDashboard.js
 * Header content for dashboard
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import messages from '../messages';
import Select from '../../Select';
import Input from '../../Input';
import images from '../../../assets/images';
import useStyles from './styles';

// TODO: Localize this tabs
const TAB_MINE = 'Mine';
const TAB_ACTIVE = 'Active';
const TAB_INACTIVE = 'Inactive';

/**
 * HeaderDashboard
 * @param {String} title='' Title to show in the header
 * @param {Object[]{text:'',value:'',icon:''}} users=[] Array of users for the select search
 * @param {Number} tabSelected=0 Index of tab selected
 * @param {function} handleTabChange Function to execute when selecting tabs
 * @param {function} handleSearch Function to execute when searching cards
 * @param {function} handleSelectChange Function to execute when selecting a user
 */
const HeaderDashboard = ({
  title = '',
  users = [],
  tabSelected = 0,
  handleTabChange,
  handleSearch,
  handleSelectChange,
  showActive,
  showMine,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(tabSelected);
  const [searchTerm, setSearchTerm] = useState('');
  let showActiveItems = false;

  if (showActive && !showMine) {
    showActiveItems = value === 0;
  } else {
    showActiveItems = value !== 0;
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleSearch(searchTerm);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchTerm]);

  /**
   * handleChange
   * @param {Object} event Event executed
   * @param {Number} newValue Index of table selected
   */
  const handleChange = (event, newValue) => {
    setValue(newValue);
    handleTabChange(newValue);
  };

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.upperWrapper}>
        <Box className={classes.titleWrapper}>
          <Typography variant="h2" className={classes.leftSeparator}>
            {title}
          </Typography>
        </Box>
        <Box className={classes.searchWrapper}>
          {showActiveItems && (
            <Box className={classes.searchDropdown}>
              <FormattedMessage {...messages.assignedUserPlaceholder}>
                {(placeholder) => <Select placeholder={placeholder} selectData={users} onChange={handleSelectChange} />}
              </FormattedMessage>
            </Box>
          )}
          <FormControl variant="outlined">
            <FormattedMessage {...messages.search}>
              {(placeholder) => (
                <Input
                  className={classes.searchInput}
                  placeholder={placeholder}
                  icon={images.searchIcon}
                  onChange={(evt) => setSearchTerm(evt.target.value)}
                  value={searchTerm}
                />
              )}
            </FormattedMessage>
          </FormControl>
        </Box>
      </Box>
      <Box className={classes.lowerWrapper}>
        <Tabs data-testid="tabs-container" value={value} onChange={handleChange} className={classes.leftSeparator}>
          {showMine && <Tab className={classes.tab} label={TAB_MINE} />}
          {showActive && <Tab className={classes.tab} label={TAB_ACTIVE} />}
          <Tab className={classes.tab} label={TAB_INACTIVE} />
        </Tabs>
      </Box>
    </Box>
  );
};

HeaderDashboard.propTypes = {
  title: PropTypes.string.isRequired,
  users: PropTypes.array,
  tabSelected: PropTypes.number.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  showActive: PropTypes.bool,
  showMine: PropTypes.bool,
};

export default HeaderDashboard;
