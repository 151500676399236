import { makeStyles } from '@mui/styles';
import { Colors } from '../../../theme';

export default makeStyles({
  wrapper: {
    height: '5.813rem',
    display: 'flex',
    flexDirection: 'row',
  },
  leftSeparator: {
    marginLeft: '2rem',
    maxWidth: '40vw',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginTop: '-5px',
    paddingTop: '0.3rem',
  },
  upperWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingTop: '0.4rem',
  },
  lowerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    objectFit: 'contain',
    justifyContent: 'space-between',
  },
  actionsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-evenly',
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  statusWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  overflowName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '90rem',
    whiteSpace: 'nowrap',
  },
  informationParentWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '50%',
    paddingTop: '0.375rem',
  },
  informationWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  informationText: {
    fontSize: 12,
  },
  saveButton: {
    paddingRight: '1rem',
  },
  chevronWrapper: {
    display: 'flex',
    paddingTop: '1rem',
  },
  button: {
    height: '1.5rem',
    width: '1.5rem',
    border: 0,
    backgroundColor: 'transparent',
    outline: 'none',
    margin: '0',
    cursor: 'pointer',
    '&:focus': {
      backgroundColor: Colors.mercury,
    },
    '&:hover': {
      backgroundColor: Colors.mercury,
    },
  },
});
