/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Tooltip, Fade } from '@mui/material';
import StyledTableCell from '../../../../components/StyledTableCell';
import StyledTableRow from '../../../../components/StyledTableRow';
import useStyles from './styles';
import SimpleSwitch from '../../../../components/SimpleSwitch';
import Icon from '../../../../components/Icon';
import images from '../../../../assets/images';
import messages from '../messages';

/**
 * @param {object} params.componentData obejct that contains the data for especific component
 * @param {function} params.onEditClick function that handles click events when the user wants to edit a component
 * @param {function} params.onDeleteClick function that handles click events when the user wants to delete a component
 * @param {function} params.onDeactivate function that handles click events when the user wants to deactivate a component
 * @param {boolean} params.showActiveTabIcons flag that determine if the icons for active tab
 * @param {boolean} params.isAdmin flag that determine if the curren user is an admin
 */
const TableRow = ({ componentData, onDeactivate, isAdmin, handleViewClick }) => {
  const classes = useStyles();
  const { id, manufacturer, partNumber, description, isActive, creationDate } = componentData;
  return (
    <StyledTableRow className={classes.row} key={id}>
      <StyledTableCell key={`${id}-manufacture`} component="th" scope="row">
        <Tooltip title={manufacturer} arrow TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
          <Typography className={classes.trimmedManufacturer}>{manufacturer}</Typography>
        </Tooltip>
      </StyledTableCell>
      <StyledTableCell key={`${id}-partNumber`}>
        <Tooltip title={partNumber} arrow TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
          <Typography className={classes.trimmedPartNumber}>{partNumber}</Typography>
        </Tooltip>
      </StyledTableCell>
      <StyledTableCell key={`${id}-description`}>
        <Tooltip title={description} arrow TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
          <Typography className={classes.trimmedDescription}>{description}</Typography>
        </Tooltip>
      </StyledTableCell>
      <StyledTableCell key={`${id}-date`}>{creationDate}</StyledTableCell>
      <StyledTableCell className={classes.buttonsColumn} align="center">
        <Icon src={images.viewIcon} onClick={() => handleViewClick(componentData)} toolTipMessage={messages.view} />
      </StyledTableCell>
      {isAdmin && (
        <StyledTableCell key={`${id}-buttons`} align="left" className={classes.buttonsCell}>
          <SimpleSwitch
            className={classes.iconButton}
            disabled={!isAdmin}
            key={`${id}-deactivate`}
            onClick={() => onDeactivate(id)}
            checked={isActive}
          />
        </StyledTableCell>
      )}
    </StyledTableRow>
  );
};

TableRow.propTypes = {
  componentData: PropTypes.shape({
    id: PropTypes.string,
    componentType: PropTypes.string,
    partNumber: PropTypes.string,
    description: PropTypes.string,
    creationDate: PropTypes.string,
    lastUpdatedDate: PropTypes.string,
    connection: PropTypes.string,
  }),
  onDeactivate: PropTypes.func,
  isAdmin: PropTypes.bool,
  handleViewClick: PropTypes.func,
};

export default TableRow;
