import { makeStyles } from '@mui/styles';
import { Colors } from '../../../../theme';

const useStyles = makeStyles({
  iconWrapper: {
    paddingBottom: '10px',
  },
  wrapper: {
    minWidth: '15rem',
    margin: '0.5rem 0',
    backgroundColor: Colors.athensGray,
    height: '130px',
    cursor: 'pointer',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    '&:last-child': {
      paddingBottom: '1rem',
    },
  },
  title: {
    fontWeight: 'bold',
  },
  menuItem: {
    fontSize: '0.875rem',
    letterSpacing: '-0.08px',
    margin: '0 0.25rem',
    padding: '0',
    color: Colors.mountainMeadow,
  },
  cardName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '12rem',
    whiteSpace: 'nowrap',
  },
});

export default useStyles;
