/* eslint-disable no-use-before-define */
/* eslint-disable default-param-last */
import * as actionTypes from './types';

const INITIAL_STATE = {
  components: {},
  isLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_COMPONENTS_START:
      return { ...state, isLoading: true };
    case actionTypes.GET_COMPONENTS_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const {
        payload: { componentType, components },
      } = action;
      // eslint-disable-next-line no-case-declarations
      const newComponents = { ...state.components };
      newComponents[componentType] = splitComponentesByStatus(components);
      return { ...state, isLoading: false, components: { ...newComponents } };
    case actionTypes.GET_COMPONENTS_FAIL:
      return { ...state, isLoading: false };
    case actionTypes.PATCH_COMPONENT_START:
      return { ...state, isLoading: true };
    case actionTypes.PATCH_COMPONENT_SUCCESS:
      return { ...state, isLoading: false };
    case actionTypes.PATCH_COMPONENT_FAIL:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

/**
 * splitComponentesByStatus
 * @param {*} components array of mixed(active and inactive) componentes
 * @returns an object with activeComponents and inactiveComponents arrays as properties
 */
const splitComponentesByStatus = (components) => {
  if (!Array.isArray(components)) {
    return {};
  }
  const activeComponents = components.filter((component) => component.isActive);
  const inactiveComponents = components.filter((component) => component.isActive === false);
  return { activeComponents, inactiveComponents, length: components.length };
};
